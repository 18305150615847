import React, { useEffect, useRef, useState } from "react";
import { classNames } from "../functions/atributComponent";
import {
  BookOpenIcon,
  ArrowTrendingUpIcon,
  ShoppingCartIcon,
  IdentificationIcon,
  CubeIcon,
  TrophyIcon,
  BriefcaseIcon,
  ChartPieIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";

import logoCompany from "../asseets/image/logo/LOGO-EKLES-Large-New.svg";
import { Link, NavLink, Outlet, useLocation } from "react-router-dom";
import Breadcrumb from "../components/BreadcrumbLibrary/Breadcrumb";
import LaporanAkhirModul from "../../moduls/laporanAkhirModul";
import { useDispatch, useSelector } from "react-redux";
import { Logout } from "../../moduls/loginModul/actions/loginAction";
import { useOnClickOutside } from "../functions/customHookReact";
import { BreadcrumbListContext } from "../components/BreadcrumbLibrary/context";
import { GetUserInfoBasic } from "../../moduls/loginModul/actions/userInfoBasicAction";
import AlertLayout from "../components/alertLibrary/view/alertLayout";

const LIST_OPTION_PROFIL = [
  // { kode: "", title: "Profil", link: "" },
  { kode: "", title: "Ubah Kata Sandi", link: "/reset-pass" },
  // { kode: "", title: "Earnings", link: "" },
];

const LIST_MENU = [
  // {
  //   kode: "",
  //   title: "Dashboard",
  //   symbol: <ChartPieIcon className="h-6 w-6 " />,
  //   link: "/",
  //   span: null,
  // },
  {
    kode: "",
    title: "Inventory",
    symbol: <CubeIcon className="h-6 w-6 " />,
    link: "/inventory",
    span: null,
  },
  // {
  //   kode: "",
  //   title: "Member",
  //   symbol: <IdentificationIcon className="h-6 w-6 " />,
  //   link: "/member",
  //   span: null,
  // },
  {
    kode: "",
    title: "Top Spander",
    symbol: <TrophyIcon className="h-6 w-6 " />,
    link: "/top-spander",
    span: null,
  },
  {
    kode: "",
    title: "Penjualan",
    symbol: <ShoppingCartIcon className="h-6 w-6 " />,
    link: "/penjualan",
    span: null,
  },
  {
    kode: "",
    title: "Komisi",
    symbol: <BriefcaseIcon className="h-6 w-6 " />,
    link: "/komisi",
    span: null,
  },
  {
    kode: "",
    title: "Laba Rugi",
    symbol: <ArrowTrendingUpIcon className="h-6 w-6 " />,
    link: "/laba-rugi",
    span: null,
  },
  {
    kode: "",
    title: "Laporan Akhir Outlet",
    symbol: <BookOpenIcon className="h-6 w-6 " />,
    link: "/laporan-akhir-outlet",
    span: null,
  },
];

export default function MainLayout() {
  const [showMenuSM, setShowMenuSM] = useState(false);
  const [showProfil, setShowProfil] = useState(false);
  const [iconOnly, setIconOnly] = useState(true);
  const [userInfoBasic, setUserInfoBasic] = useState({});
  const dispatch = useDispatch();
  const refMProfil = useRef();
  const refMenuSM = useRef();
  const locationUrl = useLocation();
  useOnClickOutside(refMProfil, () => setShowProfil(false));
  useOnClickOutside(refMenuSM, () => setShowMenuSM(false));
  useEffect(() => {
    dispatch(GetUserInfoBasic());
  }, []);
  const storeUserInfo = useSelector((state) => state.userInfoBasic);
  useEffect(() => {
    if (storeUserInfo.success) {
      setUserInfoBasic(storeUserInfo.data);
    }
  }, [storeUserInfo]);
  useEffect(() => {
    setShowMenuSM(false);
    setShowProfil(false);
  }, [locationUrl]);
  // breadcrumb part
  const [listBreadcrumb, setListBreadcrumb] = useState([]);
  const valueBreadcrumb = { listBreadcrumb, setListBreadcrumb };
  // end breadcrumb
  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(Logout());
  };
  console.log("reder layout");
  return (
    <>
      <AlertLayout />
      <div className="bg-gray-100 min-h-screen">
        <nav className="fixed top-0 z-50 w-full bg-white border-b border-gray-200 dark:bg-gray-800 dark:border-gray-700">
          <div className="px-3 py-3 lg:px-5 lg:pl-3">
            <div className="flex items-center justify-between">
              <div className="flex items-center justify-start">
                <button
                  data-drawer-target="logo-sidebar"
                  data-drawer-toggle="logo-sidebar"
                  aria-controls="logo-sidebar"
                  type="button"
                  className={classNames(
                    "inline-flex items-center p-2 text-sm text-gray-500 rounded-lg sm:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  )}
                  onClick={(e) => {
                    // e.preventDefault();
                    setShowMenuSM(!showMenuSM);
                    console.log("Here open sm", showMenuSM);
                  }}
                  disabled={showMenuSM}
                >
                  <span className="sr-only">Open sidebar</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      clipRule="evenodd"
                      fillRule="evenodd"
                      d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
                    ></path>
                  </svg>
                </button>
                <a href="#" className="flex ml-2 md:mr-24">
                  <img
                    src={logoCompany}
                    className="h-8 mr-3"
                    alt="FlowBite Logo"
                  />
                  {/* <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap dark:text-white">
                  clinic
                </span> */}
                </a>
              </div>
              <div className="flex items-center">
                <div className="flex items-center ml-3">
                  <div>
                    <button
                      type="button"
                      className="flex text-sm bg-gray-700 rounded-full focus:ring-4 focus:ring-gray-300 dark:focus:ring-gray-600"
                      aria-expanded="false"
                      data-dropdown-toggle="dropdown-user"
                      onClick={(e) => {
                        e.preventDefault();
                        setShowProfil(!showProfil);
                      }}
                    >
                      <span className="sr-only">Open user menu</span>
                      {/* <img
                      className="w-8 h-8 rounded-full"
                      src="https://flowbite.com/docs/images/people/profile-picture-5.jpg"
                      alt="user photo"
                    /> */}
                      <UserIcon className="h-6 w-6 text-gray-100 m-1" />
                    </button>
                  </div>
                  <div
                    ref={refMProfil}
                    className={classNames(
                      showProfil ? "absolute right-0 top-8" : "hidden",
                      "z-50  my-4 text-base list-none bg-white divide-y divide-gray-100 rounded shadow dark:bg-gray-700 dark:divide-gray-600"
                    )}
                    id="dropdown-user"
                  >
                    <div className="px-4 py-3 w-48" role="none">
                      <p
                        className="text-sm text-gray-900 dark:text-white"
                        role="none"
                      >
                        {userInfoBasic.name}
                      </p>
                      <p
                        className="text-sm font-medium text-gray-900 truncate dark:text-gray-300"
                        role="none"
                      >
                        {userInfoBasic.username}
                      </p>
                    </div>
                    <ul className="py-1" role="none">
                      {LIST_OPTION_PROFIL?.map((x, i) => {
                        return (
                          <li>
                            <Link
                              key={"link-opt" + i}
                              to={x.link}
                              className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white"
                              role="menuitem"
                            >
                              {x.title}
                            </Link>
                          </li>
                        );
                      })}
                      <li>
                        <button
                          onClick={handleLogout}
                          className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 dark:text-gray-300 dark:hover:bg-gray-600 dark:hover:text-white w-full text-left"
                        >
                          Keluar
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </nav>

        <aside
          id="logo-sidebar"
          ref={refMenuSM}
          className={classNames(
            showMenuSM && "translate-x-0 ",
            iconOnly == true ? "sm:w-16" : "sm:w-64",
            "w-64",
            "fixed top-0 left-0 z-40  h-screen pt-20 transition-transform -translate-x-full bg-white border-r border-gray-200 sm:translate-x-0 dark:bg-gray-800 dark:border-gray-700"
          )}
          aria-label="Sidebar"
        >
          <button
            className="hidden absolute top-14 bg-primary-50 h-5 w-full sm:flex items-center justify-center"
            onClick={(e) => {
              e.preventDefault();
              setIconOnly(!iconOnly);
            }}
          >
            {iconOnly ? (
              <ChevronDoubleRightIcon className="h-4 w-4 text-gray-500 stroke-2" />
            ) : (
              <ChevronDoubleLeftIcon className="h-4 w-4 text-gray-500 stroke-2" />
            )}
          </button>

          <div className="h-full px-3 pb-4 overflow-y-auto bg-white dark:bg-gray-800">
            <ul className="space-y-2 font-medium">
              {LIST_MENU?.map((x, i) => {
                return (
                  <li>
                    <NavLink
                      to={x.link}
                      className={({ isActive, isPending }) => {
                        return classNames(
                          isPending && "pending",
                          isActive && "bg-gray-700 text-white",
                          "flex items-center p-2 text-gray-700 rounded-lg dark:text-white  dark:hover:bg-gray-700 group"
                        );
                      }}
                    >
                      {x.symbol}

                      <span
                        className={classNames(
                          "flex-1 ml-3 whitespace-nowrap",
                          iconOnly && "sm:hidden inline"
                        )}
                      >
                        {x.title}
                      </span>
                      {x.span}
                    </NavLink>
                    {/* <a
                    href=
                    className=""
                  >
                    {x.symbol}
                    <span className="flex-1 ml-3 whitespace-nowrap">{x.title}</span>
                    {x.span}
                  </a> */}
                  </li>
                );
              })}
            </ul>
          </div>
        </aside>
        {/* body content */}
        <BreadcrumbListContext.Provider value={valueBreadcrumb}>
          <div
            className={classNames(
              iconOnly == true ? "sm:ml-16" : "sm:ml-64",
              "p-4 "
            )}
          >
            {listBreadcrumb.length > 0 ? (
              <div className="p-0 mt-14">
                <Breadcrumb list={listBreadcrumb} />
              </div>
            ) : (
              <div className="p-0 mt-14"></div>
            )}
            <div className="p-4 rounded-lg dark:border-gray-700 mt-0">
              <Outlet />
              {/* <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">
              <svg
                className="w-3.5 h-3.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4 mb-4">
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
          </div>
          <div className="flex items-center justify-center h-48 mb-4 rounded bg-gray-50 dark:bg-gray-800">
            <p className="text-2xl text-gray-400 dark:text-gray-500">
              <svg
                className="w-3.5 h-3.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 18 18"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M9 1v16M1 9h16"
                />
              </svg>
            </p>
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
            <div className="flex items-center justify-center rounded bg-gray-50 h-28 dark:bg-gray-800">
              <p className="text-2xl text-gray-400 dark:text-gray-500">
                <svg
                  className="w-3.5 h-3.5"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 18 18"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M9 1v16M1 9h16"
                  />
                </svg>
              </p>
            </div>
          </div> */}
            </div>
          </div>
        </BreadcrumbListContext.Provider>

        {/* end body content */}
      </div>
    </>
  );
}
