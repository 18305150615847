export function testDate() {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_days = [];
  while (date.getMonth() == month) {
    var d =
      date.getFullYear() +
      "-" +
      date.getMonth().toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    all_days.push(d);
    date.setDate(date.getDate() + 1);
  }
  console.log(all_days);
}

export function getArrayDateMonth() {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_days = [];
  while (date.getMonth() == month) {
    var d = date.getDate().toString().padStart(2, "0");
    all_days.push(d);
    date.setDate(date.getDate() + 1);
  }

  return all_days;
}

export function dummyValuebyDates(min, max) {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_val = [];
  while (date.getMonth() == month) {
    var d = Math.floor(Math.random() * (max - min + 1)) + min;
    all_val.push(d);
    date.setDate(date.getDate() + 1);
  }
  return all_val;
}

export function LIST_DATES() {
  return getArrayDateMonth();
}
