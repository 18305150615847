import React from "react";
import logoCompany from "../asseets/image/logo/LOGO-EKLES-Large-New.svg";

export default function Page404() {
  return (
    <div>
      <div class="min-h-screen flex flex-grow items-center justify-center bg-gray-50">
        <div className=" p-8 text-center  absolute bottom-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img src={logoCompany} className="h-8 mr-0" alt="Ekles Logo" />
            Dashboard
          </a>
        </div>

        <div class="rounded-lg bg-white p-8 text-center shadow-xl flex flex-col">
          <h1 class="mb-4 text-4xl font-bold">404</h1>

          <p class="text-gray-600">
            Oops! Halaman yang Anda cari tidak ditemukan.
          </p>
          <a
            href="/"
            class="mt-4 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
          >
            {" "}
            Kembali{" "}
          </a>
        </div>
      </div>
    </div>
  );
}
