import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { ActGeneralButtonDownloadRequest } from "./ButtonDownloadsrc/action";
import { classNames } from "../../functions/atributComponent";
export default function DownloadButton(props) {
  const { downloadAction, nameButton = "Unduh" } = props;
  const [loading, setLoading] = useState(false);
  const store = useSelector((state) => state.general_button_download);
  const dispatch = useDispatch();

  useEffect(() => {
    if (store.success && loading == true) {
      setLoading(false);
    }
  }, [store]);
  const handleDownload = () => {
    dispatch(ActGeneralButtonDownloadRequest());
    downloadAction();
    setLoading(true);
  };
  return (
    <button
      onClick={(e) => {
        e.preventDefault();
        handleDownload();
      }}
      disabled={loading}
      type="button"
      className="w-auto md:w-32 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center  flex items-center gap-2"
    >
      <DocumentArrowDownIcon
        className={classNames(
          loading && "animate-bounce",
          "h-6 w-6 text-gray-200"
        )}
      />
      <label className="hidden md:inline">
        {nameButton}
        {loading ? ".." : ""}
      </label>
    </button>
  );
}
