import React, { useState } from "react";
import Modal from "../../../../../general/components/modalLibrary";
import {
  LIST_DATES,
  dummyValuebyDates,
} from "../../../dummyData/dummyFunction";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import MultipleLineChart from "../../molekuls/Chart/MultipleLineChart";
import ChartKeuangan from "./ChartKeuangan";
import ChartKeuanganLabelSm from "./ChartKeuanganLabelSm";
import FilterKeuangan from "./FilterKeuangan";

export default function ModalDetailKeuangan() {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="bg-gray-600 absolute right-0 top-0  rounded-lg">
        <button
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
          type="button"
          className="bg-gray-800 hover:bg-gray-100 outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none  text-gray-100 hover:text-gray-800 rounded-bl-lg"
        >
          <ArrowTopRightOnSquareIcon className="h-5 w-5 " />
        </button>
      </div>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700 absolute top-0 p-4 w-full ">
            DETAIL KEUANGAN
          </h5>{" "}
          <div className="mt-8 px-2 flex justify-between items-end w-auto">
            <div className="text-base font-normal pl-2 dark:text-white text-gray-700  w-64 ">
              <div className="flex justify-between items-center">
                <p>
                  <label className="font-semibold">20</label> Outlet Cabang
                </p>
              </div>
              <div className="flex justify-between items-center whitespace-nowrap">
                <p>Total Omset :</p>{" "}
                <p className="font-semibold">Rp. 0.000.000.000.000</p>
              </div>{" "}
            </div>
            <FilterKeuangan />
          </div>
        </div>
        {/*  */}
        <div className="mx-2 my-2">
          <div className=" bg-gray-50 p-4 space-y-2">
            <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
              E Manado
            </p>
            <div className=" rounded-lg p-1">
              <ChartKeuangan
                darkMode={false}
                labelData={LIST_DATES()}
                valOmsetList={dummyValuebyDates(100000000, 20000000)}
                valPemasukanList={dummyValuebyDates(120000000, 20000000)}
                valListPengeluaran={dummyValuebyDates(50000000, 0)}
                valListPiutang={dummyValuebyDates(50000000, 0)}
              />
            </div>
            <ChartKeuanganLabelSm />
          </div>
        </div>
        {/*  */}
        <div className="mx-2 my-2">
          <div className=" bg-white p-4 space-y-2">
            <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
              E Manado
            </p>

            <ChartKeuangan
              darkMode={false}
              labelData={LIST_DATES()}
              valOmsetList={dummyValuebyDates(100000000, 20000000)}
              valPemasukanList={dummyValuebyDates(120000000, 20000000)}
              valListPengeluaran={dummyValuebyDates(50000000, 0)}
              valListPiutang={dummyValuebyDates(50000000, 0)}
            />
            <ChartKeuanganLabelSm />
          </div>
        </div>
        <div className="mx-2 my-2">
          <div className=" bg-white p-4 space-y-2">
            <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
              E Manado
            </p>

            <ChartKeuangan
              darkMode={false}
              labelData={LIST_DATES()}
              valOmsetList={dummyValuebyDates(100000000, 20000000)}
              valPemasukanList={dummyValuebyDates(120000000, 20000000)}
              valListPengeluaran={dummyValuebyDates(50000000, 0)}
              valListPiutang={dummyValuebyDates(50000000, 0)}
            />
            <ChartKeuanganLabelSm />
          </div>
        </div>
      </Modal>
    </>
  );
}
