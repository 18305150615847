import {
  CABANG_GET_LIST_OUTLET_FAIL,
  CABANG_GET_LIST_OUTLET_REQUEST,
  CABANG_GET_LIST_OUTLET_RESET,
  CABANG_GET_LIST_OUTLET_SUCCESS,
} from "../constans/cabangConstants";

export const cabangmodul_get_list_outlet_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case CABANG_GET_LIST_OUTLET_REQUEST:
      return { loading: true };
    case CABANG_GET_LIST_OUTLET_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case CABANG_GET_LIST_OUTLET_FAIL:
      return { loading: false, error: action.payload };
    case CABANG_GET_LIST_OUTLET_RESET:
      return {};
    default:
      return state;
  }
};
