import React, { useState } from "react";
import Modal from "../../../../../general/components/modalLibrary";
import {
  LIST_DATES,
  dummyValuebyDates,
} from "../../../dummyData/dummyFunction";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";

import FilterAppointment from "./FilterAppointment";
import ChartAppointment from "./ChartAppointment";

export default function ModalDetailAppointment() {
  const [show, setShow] = useState(false);
  return (
    <>
      <div className="bg-gray-600 absolute right-0 top-0  rounded-lg">
        <button
          onClick={(e) => {
            e.preventDefault();
            setShow(true);
          }}
          type="button"
          className="bg-gray-800 hover:bg-gray-100 outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none  text-gray-100 hover:text-gray-800 rounded-bl-lg"
        >
          <ArrowTopRightOnSquareIcon className="h-5 w-5 " />
        </button>
      </div>
      <Modal
        show={show}
        onClose={() => {
          setShow(false);
        }}
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700 absolute top-0 p-4 w-full ">
            DETAIL APPOINTMENT
          </h5>{" "}
          <div className="mt-8 px-2 flex justify-between items-end w-auto">
            <div className="text-base font-normal pl-2 dark:text-white text-gray-700 ">
              <div className="flex justify-between items-center">
                <p>
                  <label className="font-semibold">20</label> Outlet Cabang
                </p>
              </div>
              <p className=" whitespace-nowrap">
                Total Appointment <label className="font-semibold">1000</label>
              </p>
            </div>
            <FilterAppointment />
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="mx-2 my-2">
          <div className=" bg-gray-50 p-4 space-y-2">
            <div>
              <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
                E Manado
              </p>
              <p className=" whitespace-nowrap ">
                Total Appointment <label className="font-semibold">1000</label>
              </p>
            </div>
            <div className=" rounded-lg p-1">
              <ChartAppointment
                labelData={LIST_DATES()}
                listData={dummyValuebyDates(0, 500)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}
        <div className="mx-2 my-2">
          <div className=" bg-gray-50 p-4 space-y-2">
            <div>
              <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
                E Manado
              </p>
              <p className=" whitespace-nowrap ">
                Total Appointment <label className="font-semibold">1000</label>
              </p>
            </div>
            <div className=" rounded-lg p-1">
              <ChartAppointment
                labelData={LIST_DATES()}
                listData={dummyValuebyDates(0, 500)}
              />
            </div>
          </div>
        </div>
        {/*  */}
        {/*  */}

        <div className="mx-2 my-2">
          <div className=" bg-gray-50 p-4 space-y-2">
            <div>
              <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
                E Manado
              </p>
              <p className=" whitespace-nowrap ">
                Total Appointment <label className="font-semibold">1000</label>
              </p>
            </div>
            <div className=" rounded-lg p-1">
              <ChartAppointment
                labelData={LIST_DATES()}
                listData={dummyValuebyDates(0, 500)}
              />
            </div>
          </div>
        </div>
        {/*  */}
      </Modal>
    </>
  );
}
