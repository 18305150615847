import {
  LOGINMODUL_CHANGEPASS_FAIL,
  LOGINMODUL_CHANGEPASS_REQUEST,
  LOGINMODUL_CHANGEPASS_RESET,
  LOGINMODUL_CHANGEPASS_SUCCESS,
} from "../constants/changePassConstants";

export const loginmodul_changepass_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case LOGINMODUL_CHANGEPASS_REQUEST:
      return { loading: true, kk: true };
    case LOGINMODUL_CHANGEPASS_SUCCESS:
      return { loading: false, data: action.payload, success: true, kk: true };
    case LOGINMODUL_CHANGEPASS_FAIL:
      return { loading: false, error: action.payload, kk: true };
    case LOGINMODUL_CHANGEPASS_RESET:
      return { kk: false };
    default:
      return state;
  }
};
