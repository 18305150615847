import { URL_API } from "../../../general/init/url";

// penjualan all cabang
export const API_PENJUALAN_ALL_CABANG_GET = URL_API + "/api/PenjualanData";
export const API_PENJUALAN_ALL_CABANG_GET_DOWNLOAD =
  URL_API + "/api/PenjualanData/Download";

// Penjualan cabang
export const API_PENJUALAN_CABANG_GET = URL_API + "/api/PenjualanDataCabang";
export const API_PENJUALAN_CABANG_GET_DOWNLOAD =
  URL_API + "/api/PenjualanDataCabang/Download";
// detail penjualan cabang
export const API_PENJUALAN_CABANG_DETAIL_GET =
  URL_API + "/api/PenjualanDataCabang/Detail";
export const API_PENJUALAN_CABANG_DETAIL_GET_DOWNLOAD =
  URL_API + "/api/PenjualanDataCabang/Detail/Download";
