import { URL_API } from "../../../general/init/url";

// InventoryAllCabang
export const API_TOPSPANDER_ALL_CABANG_GET = URL_API + "/api/TopSpanderData";
export const API_TOPSPANDER_ALL_CABANG_GET_DOWNLOAD =
  URL_API + "/api/TopSpanderData/Download";
// InventoryCabang
export const API_TOPSPANDER_CABANG_GET = URL_API + "/api/TopSpanderDataCabang";
export const API_TOPSPANDER_CABANG_GET_DOWNLOAD =
  URL_API + "/api/TopSpanderDataCabang/Download";
