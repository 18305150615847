import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetInventoryAllCabang,
  GetInventoryAllCabang,
} from "../../actions/InventoryAllCabangActions";
import TableFixHeaderInventoryAllCabang from "../molekuls/table/TableFixHeaderInventoryAllCabang";
import { filterbyArray } from "../../../../general/functions/checkingInput";
import {
  MagnifyingGlassIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import Select from "react-select";
import ModalConfigInventory from "../molekuls/modal/ModalConfigInventory";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { ConfigInvContext } from "../../context/configContext";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import { useHandleListBreadcrumb } from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";

export default function PageInventoryAllCabang() {
  const { configInv, setConfigInv } = useContext(ConfigInvContext);

  const [minStok, setMinStok] = useState(configInv.minStok);
  const [loading, setLoading] = useState(true);
  const [listData, setlistData] = useState();
  const [listFilter, setListFilter] = useState([]);

  const dispatch = useDispatch();
  const storeGetData = useSelector(
    (state) => state.inventoryModul_get_all_cabang
  );
  const handleDowload = () => {
    dispatch(DownloadGetInventoryAllCabang({ minStok: minStok }));
  };

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      setListBreadcrumb([{ name: "Inventory", link: "/inventory" }]);
    }
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    setConfigInv({ ...ConfigInvContext, minStok: minStok });
    dispatch(GetInventoryAllCabang({ minStok: minStok }));
  }, [minStok]);
  useEffect(() => {
    if (storeGetData.success) {
      console.log("dataPure", storeGetData.data);
      setlistData(
        storeGetData.data.listData?.map((x) => {
          return {
            nama: x.namaOutlet,
            id: x.idOutlet,
            tipeOutlet: x.kategori,
            countMinStok: x.countMinStok,
            countMinStokTreat: x.countMinStokTreat,
            countProdukStok: x.countProdukStok,
            countProdukStokTreat: x.countProdukStokTreat,
          };
        })
      );

      setLoading(false);
    }
    if (storeGetData.error) {
      setLoading(false);
    }
  }, [storeGetData]);
  return (
    <div className="">
      <HeaderPage title="Inventory" />
      <div className="pt-2">
        <ModalConfigInventory
          minStok={minStok}
          handleOnChange={(v) => {
            setMinStok(v.minStok);
          }}
        />
      </div>
      <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
        <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
          <div className="order-first">
            <DownloadButton downloadAction={() => handleDowload()} />
          </div>
          <div className="flex gap-2  items-center flex-row z-30">
            <div className="w-auto max-w-fit  bg-red-50">
              <Select
                //   defaultValue={[]}
                isMulti
                name="colors"
                options={listData?.map((x) => {
                  return { value: x.nama, label: x.nama, id: x.id };
                })}
                onChange={(e) => {
                  console.log(e);
                  setListFilter(e);
                }}
                className="react-select-container "
                classNamePrefix="select"
                placeholder="Pilih Cabang Tertentu"
              />
            </div>
            <button
              type="button"
              className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <MagnifyingGlassIcon className="h-6 w-6 text-gray-200" />
            </button>
          </div>
        </div>
        {loading ? (
          <SimpleLoading />
        ) : (
          <TableFixHeaderInventoryAllCabang
            data={
              listFilter != null && listFilter.length != 0
                ? filterbyArray(listData, listFilter)
                : listData
            }
          />
        )}
      </div>
    </div>
  );
}
