import React, { useEffect, useState } from "react";
import { classNames } from "../../functions/atributComponent";
import ScrollBlocked from "../../functions/scrollBlocked";

export default function ModalManualLoading(props) {
  const { show } = props;

  return (
    <>
      {show && (
        <>
          <ScrollBlocked blocked={true} />
          <div className="backdrop-filter backdrop-blur-sm justify-center  flex fixed inset-0 z-50 outline-none focus:outline-none items-center">
            <div className={"relative  my-6 mx-auto "}>
              {/*content*/}
              <div
                className={classNames(
                  "  relative flex flex-col w-full  outline-none focus:outline-none max-h-full bg-gray-200 overflow-hidden rounded-md "
                )}
              >
                <div className=" animate-pulse  shadow-lg p-4 bg-gray-50">
                  <label className="">Harap Tunggu...</label>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
