import { URL_API } from "../../../general/init/url";

// InventoryAllCabang
export const API_KOMISI_ALL_CABANG_GET = URL_API + "/api/KomisiData";
export const API_KOMISI_ALL_CABANG_GET_DOWNLOAD =
  URL_API + "/api/KomisiData/Download";
// InventoryCabang
export const API_KOMISI_CABANG_GET = URL_API + "/api/KomisiDataCabang";
export const API_KOMISI_CABANG_GET_DOWNLOAD =
  URL_API + "/api/KomisiDataCabang/Download";
