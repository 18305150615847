import React from "react";
import MultipleLineChart from "../../molekuls/Chart/MultipleLineChart";

export default function ChartItemTerjual({
  labelData,
  valProduk,
  valTreatmentNonMedis,
  valTreatment,
  darkMode = false,
}) {
  return (
    <div className="h-64">
      <MultipleLineChart
        darkMode={darkMode}
        labelData={labelData}
        datasets={[
          {
            label: " Produk",
            data: valProduk,

            borderColor: "rgba(59, 130, 246, 1)",
            backgroundColor: "rgba(59, 130, 246, 0.8)",
          },
          {
            label: " Treatment non Medis",
            data: valTreatmentNonMedis,
            borderColor: "rgba(34, 197, 94, 1)",
            backgroundColor: "rgba(94, 197, 94, 0.8)",
          },
          {
            label: " Treatment",
            data: valTreatment,
            borderColor: "rgba(239, 68, 68, 1)",
            backgroundColor: "rgba(239, 68, 68, 0.8)",
          },
        ]}
        title="Keuangan"
      />
    </div>
  );
}
