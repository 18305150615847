import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  MagnifyingGlassIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";

import TableFixHeaderPenjualan from "../molekuls/table/TableFixHeaderLabaRugi";
import { getMonthFromDate } from "../../../../general/functions/dateCustome";
import { useHandleListBreadcrumb } from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import ModalFilterTanggalLabaRugi from "../molekuls/modal/ModalFilterTanggalLabaRugi";
import {
  DownloadGetLabaRugi,
  DownloadPdfGetLabaRugi,
  GenerateLabaRugi,
  GetLabaRugi,
  RGenerateLabaRugi,
} from "../../actions/labaRugiActions";
import { useDispatch, useSelector } from "react-redux";
import { formatCDecimal } from "../../../../general/functions/NumberFormat";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TableFixHeaderLabaRugi from "../molekuls/table/TableFixHeaderLabaRugi";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { classNames } from "../../../../general/functions/atributComponent";
import ModalGenerateData from "../../../../general/components/modalLibrary/ModalGenerateData";
import ModalManualLoading from "../../../../general/components/loading/ModalManualLoading";
import { SetAlertBasic } from "../../../../general/components/alertLibrary/actions/alertBasicAction";

function filterbyArray(arraySumber, arrayCheck) {
  console.log(arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id)));
  return arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id));
}

export default function PageGeneralBulanan() {
  const date = new Date();
  const [sort, setSort] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [outletName, setOutletName] = useState(null);
  const [outletId, setOutletId] = useState(null);
  const [listData, setlistData] = useState();
  const [totalVal, setTotalVal] = useState({});
  const [listFilter, setListFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingGenerate, setLoadingGenerate] = useState(false);
  const [detailCabang, setDetailCabang] = useState({
    nama: null,
    kategori: null,
  });
  // redux
  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.labarugimodul_get);

  const [searchParams, setSearchParams] = useSearchParams();

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      let tmpMonth = month == null ? "00" : month;
      if (outletId != null) {
        setListBreadcrumb([
          {
            name: "Laba Rugi",
            link: classNames("/laba-rugi/" + year + "" + tmpMonth),
          },
          { name: "Cabang " + detailCabang.nama, link: "#" },
        ]);
      } else {
        setListBreadcrumb([
          {
            name: "Laba Rugi",
            link: classNames("/laba-rugi/" + year + "" + tmpMonth),
          },
        ]);
      }
    }
  }, [loading, outletId]);

  const navigate = useNavigate();
  let { dateReportParam, idOutletParam } = useParams();

  useEffect(() => {
    console.log("useEffect", dateReportParam, idOutletParam);
    if (dateReportParam != null && dateReportParam != undefined) {
      let y = null;
      let m = null;
      if (dateReportParam.length == 6 && !isNaN(dateReportParam)) {
        y = dateReportParam.slice(0, 4);
        m = dateReportParam.slice(4, 6);
        if (m == "00") {
          m = null;
        }
        setYear(y);
        setMonth(m);
      }
    } else {
      setYear(date.getFullYear());
    }
    if (idOutletParam != null && idOutletParam != undefined) {
      setOutletId(idOutletParam);
    } else {
      setOutletId(null);
      setOutletName(null);
    }
  }, [dateReportParam, idOutletParam]);

  useEffect(() => {
    console.log("useEffect", year, month, outletId);
    if (year != null && loadingGenerate == false) {
      setLoading(true);
      setTotalVal({});
      dispatch(GetLabaRugi({ tahun: year, bulan: month, outlet: outletId }));
    }
  }, [year, month, outletId, loadingGenerate]);

  useEffect(() => {
    if (storeGetData.success) {
      console.log("storeGetData.data", storeGetData.data);
      let dataStore = storeGetData.data;
      let data = dataStore?.listData;
      let sumValue = {
        biayaPengeluaran: 0,
        hppProduk: 0,
        hppTotal: 0,
        hppTreatmentMedis: 0,
        hppTreatmentNonMedis: 0,
        hutang: 0,
        labaBersih: 0,
        labaKotor: 0,
        penjualanProduk: 0,
        penjualanTotal: 0,
        penjualanTreatmentMedis: 0,
        penjualanTreatmentNonMedis: 0,
        ppn: 0,
        sc: 0,
      };
      let dataPure = [];
      if (dataStore?.outlet == null) {
        dataPure = data.map((x) => {
          let value = {
            biayaPengeluaran: 0,
            hppProduk: 0,
            hppTotal: 0,
            hppTreatmentMedis: 0,
            hppTreatmentNonMedis: 0,
            hutang: 0,
            labaBersih: 0,
            labaKotor: 0,
            penjualanProduk: 0,
            penjualanTotal: 0,
            penjualanTreatmentMedis: 0,
            penjualanTreatmentNonMedis: 0,
            ppn: 0,
            sc: 0,
          };
          x?.list?.forEach((y) => {
            value.biayaPengeluaran += parseFloat(y.biaya_pengeluaran);
            value.hppProduk += parseFloat(y.hpp_produk);
            value.hppTotal += parseFloat(y.hpp_total);
            value.hppTreatmentMedis += parseFloat(y.hpp_treatment_medis);
            value.hppTreatmentNonMedis += parseFloat(y.hpp_treatment_non_medis);
            value.hutang += parseFloat(y.hutang);
            value.labaBersih += parseFloat(y.laba_bersih);
            value.labaKotor += parseFloat(y.laba_kotor);
            value.penjualanProduk += parseFloat(y.penjualan_produk);
            value.penjualanTotal += parseFloat(y.penjualan_total);
            value.penjualanTreatmentMedis += parseFloat(
              y.penjualan_treatment_medis
            );
            value.penjualanTreatmentNonMedis += parseFloat(
              y.penjualan_treatment_non_medis
            );
            value.ppn += parseFloat(y.ppn);
            value.sc += parseFloat(y.sc);
          });

          sumValue.biayaPengeluaran += value.biayaPengeluaran;
          sumValue.hppProduk += value.hppProduk;
          sumValue.hppTotal += value.hppTotal;
          sumValue.hppTreatmentMedis += value.hppTreatmentMedis;
          sumValue.hppTreatmentNonMedis += value.hppTreatmentNonMedis;
          sumValue.hutang += value.hutang;
          sumValue.labaBersih += value.labaBersih;
          sumValue.labaKotor += value.labaKotor;
          sumValue.penjualanProduk += value.penjualanProduk;
          sumValue.penjualanTotal += value.penjualanTotal;
          sumValue.penjualanTreatmentMedis += value.penjualanTreatmentMedis;
          sumValue.penjualanTreatmentNonMedis +=
            value.penjualanTreatmentNonMedis;
          sumValue.ppn += value.ppn;
          sumValue.sc += value.sc;
          return {
            nama: x.namaOutlet,
            id: x.idOutlet,
            tipeOutlet: x.kategori,
            ...value,
          };
        });
      }
      if (dataStore?.outlet != null) {
        // setPageName({ name: "Test", link: "#" });
        data?.forEach((x) => {
          setDetailCabang({
            id: x?.idOutlet,
            nama: x?.namaOutlet,
            kategori: x?.kategori,
          });
          setOutletName(x?.namaOutlet);
          x?.list?.forEach((y) => {
            let value = {
              biayaPengeluaran: 0,
              hppProduk: 0,
              hppTotal: 0,
              hppTreatmentMedis: 0,
              hppTreatmentNonMedis: 0,
              hutang: 0,
              labaBersih: 0,
              labaKotor: 0,
              penjualanProduk: 0,
              penjualanTotal: 0,
              penjualanTreatmentMedis: 0,
              penjualanTreatmentNonMedis: 0,
              ppn: 0,
              sc: 0,
            };
            value.biayaPengeluaran += parseFloat(y.biaya_pengeluaran);
            value.hppProduk += parseFloat(y.hpp_produk);
            value.hppTotal += parseFloat(y.hpp_total);
            value.hppTreatmentMedis += parseFloat(y.hpp_treatment_medis);
            value.hppTreatmentNonMedis += parseFloat(y.hpp_treatment_non_medis);
            value.hutang += parseFloat(y.hutang);
            value.labaBersih += parseFloat(y.laba_bersih);
            value.labaKotor += parseFloat(y.laba_kotor);
            value.penjualanProduk += parseFloat(y.penjualan_produk);
            value.penjualanTotal += parseFloat(y.penjualan_total);
            value.penjualanTreatmentMedis += parseFloat(
              y.penjualan_treatment_medis
            );
            value.penjualanTreatmentNonMedis += parseFloat(
              y.penjualan_treatment_non_medis
            );
            value.ppn += parseFloat(y.ppn);
            value.sc += parseFloat(y.sc);

            sumValue.biayaPengeluaran += value.biayaPengeluaran;
            sumValue.hppProduk += value.hppProduk;
            sumValue.hppTotal += value.hppTotal;
            sumValue.hppTreatmentMedis += value.hppTreatmentMedis;
            sumValue.hppTreatmentNonMedis += value.hppTreatmentNonMedis;
            sumValue.hutang += value.hutang;
            sumValue.labaBersih += value.labaBersih;
            sumValue.labaKotor += value.labaKotor;
            sumValue.penjualanProduk += value.penjualanProduk;
            sumValue.penjualanTotal += value.penjualanTotal;
            sumValue.penjualanTreatmentMedis += value.penjualanTreatmentMedis;
            sumValue.penjualanTreatmentNonMedis +=
              value.penjualanTreatmentNonMedis;
            sumValue.ppn += value.ppn;
            sumValue.sc += value.sc;
            let tanggal = y.tanggal;
            if (month == null) {
              tanggal = getMonthFromDate(y.tahun + "-" + y.bulan + "-01")?.name;
            }
            dataPure.push({
              tanggal: tanggal,
              id: y.id,
              ...value,
            });
          });
        });
      }
      console.log("storeGetData.data dataPure", dataPure);

      setlistData(dataPure);
      setTotalVal(sumValue);
      console.log("dataPure", dataPure, data?.outlet, month);
      // setDetail(storeGetData.data);
      setLoading(false);
    }
    if (storeGetData.error) {
      // dispatch(SetAlertBasic("WARNING", <> {storeGetData.error} </>));
      setLoading(false);
      // setShowModal(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.year;
    let m = v.month;
    let n = "";
    if (m == null) {
      m = "00";
    }
    if (idOutletParam != null) {
      n = "/" + idOutletParam;
    }
    navigate("/laba-rugi/" + y + "" + m + n);
  };

  const handleChangeOutlet = (v) => {
    let y = year;
    let m = month;
    let n = "";
    if (m == null) {
      m = "00";
    }
    n = "/" + v;
    navigate("/laba-rugi/" + y + "" + m + n);

    // searchParams.set("idOutletParam", v);
    // setSearchParams(searchParams);
  };
  const handleDowload = () => {
    dispatch(
      DownloadGetLabaRugi({
        tahun: year,
        bulan: month,
        outlet: outletId,
        namaOutlet: outletName == null ? "" : outletName,
      })
    );
  };
  const handleDowloadPdf = () => {
    dispatch(
      DownloadPdfGetLabaRugi({
        tahun: year,
        bulan: month,
        outlet: outletId,
        namaOutlet: outletName == null ? "" : outletName,
      })
    );
  };

  const handleGenerateOutlet = (v) => {
    setLoadingGenerate(true);
    dispatch(
      GenerateLabaRugi({ tahun: v.year, bulan: v.month, outlet: v.outlet })
    );
  };
  const storeGenerate = useSelector((state) => state.labarugimodul_generate);
  useEffect(() => {
    if (storeGenerate.success) {
      setLoadingGenerate(false);
      dispatch(SetAlertBasic("SUCCESS", <> Berhasil </>));
      dispatch(RGenerateLabaRugi());
    }
    if (storeGenerate.error) {
      setLoadingGenerate(false);
      dispatch(SetAlertBasic("WARNING", <> {storeGenerate.error} </>));
      dispatch(RGenerateLabaRugi());
    }
  }, [storeGenerate]);

  return (
    <>
      {/* {outletName != null && idOutletParam != null && (
        <BLaporanOutlet name={outletName} link={"#"} />
      )} */}
      {/* <div className="flex-none pb-2">
        <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-800 md:text-2xl dark:text-white">
          Laba Rugi{" " + outletName}
        </h1>
        <p className="text-sm font-medium">{outletName}</p>
      </div> */}
      <div className="flex justify-between items-start">
        <HeaderPage
          title={classNames(
            "Laba Rugi ",
            outletId != null ? detailCabang?.nama : ""
          )}
          p={
            outletId != null
              ? "Laba Rugi Cabang" + ", " + detailCabang?.kategori
              : ""
          }
        />
        <ModalGenerateData
          handleGenerate={(v) => {
            handleGenerateOutlet(v);
          }}
        />
      </div>

      <div className="pt-2">
        <ModalFilterTanggalLabaRugi
          monthId={month}
          yearId={year}
          handleOnChange={handleChangeDate}
        />
        <ModalManualLoading show={loadingGenerate} />
        <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
          <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
            <div className="order-first flex gap-1 ">
              {/* <button
              disabled={false}
              type="button"
              className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex items-center gap-2"
              onClick={(e) => {
                e.preventDefault();
                handleDowload();
              }}
            >
              <DocumentArrowDownIcon className="h-6 w-6 text-gray-200" />
              <label className="hidden lg:inline">Download</label>
            </button> */}
              <DownloadButton
                nameButton="Excel"
                downloadAction={() => handleDowload()}
              />
              <DownloadButton
                downloadAction={() => handleDowloadPdf()}
                nameButton="PDF"
              />
            </div>
            {outletName == null && idOutletParam == null && (
              <div className="flex gap-2  items-center flex-row z-30">
                <div className="w-auto max-w-fit  bg-red-50">
                  <Select
                    //   defaultValue={[]}
                    isMulti
                    name="colors"
                    options={listData?.map((x) => {
                      return { value: x.nama, label: x.nama, id: x.id };
                    })}
                    onChange={(e) => {
                      console.log(e);
                      setListFilter(e);
                    }}
                    className="react-select-container "
                    classNamePrefix="select"
                    placeholder="Pilih Cabang Tertentu"
                  />
                </div>
                <button
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  <MagnifyingGlassIcon className="h-6 w-6 text-gray-200" />
                </button>
              </div>
            )}
          </div>
          {loading ? (
            <SimpleLoading />
          ) : (
            <TableFixHeaderLabaRugi
              type={outletId != null ? "LEVEL_2" : "LEVEL_1"}
              data={
                listFilter != null && listFilter.length != 0
                  ? filterbyArray(listData, listFilter)
                  : listData
              }
              handleViewDetail={handleChangeOutlet}
            />
          )}
        </div>
        <div className=" grid lg:grid-cols-3 pt-2 w-full gap-2 ">
          <div>
            <div className="bg-white p-4 rounded-md">
              <h1 className="text-xl font-bold text-gray-700 pb-2">
                PENJUALAN
              </h1>
              <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700 ">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm dark:text-gray-400">
                    Produk
                  </dt>
                  <dd className="text-sm font-semibold text-end ">
                    {formatCDecimal(totalVal?.penjualanProduk)}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Treatment Medis
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.penjualanTreatmentMedis)}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Treatment Non Medis
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.penjualanTreatmentNonMedis)}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-lg dark:text-gray-400">
                    Total
                  </dt>
                  <dd className="text-lg font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.penjualanTotal)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div>
            <div className="bg-white p-4 rounded-md">
              <h1 className="text-xl font-bold text-gray-700 pb-2">
                HARGA POKOK
              </h1>
              <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm dark:text-gray-400">
                    Produk
                  </dt>
                  <dd className="text-sm font-semibold text-end ">
                    {formatCDecimal(totalVal?.hppProduk)}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Treatment Medis
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.hppTreatmentMedis)}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Treatment Non Medis
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.hppTreatmentNonMedis)}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-lg dark:text-gray-400">
                    Total
                  </dt>
                  <dd className="text-lg font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.hppTotal)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div>
            <div className="bg-white p-4 rounded-md">
              <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                <div className="flex flex-col pb-3">
                  <dt className="mb-1 text-gray-500 md:text-sm dark:text-gray-400">
                    Laba Kotor
                  </dt>
                  <dd className="text-sm font-semibold text-end ">
                    {formatCDecimal(totalVal?.labaKotor)}
                  </dd>
                </div>
                <div className="flex flex-col py-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Biaya
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.biayaPengeluaran)}
                  </dd>
                </div>

                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    Service Charge
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.sc)}
                  </dd>
                </div>
                <div className="flex flex-col pt-3">
                  <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                    PPN
                  </dt>
                  <dd className="text-sm font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.ppn)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          <div className="col-span-3">
            <div className="bg-gray-900 p-4 rounded-md">
              <dl className=" text-white divide-y divide-gray-200 ">
                <div className="flex flex-col sm:flex-row justify-between items-center ">
                  <h1 className="text-xl font-bold text-gray-200 pb-2">
                    LABA BERSIH
                  </h1>
                  <dd className="text-xl  font-semibold text-end">
                    {" "}
                    {formatCDecimal(totalVal?.labaBersih)}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
