export const formatRupiah = (number) => {
  return new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  }).format(number);
};

export const formatCurrency = (number) => {
  if (isNaN(number)) {
    number = 0;
  }
  return new Intl.NumberFormat().format(number);
};

export const formatCDecimal = (number) => {
  if (isNaN(number)) {
    number = 0;
  }
  return new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};
