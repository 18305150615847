import React, { useContext, useEffect, useState } from "react";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { ConfigInvContext } from "../../context/configContext";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetKomisiCabang,
  GetKomisiCabang,
  RGetKomisiCabang,
} from "../../actions/KomisiCabangActions";
import TabelKomisiCabang from "../molekuls/table/TabelKomisiCabang";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import {
  useHandleAddPageName,
  useHandleListBreadcrumb,
} from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import ModalFilterTanggalKomisi from "../molekuls/modal/ModalFilterTanggalKomisi";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import { classNames } from "../../../../general/functions/atributComponent";
import { formatCurrency } from "../../../../general/functions/NumberFormat";
import ModalFilterBulananKomisi from "../molekuls/modal/ModalFilterBulananKomisi";

export default function PageKomisiDetailCabang(props) {
  const date = new Date();

  const { outlet } = props;
  const [loading, setLoading] = useState(true);
  const [listData, setlistData] = useState();
  const [detailCabang, setDetailCabang] = useState({});
  const [totalVal, setTotalVal] = useState({});

  //
  const [monthS, setMonthS] = useState(null);
  const [yearS, setYearS] = useState(null);
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.komisiModul_get_cabang);
  let { dateParam } = useParams();

  // useHandleAddPageName("Cabang" + detailCabang?.nama, "");
  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      // let tmpMonth = month == null ? "00" : month;
      if (outlet != null && detailCabang?.nama != undefined) {
        setListBreadcrumb([
          {
            name: "Komisi",
            link: classNames("/komisi/" + yearS + "" + monthS),
          },
          { name: "Cabang " + detailCabang?.nama, link: "#" },
        ]);
      } else {
        setListBreadcrumb([
          {
            name: "Komisi",
            link: classNames("/komisi/" + yearS + "" + monthS),
          },
        ]);
      }
    }
  }, [loading, outlet]);
  useEffect(() => {
    if (dateParam != null && dateParam != undefined) {
      let y = null;
      let m = null;

      if (dateParam.length == 6 && !isNaN(dateParam)) {
        y = dateParam.slice(0, 4);
        m = dateParam.slice(4, 6);
        setYearS(y);
        setMonthS(m);
      }
    } else {
      setMonthS("01");
      setYearS(date.getFullYear());
    }
  }, [dateParam]);

  useEffect(() => {
    setLoading(true);
    if (yearS != null && monthS != null) {
      let firstDay = new Date(yearS, monthS, 1);
      let lastDay = new Date(yearS, parseInt(monthS), 0);
      let dateStart =
        yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
      let dateEnd =
        yearS + "-" + monthS + "-" + ("0" + lastDay.getDate()).slice(-2);
      setTotalVal({});
      dispatch(
        GetKomisiCabang({
          dateStart: dateStart,
          dateEnd: dateEnd,
          outlet: outlet,
        })
      );
    }
  }, [yearS, monthS]);

  useEffect(() => {
    console.log("dataPure", storeGetData);
    if (storeGetData?.success) {
      let sumValue = {
        tKomisi: 0,
        tKomisiProduk: 0,
        tKomisiTreatmetMedis: 0,
        tKomisiTreatmetNonMedis: 0,
        tKomisiLain: 0,
      };
      if (outlet == storeGetData?.data?.outlet) {
        storeGetData.data.listData?.forEach((x) => {
          setDetailCabang({
            id: x?.idOutlet,
            nama: x?.namaOutlet,
            kategori: x?.kategori,
          });
          x?.listData?.forEach((z) => {
            sumValue.tKomisi +=
              z?.totalkomisiFinal != undefined
                ? parseInt(z?.totalkomisiFinal)
                : 0;
            sumValue.tKomisiProduk +=
              z?.komisiProduk != undefined ? parseInt(z?.komisiProduk) : 0;
            sumValue.tKomisiTreatmetMedis +=
              z?.komisiTreatmentMedis != undefined
                ? parseInt(z?.komisiTreatmentMedis)
                : 0;
            sumValue.tKomisiTreatmetNonMedis +=
              z?.komisiTreatmentNonMedis != undefined
                ? parseInt(z?.komisiTreatmentNonMedis)
                : 0;
            sumValue.tKomisiLain +=
              z.komisiLain != undefined ? parseInt(z.komisiLain) : 0;
          });
          setTotalVal(sumValue);
          console.log("dataPure data 2", x?.listData, sumValue);

          setlistData(
            x?.listData?.map((y) => {
              return {
                id: y.iduser,
                nama: y.namauser,
                komisiProduk: y.komisiProduk,
                komisiTreatmentNonMedis: y.komisiTreatmentMedis,
                komisiTreatmentMedis: y.komisiTreatmentNonMedis,
                komisiLain: y.komisiLain,
                totalkomisi: y.totalkomisiFinal,
              };
            })
          );
        });
        setLoading(false);
      }
    }
    if (storeGetData?.error) {
      setLoading(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.yearS;
    let m = v.monthS;
    if (m == null) {
      m = "01";
    }
    navigate("/komisi/" + y + "" + m + "/" + outlet);
  };
  const handleDowload = () => {
    let firstDay = new Date(yearS, monthS, 1);
    let lastDay = new Date(yearS, parseInt(monthS), 0);
    let dateStart =
      yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
    let dateEnd =
      yearS + "-" + monthS + "-" + ("0" + lastDay.getDate()).slice(-2);
    dispatch(
      DownloadGetKomisiCabang({
        dateStart: dateStart,
        dateEnd: dateEnd,
        outlet: outlet,
        namaOutlet: detailCabang.nama,
      })
    );
  };
  return (
    <div>
      <HeaderPage
        title={classNames(
          "Komisi ",
          detailCabang?.nama != undefined ? detailCabang?.nama : ""
        )}
        p={
          detailCabang?.kategori != undefined
            ? "Komisi Cabang" + ", " + detailCabang?.kategori
            : ""
        }
      />

      <div className="pt-2">
        <ModalFilterBulananKomisi
          monthId={monthS}
          yearId={yearS}
          handleOnChange={handleChangeDate}
        />
        <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
          <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
            <div className="order-first">
              {/* <button
                onClick={(e) => {
                  e.preventDefault();
                  handleDowload();
                }}
                disabled={false}
                type="button"
                className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex items-center gap-2"
              >
                <DocumentArrowDownIcon className="h-6 w-6 text-gray-200" />
                <label className="hidden lg:inline">Download</label>
              </button> */}
              <DownloadButton downloadAction={() => handleDowload()} />
            </div>
          </div>
          {loading ? (
            <SimpleLoading />
          ) : (
            <TabelKomisiCabang title="User" data={listData} />
          )}
        </div>

        {!loading && (
          <>
            <div className="grid grid-cols-1 pt-2 gap-2">
              <div className="p-4 rounded-md divide-y divide-gray-500 bg-white">
                {/* <h1 className="text-xl font-bold text-gray-700 pb-2">Total</h1> */}
                <dl className="w-full text-gray-900  dark:text-white grid grid-cols-1  md:grid-cols-2 lg:grid-cols-4 pt-2 gap-2 md:gap-0">
                  <div className="flex flex-col">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Komisi Produk
                    </dt>
                    <dd className="text-lg font-semibold">
                      {formatCurrency(totalVal.tKomisiProduk)}
                    </dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Komisi Treatment Medis
                    </dt>
                    <dd className="text-lg font-semibold">
                      {formatCurrency(totalVal.tKomisiTreatmetMedis)}
                    </dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Komisi Treatment Non Medis
                    </dt>
                    <dd className="text-lg font-semibold">
                      {formatCurrency(totalVal.tKomisiTreatmetNonMedis)}
                    </dd>
                  </div>
                  <div className="flex flex-col">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Komisi Lain
                    </dt>
                    <dd className="text-lg font-semibold">
                      {formatCurrency(totalVal.tKomisiLain)}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="">
                <div className="bg-gray-900 p-4 rounded-md">
                  <dl className=" text-white divide-y divide-gray-200 ">
                    <div className="flex flex-col sm:flex-row justify-between items-center ">
                      <h1 className="text-xl font-bold text-gray-200 pb-2">
                        TOTAL KOMISI
                      </h1>
                      <dd className="text-xl  font-semibold text-end">
                        {" "}
                        {formatCurrency(totalVal.tKomisi)}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
