import axios from "axios";
import {
  LOGINMODUL_CHANGEPASS_FAIL,
  LOGINMODUL_CHANGEPASS_REQUEST,
  LOGINMODUL_CHANGEPASS_RESET,
  LOGINMODUL_CHANGEPASS_SUCCESS,
} from "../constants/changePassConstants";
import { Logout } from "./loginAction";
import { API_USER_CHANGE_PASS } from "../init/apiConstants";

export const RChangePassUser = () => async (dispatch, getState) => {
  dispatch({
    type: LOGINMODUL_CHANGEPASS_RESET,
  });
};

export const ChangePassUser =
  ({ passOld, passNew2 }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: LOGINMODUL_CHANGEPASS_REQUEST,
      });
      const {
        userLogin: { token },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      const { data: data } = await axios.post(
        API_USER_CHANGE_PASS,
        {
          password: passNew2,
          current_password: passOld,
        },
        config
      );
      dispatch({
        type: LOGINMODUL_CHANGEPASS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(Logout());
      }

      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: LOGINMODUL_CHANGEPASS_FAIL,
        payload: message,
      });
    }
  };
