import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import React from "react";
import MultipleLineChart from "../molekuls/Chart/MultipleLineChart";
import { LIST_DATES, dummyValuebyDates } from "../../dummyData/dummyFunction";
import ChartKeuangan from "./DPartKeuangan-F/ChartKeuangan";
import ModalDetailKeuangan from "./DPartKeuangan-F/ModalDetailKeuangan";
import ChartKeuanganLabel from "./DPartKeuangan-F/ChartKeuanganLabel";

export default function DPartKeuangan() {
  return (
    <>
      <div className="bg-gray-900 shadow rounded-lg p-4 sm:p-6 xl:p-8  h-auto relative">
        <div className="mb-4 ">
          <h5 className="text-2xl -mb-2 font-bold dark:text-white text-gray-300">
            KEUANGAN
          </h5>
          <label className="text-sm font-semibold text-gray-300">
            Januari 2023
          </label>
        </div>
        <ModalDetailKeuangan />
        <ChartKeuangan
          darkMode={true}
          labelData={LIST_DATES()}
          valOmsetList={dummyValuebyDates(100000000, 20000000)}
          valPemasukanList={dummyValuebyDates(120000000, 20000000)}
          valListPengeluaran={dummyValuebyDates(50000000, 0)}
          valListPiutang={dummyValuebyDates(50000000, 0)}
        />
      </div>
      <ChartKeuanganLabel />
    </>
  );
}
