import {
  LABA_RUGI_GENERATE_FAIL,
  LABA_RUGI_GENERATE_REQUEST,
  LABA_RUGI_GENERATE_RESET,
  LABA_RUGI_GENERATE_SUCCESS,
  LABA_RUGI_GET_FAIL,
  LABA_RUGI_GET_REQUEST,
  LABA_RUGI_GET_RESET,
  LABA_RUGI_GET_SUCCESS,
} from "../constants/labaRugiConstants";

export const labarugimodul_get_reducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case LABA_RUGI_GET_REQUEST:
      return { loading: true };
    case LABA_RUGI_GET_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LABA_RUGI_GET_FAIL:
      return { loading: false, error: action.payload };
    case LABA_RUGI_GET_RESET:
      return {};
    default:
      return state;
  }
};

export const labarugimodul_generate_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case LABA_RUGI_GENERATE_REQUEST:
      return { loading: true };
    case LABA_RUGI_GENERATE_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LABA_RUGI_GENERATE_FAIL:
      return { loading: false, error: action.payload };
    case LABA_RUGI_GENERATE_RESET:
      return {};
    default:
      return state;
  }
};
