import React, { useEffect, useState } from "react";
import Select from "react-select";
import { classNames } from "../../../../general/functions/atributComponent";
import {
  MagnifyingGlassIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";

import TableFixHeaderPenjualan from "../molekuls/table/TableFixHeaderPenjualan";
import {
  getListYears,
  getMonth,
  getMonthFromDate,
} from "../../../../general/functions/dateCustome";
import SelectFilterDynamic from "../../../dashboardModul/views/molekuls/Select/SelectFilterDynamic";
import {
  useHandleAddPageName,
  useHandleListBreadcrumb,
} from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import ModalFilterTanggalLaporanAkhir from "../molekuls/modal/ModalFilterTanggalLaporanAkhir";
import {
  DownloadGetLaporanAkhir,
  DownloadPdfGetLaporanAkhir,
  GenerateLaporanAkhir,
  GetLaporanAkhir,
  RGenerateLaporanAkhir,
} from "../../actions/laporanAkhirActions";
import { useDispatch, useSelector } from "react-redux";
import { formatCDecimal } from "../../../../general/functions/NumberFormat";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BLaporanOutlet from "../molekuls/tmpTitle/BLaporanOutlet";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import ModalGenerateData from "../../../../general/components/modalLibrary/ModalGenerateData";
import { SetAlertBasic } from "../../../../general/components/alertLibrary/actions/alertBasicAction";
import ModalManualLoading from "../../../../general/components/loading/ModalManualLoading";

function filterbyArray(arraySumber, arrayCheck) {
  console.log(arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id)));
  return arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id));
}

export default function PageGeneralBulanan() {
  const date = new Date();
  const [sort, setSort] = useState(null);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(null);
  const [outletName, setOutletName] = useState(null);
  const [outletId, setOutletId] = useState(null);
  const [listData, setlistData] = useState();
  const [totalVal, setTotalVal] = useState({});
  const [listFilter, setListFilter] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingGenerate, setLoadingGenerate] = useState(false);

  const [detailCabang, setDetailCabang] = useState({
    nama: null,
    kategori: null,
  });
  // redux
  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.laporanakhirmodul_get);

  const [searchParams, setSearchParams] = useSearchParams();

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      let tmpMonth = month == null ? "00" : month;
      if (outletId != null) {
        setListBreadcrumb([
          {
            name: "Laporan Akhir",
            link: classNames("/laporan-akhir-outlet/" + year + "" + tmpMonth),
          },
          { name: "Cabang " + detailCabang.nama, link: "#" },
        ]);
      } else {
        setListBreadcrumb([
          {
            name: "Laporan Akhir",
            link: classNames("/laporan-akhir-outlet/" + year + "" + tmpMonth),
          },
        ]);
      }
    }
  }, [loading, outletId]);

  const navigate = useNavigate();
  let { dateReportParam, idOutletParam } = useParams();

  useEffect(() => {
    console.log("useEffect", dateReportParam, idOutletParam);
    if (dateReportParam != null && dateReportParam != undefined) {
      let y = null;
      let m = null;
      if (dateReportParam.length == 6 && !isNaN(dateReportParam)) {
        y = dateReportParam.slice(0, 4);
        m = dateReportParam.slice(4, 6);
        if (m == "00") {
          m = null;
        }
        setYear(y);
        setMonth(m);
      }
    } else {
      setYear(date.getFullYear());
    }
    if (idOutletParam != null && idOutletParam != undefined) {
      setOutletId(idOutletParam);
    } else {
      setOutletId(null);
      setOutletName(null);
    }
  }, [dateReportParam, idOutletParam]);

  useEffect(() => {
    console.log("useEffect", year, month, outletId);
    if (year != null && loadingGenerate == false) {
      setLoading(true);
      dispatch(
        GetLaporanAkhir({ tahun: year, bulan: month, outlet: outletId })
      );
    }
  }, [year, month, outletId, loadingGenerate]);

  useEffect(() => {
    if (storeGetData.success) {
      let dataStore = storeGetData.data;
      let data = dataStore?.listData;
      let sumValue = {
        medis: 0,
        nonMedis: 0,
        produk: 0,
        pelunasan: 0,
        hutang: 0,
        ppn: 0,
        sc: 0,
        total: 0,
        tunai: 0,
        transfer: 0,
        creditBni: 0,
        creditBri: 0,
        creditBca: 0,
        creditMandiri: 0,
        debitBni: 0,
        debitBri: 0,
        debitBca: 0,
        debitMandiri: 0,
        ovo: 0,
        komisi: 0,
      };
      let dataPure = [];
      if (dataStore?.outlet == null) {
        dataPure = data.map((x) => {
          let value = {
            medis: 0,
            nonMedis: 0,
            produk: 0,
            pelunasan: 0,
            hutang: 0,
            ppn: 0,
            sc: 0,
            total: 0,
            tunai: 0,
            transfer: 0,
            creditBni: 0,
            creditBri: 0,
            creditBca: 0,
            creditMandiri: 0,
            debitBni: 0,
            debitBri: 0,
            debitBca: 0,
            debitMandiri: 0,
            ovo: 0,
            komisi: 0,
          };
          x?.list?.forEach((y) => {
            value.medis += parseFloat(y.rp_medis);
            value.nonMedis += parseFloat(y.rp_non_medis);
            value.produk += parseFloat(y.rp_produk);
            value.pelunasan += parseFloat(y.pelunasan);
            value.hutang += parseFloat(y.hutang);
            value.ppn += parseFloat(y.rp_ppn);
            value.sc += parseFloat(y.rp_sc);
            value.total += parseFloat(y.p_total_payment);
            value.tunai += parseFloat(y.p_tunai);
            value.transfer += parseFloat(y.p_transfer);
            value.creditBni += parseFloat(y.p_cbni);
            value.creditBri += parseFloat(y.p_cbri);
            value.creditBca += parseFloat(y.p_cbca);
            value.creditMandiri += parseFloat(y.p_cmandiri);
            value.debitBni += parseFloat(y.p_dbni);
            value.debitBri += parseFloat(y.p_dbri);
            value.debitBca += parseFloat(y.p_dbca);
            value.debitMandiri += parseFloat(y.p_dmandiri);
            value.ovo += parseFloat(y.ovo);
            value.komisi += parseFloat(y.komisi);
          });

          sumValue.medis += value.medis;
          sumValue.nonMedis += value.nonMedis;
          sumValue.produk += value.produk;
          sumValue.pelunasan += value.pelunasan;
          sumValue.hutang += value.hutang;
          sumValue.ppn += value.ppn;
          sumValue.sc += value.sc;
          sumValue.total += value.total;
          sumValue.tunai += value.tunai;
          sumValue.transfer += value.transfer;
          sumValue.creditBni += value.creditBni;
          sumValue.creditBri += value.creditBri;
          sumValue.creditBca += value.creditBca;
          sumValue.creditMandiri += value.creditMandiri;
          sumValue.debitBni += value.debitBni;
          sumValue.debitBri += value.debitBri;
          sumValue.debitBca += value.debitBca;
          sumValue.debitMandiri += value.debitMandiri;
          sumValue.ovo += value.ovo;
          sumValue.komisi += value.komisi;

          return {
            nama: x.namaOutlet,
            id: x.idOutlet,
            tipeOutlet: x.kategori,
            ...value,
          };
        });
      }
      if (dataStore?.outlet != null) {
        // setPageName({ name: "Test", link: "#" });
        data?.forEach((x) => {
          setDetailCabang({
            id: x?.idOutlet,
            nama: x?.namaOutlet,
            kategori: x?.kategori,
          });
          setOutletName(x?.namaOutlet);
          x?.list?.forEach((y) => {
            let value = {
              medis: 0,
              nonMedis: 0,
              produk: 0,
              pelunasan: 0,
              hutang: 0,
              ppn: 0,
              sc: 0,
              total: 0,
              tunai: 0,
              transfer: 0,
              creditBni: 0,
              creditBri: 0,
              creditBca: 0,
              creditMandiri: 0,
              debitBni: 0,
              debitBri: 0,
              debitBca: 0,
              debitMandiri: 0,
              ovo: 0,
              komisi: 0,
            };
            value.medis = parseFloat(y.rp_medis);
            value.nonMedis = parseFloat(y.rp_non_medis);
            value.produk = parseFloat(y.rp_produk);
            value.pelunasan = parseFloat(y.pelunasan);
            value.hutang = parseFloat(y.hutang);
            value.ppn = parseFloat(y.rp_ppn);
            value.sc = parseFloat(y.rp_sc);
            value.total = parseFloat(y.p_total_payment);
            value.tunai = parseFloat(y.p_tunai);
            value.transfer = parseFloat(y.p_transfer);
            value.creditBni = parseFloat(y.p_cbni);
            value.creditBri = parseFloat(y.p_cbri);
            value.creditBca = parseFloat(y.p_cbca);
            value.creditMandiri = parseFloat(y.p_cmandiri);
            value.debitBni = parseFloat(y.p_dbni);
            value.debitBri = parseFloat(y.p_dbri);
            value.debitBca = parseFloat(y.p_dbca);
            value.debitMandiri = parseFloat(y.p_dmandiri);
            value.ovo = parseFloat(y.ovo);
            value.komisi = parseFloat(y.komisi);

            sumValue.medis += value.medis;
            sumValue.nonMedis += value.nonMedis;
            sumValue.produk += value.produk;
            sumValue.pelunasan += value.pelunasan;
            sumValue.hutang += value.hutang;
            sumValue.ppn += value.ppn;
            sumValue.sc += value.sc;
            sumValue.total += value.total;
            sumValue.tunai += value.tunai;
            sumValue.transfer += value.transfer;
            sumValue.creditBni += value.creditBni;
            sumValue.creditBri += value.creditBri;
            sumValue.creditBca += value.creditBca;
            sumValue.creditMandiri += value.creditMandiri;
            sumValue.debitBni += value.debitBni;
            sumValue.debitBri += value.debitBri;
            sumValue.debitBca += value.debitBca;
            sumValue.debitMandiri += value.debitMandiri;
            sumValue.ovo += value.ovo;
            sumValue.komisi += value.komisi;
            let tanggal = y.tanggal;
            if (month == null) {
              tanggal = getMonthFromDate(y.tahun + "-" + y.bulan + "-01")?.name;
            }
            dataPure.push({
              tanggal: tanggal,
              id: y.id,
              ...value,
            });
          });
        });
      }

      setlistData(dataPure);
      setTotalVal(sumValue);
      console.log("dataPure", dataPure, data?.outlet, month);
      // setDetail(storeGetData.data);
      setLoading(false);
    }
    if (storeGetData.error) {
      // dispatch(SetAlertBasic("WARNING", <> {storeGetData.error} </>));
      setLoading(false);
      // setShowModal(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.year;
    let m = v.month;
    let n = "";
    if (m == null) {
      m = "00";
    }
    if (idOutletParam != null) {
      n = "/" + idOutletParam;
    }
    navigate("/laporan-akhir-outlet/" + y + "" + m + n);
  };

  const handleChangeOutlet = (v) => {
    let y = year;
    let m = month;
    let n = "";
    if (m == null) {
      m = "00";
    }
    n = "/" + v;
    navigate("/laporan-akhir-outlet/" + y + "" + m + n);

    // searchParams.set("idOutletParam", v);
    // setSearchParams(searchParams);
  };
  const handleDowload = () => {
    dispatch(
      DownloadGetLaporanAkhir({
        tahun: year,
        bulan: month,
        outlet: outletId,
        namaOutlet: outletName == null ? "" : outletName,
      })
    );
  };
  const handleDowloadPdf = () => {
    dispatch(
      DownloadPdfGetLaporanAkhir({
        tahun: year,
        bulan: month,
        outlet: outletId,
        namaOutlet: outletName == null ? "" : outletName,
      })
    );
  };
  const handleGenerateOutlet = (v) => {
    setLoadingGenerate(true);
    dispatch(
      GenerateLaporanAkhir({ tahun: v.year, bulan: v.month, outlet: v.outlet })
    );
  };

  const storeGenerate = useSelector(
    (state) => state.laporanakhirmodul_genereate
  );
  useEffect(() => {
    if (storeGenerate.success) {
      setLoadingGenerate(false);
      dispatch(SetAlertBasic("SUCCESS", <> Berhasil </>));
      dispatch(RGenerateLaporanAkhir());
    }
    if (storeGenerate.error) {
      setLoadingGenerate(false);
      dispatch(SetAlertBasic("WARNING", <> {storeGenerate.error} </>));
      dispatch(RGenerateLaporanAkhir());
    }
  }, [storeGenerate]);

  return (
    <>
      {/* {outletName != null && idOutletParam != null && (
        <BLaporanOutlet name={outletName} link={"#"} />
      )} */}
      <div className="flex justify-between items-start">
        <HeaderPage
          title={classNames(
            "Laporan Akhir ",
            outletId != null ? detailCabang?.nama : ""
          )}
          p={
            outletId != null
              ? "Laporan AKhir Cabang" + ", " + detailCabang?.kategori
              : ""
          }
        />

        <ModalGenerateData
          handleGenerate={(v) => {
            handleGenerateOutlet(v);
          }}
        />
      </div>
      <div className="pt-2">
        <ModalFilterTanggalLaporanAkhir
          monthId={month}
          yearId={year}
          handleOnChange={handleChangeDate}
        />
        <ModalManualLoading show={loadingGenerate} />

        <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
          <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
            <div className="order-first flex gap-1 ">
              {/* <button
              onClick={(e) => {
                e.preventDefault();
                handleDowload();
              }}
              disabled={false}
              type="button"
              className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex items-center gap-2"
            >
              <DocumentArrowDownIcon className="h-6 w-6 text-gray-200" />
              <label className="hidden lg:inline">Download</label>
            </button> */}
              <DownloadButton
                nameButton="Excel"
                downloadAction={() => handleDowload()}
              />
              <DownloadButton
                downloadAction={() => handleDowloadPdf()}
                nameButton="PDF"
              />
            </div>
            {outletName == null && idOutletParam == null && (
              <div className="flex gap-2  items-center flex-row z-30">
                <div className="w-auto max-w-fit  bg-red-50">
                  <Select
                    //   defaultValue={[]}
                    isMulti
                    name="colors"
                    options={listData?.map((x) => {
                      return { value: x.nama, label: x.nama, id: x.id };
                    })}
                    onChange={(e) => {
                      console.log(e);
                      setListFilter(e);
                    }}
                    className="react-select-container "
                    classNamePrefix="select"
                    placeholder="Pilih Cabang Tertentu"
                  />
                </div>
                <button
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  <MagnifyingGlassIcon className="h-6 w-6 text-gray-200" />
                </button>
              </div>
            )}
          </div>
          {loading ? (
            <SimpleLoading />
          ) : (
            <TableFixHeaderPenjualan
              type={outletId != null ? "LEVEL_2" : "LEVEL_1"}
              data={
                listFilter != null && listFilter.length != 0
                  ? filterbyArray(listData, listFilter)
                  : listData
              }
              handleViewDetail={handleChangeOutlet}
            />
          )}
        </div>
        {!loading && (
          <div className="grid lg:grid-cols-3 pt-2 w-full gap-2 ">
            <div className="flex flex-col gap-2">
              <div className="bg-white p-4 rounded-md">
                <h1 className="text-xl font-bold text-gray-700 pb-2">
                  PENJUALAN ITEM
                </h1>
                <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="flex flex-col pb-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Medis
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {formatCDecimal(totalVal?.medis)}
                    </dd>
                  </div>
                  <div className="flex flex-col py-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Non Medis
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.nonMedis)}
                    </dd>
                  </div>
                  <div className="flex flex-col pt-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Produk
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.produk)}
                    </dd>
                  </div>
                </dl>
              </div>
              <div className="bg-white px-4  rounded-md">
                <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="flex flex-col py-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Komisi
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.komisi)}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col gap-2">
              <div className="bg-white p-4 rounded-md">
                <h1 className="text-xl font-bold text-gray-700 pb-2">
                  PELUNASAN & PIUTANG
                </h1>
                <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="flex flex-col pb-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Pelunasan
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.pelunasan)}
                    </dd>
                  </div>
                  <div className="flex flex-col py-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Piutang
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.hutang)}
                    </dd>
                  </div>
                </dl>
              </div>

              <div className="bg-white p-4 rounded-md">
                <h1 className="text-xl font-bold text-gray-700 pb-2">
                  LAINNYA
                </h1>
                <dl className=" text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="flex flex-col pb-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      PPN
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.ppn)}
                    </dd>
                  </div>
                  <div className="flex flex-col py-3">
                    <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                      Service
                    </dt>
                    <dd className="text-base font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.sc)}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            {/*  */}
            <div className="flex flex-col rounded-md bg-white">
              <div className=" px-4 pt-4 ">
                <h1 className="text-xl font-bold text-gray-700 pb-2">
                  PEMBAYARAN
                </h1>
                <dl className="w-full text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Tunai
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.tunai)}
                      </dd>
                    </div>
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Transfer
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.transfer)}
                      </dd>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Credit BNI
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.creditBni)}
                      </dd>
                    </div>
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Debit BNI
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.debitBni)}
                      </dd>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Credit BRI
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.creditBri)}
                      </dd>
                    </div>
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Debit BRI
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.debitBri)}
                      </dd>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Credit BCA
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.creditBca)}
                      </dd>
                    </div>
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Debit BCA
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.debitBca)}
                      </dd>
                    </div>
                  </div>

                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Credit Mandiri
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.creditMandiri)}
                      </dd>
                    </div>
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Debit Mandiri
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.debitMandiri)}
                      </dd>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 pb-3 gap-x-2">
                    <div className="flex flex-col ">
                      <dt className="mb-1 text-gray-500 text-sm dark:text-gray-400">
                        Ovo
                      </dt>
                      <dd className="text-base font-semibold text-end">
                        {" "}
                        {formatCDecimal(totalVal?.ovo)}
                      </dd>
                    </div>
                  </div>
                </dl>
              </div>
              <div className="bg-gray-900  rounded-md px-4 pb-4">
                <dl className=" text-gray-50 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                  <div className="flex flex-col pt-3">
                    <dt className="mb-1 text-gray-200 md:text-lg ">Total</dt>
                    <dd className="text-lg font-semibold text-end">
                      {" "}
                      {formatCDecimal(totalVal?.total)}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
