import React, { useEffect, useState } from "react";
import Modal from "../../../../../general/components/modalLibrary";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { getRpPersenFromRpTotal } from "../../../../../general/functions/ppnscFunctions";
import { useDispatch, useSelector } from "react-redux";
import {
  GetPenjualanCabangDetail,
  RGetPenjualanCabangDetail,
} from "../../../actions/PenjualanCabangDetailActions";
import TableListItemPenjualan from "../table/TableListItemPenjualan";
import SimpleLoading from "../../../../../general/components/loading/SimpleLoading";

export default function ModalDetailPenjualan(props) {
  const { show, setShow, data, detailCabang } = props;
  const [detail, setDetail] = useState({});
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const storeGetData = useSelector(
    (state) => state.penjualanModul_get_cabang_detail
  );

  useEffect(() => {
    if (show == false) {
      setDetail({});
      setLoading(true);
    }
  }, [show]);
  useEffect(() => {
    setLoading(true);
    dispatch(
      GetPenjualanCabangDetail({
        idPenjualan: data.id,
        outlet: detailCabang.id,
      })
    );
  }, [data]);

  useEffect(() => {
    console.log(
      "dataPure",
      storeGetData,
      data.id == storeGetData?.data?.penjualan,
      loading
    );
    if (storeGetData?.success) {
      if (data.id == storeGetData?.data?.penjualan) {
        let detailItem = storeGetData?.data?.dataItems?.map((x, i) => {
          return {
            id: x.iditem,
            nama: x.namaitem,
            jumlah: x.qty,
            harga: x.hargaitem,
            diskon: x.diskon,
            total: x.total,
          };
        });
        let totalHargaItem = detailItem.reduce(
          (acc, o) => acc + parseInt(o.total),
          0
        );
        let totalTerbayarHutang = storeGetData?.data?.dataPiutang?.reduce(
          (acc, o) => acc + parseInt(o.tunai) - parseInt(o.kembalian),
          0
        );
        let totalTerbayarFix =
          parseInt(totalTerbayarHutang) +
          parseInt(storeGetData?.data?.dataOutelt?.tunai) -
          parseInt(storeGetData?.data?.dataOutelt?.kembalian);
        let dataTmp = {
          dataOutlet: storeGetData?.data?.dataOutelt,
          dataItems: detailItem,
          totalHargaItems: totalHargaItem,
          dataPiutang: storeGetData?.data?.dataPiutang,
          terbayar: totalTerbayarFix,
        };
        console.log(
          "dataPure 2",
          dataTmp,
          dataTmp?.dataOutlet?.nonota,
          dataTmp.totalHargaItems
        );
        setDetail(dataTmp);
        setLoading(false);
        console.log("loading", loading);
      }
      dispatch(RGetPenjualanCabangDetail());
    }
    if (storeGetData?.error) {
      setLoading(false);
      console.log("loading", loading);
    }
  }, [storeGetData]);
  // id, nomor nota, tanggal, outlet,
  // id member, nama member,id staff
  // tipe transaksi, status hutang, tanggal pelunasan
  //  grand total, ppn, service charge, diskon, pembayaran awal, kembalian, sisa hutang
  //  tabel riwayat pelunasan, [tanggal, id staff, nama staff, tipe transaksi, total bayar, sisa hutang]
  //  tabel item, [id, nama, jumlah, harga, diskon, total]
  console.log(
    "loading",
    loading,
    detail?.dataOutlet?.Id,
    detail?.dataOutlet?.Id != undefined
  );
  return (
    <Modal
      show={show}
      overflow={false}
      onClose={() => {
        setShow(false);
      }}
    >
      {loading || detail?.dataOutlet?.Id == undefined ? (
        <div className="h-[80vh] bg-gray-100 flex items-center">
          <SimpleLoading />
        </div>
      ) : (
        <>
          <div className="bg-white w-full sticky top-0">
            <div className="top-0 pt-4 px-4 pb-2">
              <h5 className="text-lg font-bold  dark:text-white text-gray-700  leading-5">
                DETAIL PENJUALAN
              </h5>{" "}
              <div className="flex justify-between items-end pt-1">
                <div className="text-sm flex flex-col">
                  <label>
                    {detailCabang?.nama} ({detailCabang?.kategori})
                  </label>
                  <label>
                    {detail?.dataOutlet?.namastaff} / ID{" "}
                    {detail?.dataOutlet?.idstaff}
                  </label>
                </div>
                <div className="text-sm flex flex-col items-center">
                  <label>No Nota: {detail?.dataOutlet?.nonota}</label>
                  <label>ID: {detail?.dataOutlet?.Id}</label>
                </div>

                <div className="sm">Tanggal: {detail?.dataOutlet?.tanggal}</div>
              </div>
            </div>
          </div>
          <div
            className="py-2 px-4
           bg-white border-t"
          >
            <div className="flex justify-between pb-3">
              <div className="flex flex-col">
                <dt className="mb-1 text-gray-500 md:text-sm">Member</dt>
                <dd className="text-base font-semibold">
                  {" "}
                  {detail?.dataOutlet?.namamember}
                  {/* {formatCurrency(totalVal?.creditBni)} */}
                </dd>
                <dd className="text-sm -mt-1">
                  {" "}
                  ID {detail?.dataOutlet?.idmember}
                  {/* {formatCurrency(totalVal?.creditBni)} */}
                </dd>
              </div>
            </div>
          </div>
          <div className="overflow-auto">
            <div className="px-4 pt-4">
              <h1 className="text-lg font-bold text-gray-700 ">ITEM</h1>
              <TableListItemPenjualan data={detail?.dataItems} />
            </div>
            <div className="grid grid-cols-2 m-4 gap-2">
              {/* Hutang part */}
              <div className="flex flex-col rounded-md bg-white">
                <div className=" px-4 pt-4 ">
                  <h1 className="text-xl font-bold text-gray-700 pb-2">
                    RIWAYAT
                  </h1>
                  <dl className="w-full text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Status Hutang
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {detail?.dataOutlet?.statuspiutang}
                          {/* {formatCurrency(totalVal?.ovo)} */}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Tanggal Pelunasan
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {detail?.dataOutlet?.tglpelunasan}
                          {/* {formatCurrency(totalVal?.ovo)} */}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Sisa Hutang
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {formatCurrency(detail?.dataOutlet?.piutang)}
                        </dd>
                      </div>
                    </div>

                    <div className="grid grid-cols-1 ">
                      <div className="flex items-center justify-between">
                        <div className="mb-1 flex flex-col items-center">
                          <dt className=" text-gray-500 md:text-base dark:text-gray-400">
                            Pembayaran 1
                          </dt>
                          <small>{detail?.dataOutlet?.tanggal}</small>
                        </div>
                        <dd className="text-base font-semibold">
                          {formatCurrency(
                            detail?.dataOutlet?.tunai -
                              detail?.dataOutlet?.kembalian
                          )}
                        </dd>
                      </div>
                    </div>

                    {detail?.dataPiutang?.map((x, i) => {
                      return (
                        <div className="grid grid-cols-1 ">
                          <div className="flex items-center justify-between">
                            <div className="mb-1 flex flex-col items-center">
                              <dt className=" text-gray-500 md:text-base dark:text-gray-400">
                                Pembayaran {i + 2}
                              </dt>
                              <small>{x?.tanggal}</small>
                            </div>

                            <dd className="text-base font-semibold">
                              {formatCurrency(x?.tunai - x?.kembalian)}
                            </dd>
                          </div>
                        </div>
                      );
                    })}
                  </dl>
                </div>
              </div>
              {/* end hutang part */}
              <div className="flex flex-col  rounded-md bg-white">
                <div className=" px-4 pt-4 ">
                  <h1 className="text-lg font-bold text-gray-700 pb-2">
                    PEMBAYARAN
                  </h1>
                  <dl className="w-full text-gray-900 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Tipe Pembayaran
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {detail?.dataOutlet?.tipe}
                          {/* {formatCurrency(totalVal?.ovo)} */}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Harga Total Item
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {formatCurrency(detail?.totalHargaItems)}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          PPN ({detail?.dataOutlet?.ppn}%)
                        </dt>
                        <dd className="text-base font-semibold">
                          {formatCurrency(
                            parseInt(
                              getRpPersenFromRpTotal(
                                detail?.dataOutlet?.grandtotal,
                                detail?.dataOutlet?.ppn,
                                detail?.dataOutlet?.ppn +
                                  detail?.dataOutlet?.servicecharge
                              )
                            )
                          )}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Service Charge ({detail?.dataOutlet?.servicecharge}%)
                        </dt>
                        <dd className="text-base font-semibold">
                          {formatCurrency(
                            parseInt(
                              getRpPersenFromRpTotal(
                                detail?.dataOutlet?.grandtotal,
                                detail?.dataOutlet?.servicecharge,
                                detail?.dataOutlet?.ppn +
                                  detail?.dataOutlet?.servicecharge
                              )
                            )
                          )}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Diskon
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {formatCurrency(detail?.dataOutlet?.diskon)}
                        </dd>
                      </div>
                    </div>
                    <div className="grid grid-cols-1 pb-3">
                      <div className="flex items-center justify-between">
                        <dt className="mb-1 text-gray-500 md:text-base dark:text-gray-400">
                          Grand Total
                        </dt>
                        <dd className="text-base font-semibold">
                          {" "}
                          {formatCurrency(detail?.dataOutlet?.grandtotal)}
                        </dd>
                      </div>
                    </div>
                  </dl>
                </div>
                <div className="bg-gray-900  rounded-md px-4 pb-4">
                  <dl className=" text-gray-50 divide-y divide-gray-200 dark:text-white dark:divide-gray-700">
                    <div className="flex justify-between items-center  pt-3">
                      <dt className="mb-1 text-gray-200 md:text-base ">
                        Terbayar
                      </dt>
                      <dd className="text-base font-semibold">
                        {" "}
                        {formatCurrency(detail?.terbayar)}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Modal>
  );
}
