import React from "react";

export default function HeaderPage({ title, p = "" }) {
  return (
    <div className="flex-none">
      <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-800 md:text-2xl dark:text-white">
        {title}
      </h1>
      {p != "" && <p className="font-normal">{p}</p>}
    </div>
  );
}
