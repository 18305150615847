import React, { createContext, useEffect } from "react";
import {
  useHandleFirstPageName,
  useHandleListBreadcrumb,
} from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import HeaderPage from "../../general/components/text/HeaderPage";
import PageInventoryAllCabang from "./view/parts/PageInventoryAllCabang";
import { useParams } from "react-router-dom";
import { ConfigInvContext } from "./context/configContext";
import PageInventoryDetailCabang from "./view/parts/PageInventoryDetailCabang";

export default function InventoryModul() {
  const valueConfing = {
    configInv: { minStok: 50 },
    setConfigInv: () => {},
  };

  return (
    <div>
      <ConfigInvContext.Provider value={valueConfing}>
        <View />
      </ConfigInvContext.Provider>
    </div>
  );
}

const View = () => {
  let { outlet } = useParams();
  let n = 0;
  if (outlet != null) {
    n = 1;
  }
  switch (n) {
    case 1:
      return <PageInventoryDetailCabang outlet={outlet} />;

    default:
      return <PageInventoryAllCabang />;
  }
};
