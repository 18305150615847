import React, { useEffect, useState } from "react";
import { ServerIcon } from "@heroicons/react/24/outline";
import Modal from ".";
import SelectFilterDynamic from "../../../moduls/dashboardModul/views/molekuls/Select/SelectFilterDynamic";
import { getListYears, getMonth } from "../../functions/dateCustome";
import { GetListOutletCabang } from "../../../moduls/cabangModul/actions/cabangActions";
import { useDispatch, useSelector } from "react-redux";
import { SetAlertBasic } from "../alertLibrary/actions/alertBasicAction";
import { classNames } from "../../functions/atributComponent";

export default function ModalGenerateData(props) {
  const { handleGenerate } = props;
  const [valid, setValid] = useState(false);
  const date = new Date();
  const [show, setShow] = useState(false);
  const [outlet, setOutlet] = useState(null);
  const [listOutlet, setListOutlet] = useState([]);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(date.getFullYear());
  let yearList = getListYears(2017, date.getFullYear());

  const dispatch = useDispatch();
  const storeGetCabang = useSelector(
    (state) => state.cabangmodul_get_list_outlet
  );
  useEffect(() => {
    dispatch(GetListOutletCabang());
  }, []);
  useEffect(() => {
    if (storeGetCabang.success) {
      setListOutlet(
        storeGetCabang?.data?.map((x) => {
          return {
            id: x.idOutlet,
            name: x.namaOutlet,
          };
        })
      );
    }
  }, [storeGetCabang]);
  // useEffect(() => {
  //   if (editeMode == false) {
  //     setMonth(monthId);
  //     if (!isNaN(yearId)) {
  //       setYear(parseInt(yearId));
  //     }
  //     console.log("date test filter", yearId, monthId);
  //   }
  // }, [monthId, yearId, editeMode]);
  // GetListOutletCabang
  const handleAction = (e) => {
    e.preventDefault();
    setValid(true);
    if (month != "00" && month != null && year != null && outlet != null) {
      handleGenerate({ month: month, year: year, outlet: outlet });
      setShow(false);
    }
    // else {
    //   dispatch(SetAlertBasic("WARNING", <> Tolong lengkapi inputan </>));
    // }
  };

  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}
        type="button"
        className="w-auto md:w-32 text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 flex items-center gap-2"
      >
        <ServerIcon className="h-6 w-6 text-gray-200" />
        <label className="hidden md:inline">Generate</label>
      </button>

      <Modal
        show={show}
        overflow={false}
        onClose={() => {
          setShow(false);
        }}
        size="md"
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700  top-0 p-4  ">
            Generate Data Outlet Cabang
          </h5>{" "}
        </div>
        <div className="p-4 bg-white border-t">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center pb-1">
              <div>Outlet</div>
              <div className="">
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto "
                  dataSelectList={listOutlet}
                  placeholder="Pilih Outlet"
                  valueOption={outlet}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setOutlet(null);
                    } else {
                      setOutlet(v.id);
                    }
                  }}
                />
                <label
                  className={classNames(
                    valid && outlet == null ? "block" : "hidden",
                    " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                  )}
                >
                  *silakan pilih
                </label>
              </div>
            </div>
            <div className="flex justify-between items-center pb-1">
              <div>Bulan</div>
              <div className="">
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto "
                  dataSelectList={getMonth().filter((x) => x.id != "00")}
                  placeholder="Pilih Bulan.."
                  valueOption={month}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setMonth("00");
                    } else {
                      setMonth(v.id);
                    }
                  }}
                />
                <label
                  className={classNames(
                    valid && (month == "00" || month == null)
                      ? "block"
                      : "hidden",
                    " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                  )}
                >
                  *silakan pilih
                </label>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Tahun</div>
              <div>
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
                  dataSelectList={yearList}
                  placeholder="Tahun"
                  valueOption={year}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setYear(date.getFullYear());
                    } else {
                      setYear(v.id);
                    }
                  }}
                />
              </div>
            </div>
            {/* footer */}
            <div className="flex justify-end  gap-2">
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-primary-600 bg-gray-200 hover:bg-primary-300 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Tutup
                </button>
              </div>
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleAction}
                >
                  Generate
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
