import {
  ALERT_BASIC_REQUEST,
  ALERT_BASIC_SET,
} from "../constants/alertBasicConstants";

export const SetAlertBasic = (type, mess) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ALERT_BASIC_REQUEST,
    });
    if (type) {
      let obj = { type: type, mes: mess };
      dispatch({
        type: ALERT_BASIC_SET,
        payload: obj,
      });
    }
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    console.log("error basic message", message);
  }
};
