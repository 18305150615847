import React, { useState } from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { miniSerializeError } from "@reduxjs/toolkit";

const HEADER_TABLE = [
  { name: "Nama" },
  // { name: "Kategori" },
  { name: "Satuan" },
  { name: "Stok" },
];
export default function TabelStokInventoryCabang(props) {
  const { data, minStok, title = "" } = props;
  const [search, setSearch] = useState("");
  let totalNeedOrder = 0;
  return (
    <div>
      <div className="flex justify-between ml-2">
        <h1 className="text-xl font-bold text-gray-700 pb-2 ">{title}</h1>
        <div>
          <input
            type="text"
            name="first-name"
            id="first-name"
            placeholder="Cari"
            className=" h-8 px-2 ring-primary-500 focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border border-gray-300 rounded-md sm:w-auto  bg-white"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <div
        style={{ minHeight: "20rem" }}
        className="bg-white rounded-md border-2 pb-2"
      >
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
              <div
                className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative"
                style={{ maxHeight: "50vh" }}
              >
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100 ">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                      >
                        No.
                      </th>
                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {data
                      ?.filter((x) => {
                        if (search == "") {
                          return true;
                        } else {
                          return x.nama
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        }
                      })
                      .map((x, i) => {
                        if (x.stok <= minStok) totalNeedOrder += 1;

                        return (
                          <tr
                            className={classNames(
                              x.stok <= minStok ? "bg-white" : "bg-white"
                            )}
                          >
                            <td className={classNames("text-center py-2")}>
                              <div className="text-sm font-medium text-gray-500 ">
                                {++i}
                              </div>
                            </td>
                            <td className={classNames("text-left  z-0")}>
                              <div className="text-sm font-medium text-gray-500  px-2 flex  gap-1 pb-2 items-center">
                                <div>{x?.nama}</div>{" "}
                              </div>
                            </td>
                            {/* <td className={classNames("text-left ")}>
                              <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                                {x.kategori}
                              </div>
                            </td> */}
                            <td
                              className={classNames(
                                // false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {x.satuan}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                // false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 flex justify-between">
                                <div>
                                  {x.stok <= minStok && (
                                    <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
                                      !
                                    </span>
                                  )}
                                </div>
                                <div>{formatCurrency(x.stok)}</div>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p>Keterangan:</p>
      <div className="flex">
        <span class="bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300">
          !
        </span>
        Stok Kurang
      </div>

      {/*  */}
      <div className="bg-white p-4 rounded-md divide-y divide-gray-500 w-full ">
        <h1 className="text-lg font-bold text-gray-700 pb-2">
          {"Stok " + title + " di bawah dari " + formatCurrency(minStok)}
        </h1>
        <dl className="w-full text-gray-900  dark:text-white  pt-2">
          <div className="flex flex-col">
            <dd className="text-lg font-semibold">
              {formatCurrency(data.filter((x) => x.stok <= minStok).length) +
                " dari " +
                formatCurrency(data.length)}
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
