import {
  laporanakhirmodul_genereate_reducers,
  laporanakhirmodul_get_reducers,
} from "../reducers/laporanAkhirReducers";

const modulLaporanAkhir = {
  laporanakhirmodul_get: laporanakhirmodul_get_reducers,
  laporanakhirmodul_genereate: laporanakhirmodul_genereate_reducers,
};

export default modulLaporanAkhir;
