import axios from "axios";
import {
  PENJUALAN_CABANG_DETAIL_GET_FAIL,
  PENJUALAN_CABANG_DETAIL_GET_REQUEST,
  PENJUALAN_CABANG_DETAIL_GET_RESET,
  PENJUALAN_CABANG_DETAIL_GET_SUCCESS,
} from "../constants/PenjualanCabangDetailConstants";
import { API_PENJUALAN_CABANG_DETAIL_GET } from "../init/api_url";
import { Logout } from "../../loginModul/actions/loginAction";

export const GetPenjualanCabangDetail =
  ({ idPenjualan, outlet }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: PENJUALAN_CABANG_DETAIL_GET_REQUEST,
      });
      const {
        userLogin: { token },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          penjualan: idPenjualan,
          outlet: outlet,
        },
      };
      const { data: data } = await axios.get(
        API_PENJUALAN_CABANG_DETAIL_GET,
        config
      );
      console.log("testing Action get detail penjualan", data);
      dispatch({
        type: PENJUALAN_CABANG_DETAIL_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(Logout());
      }
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: PENJUALAN_CABANG_DETAIL_GET_FAIL,
        payload: message,
      });
    }
  };

export const RGetPenjualanCabangDetail = () => async (dispatch, getState) => {
  dispatch({
    type: PENJUALAN_CABANG_DETAIL_GET_RESET,
  });
};
