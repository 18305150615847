import React, { useEffect } from "react";

export default function ScrollBlocked({ blocked }) {
  useEffect(() => {
    if (blocked) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [blocked]);

  return <></>;
}
