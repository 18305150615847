export function includePPN(sumTotalItem, ppn) {
  let nilaiPPN = (sumTotalItem * ppn) / 100;
  return sumTotalItem + nilaiPPN;
}

function withoutPPN(grandtotal, ppn) {
  let a = parseInt(grandtotal) * 100;
  let b = parseInt(ppn) + 100;
  return a / b;
}

export function nominalPPN(grandtotal, ppn) {
  return grandtotal - withoutPPN(grandtotal, ppn);
}

export function ppnFromPureTotal(sumTotalItem, ppn) {
  return includePPN(sumTotalItem, ppn) - sumTotalItem;
}

//PPN dan Service Charge
export function getPureValue(total, persen) {
  let a = parseInt(total) * 100;
  let b = parseInt(persen) + 100;
  return a / b;
}
export function getTotalRpPersenFromRoTotal(total, persen) {
  return total - getPureValue(total, persen);
}
export function getRpPersenFromRpTotal(total, persen, totalPersen) {
  let pure = getPureValue(total, totalPersen);
  return (pure * persen) / 100;
}

export function getRpPersenFromRpTotalPure(total, persen) {
  // Sebelum total kenapersen
  return (total * persen) / 100;
}
