import React, { useState } from "react";
import Select from "react-select";
import SelectFilterDynamic from "../../molekuls/Select/SelectFilterDynamic";
import { getListYearMonth } from "../../../../../general/functions/dateCustome";

export default function FilterAppointment() {
  const [sort, setSort] = useState(null);
  const [month, setMonth] = useState(null);
  let monthYear = getListYearMonth(2017, 2023);
  console.log("DPArtHead", monthYear);
  return (
    <div className="flex justify-between p-2">
      <div className="flex gap-2 items-center">
        <div className="w-auto max-w-fit  bg-red-50">
          <Select
            //   defaultValue={[]}
            isMulti
            name="colors"
            options={[
              {
                value: "ocean",
                label: "Ocean",
                color: "#00B8D9",
                isFixed: true,
              },
              {
                value: "blue",
                label: "Blue",
                color: "#0052CC",
                isDisabled: true,
              },
              { value: "purple", label: "Purple", color: "#5243AA" },
              { value: "red", label: "Red", color: "#FF5630", isFixed: true },
              { value: "orange", label: "Orange", color: "#FF8B00" },
              { value: "yellow", label: "Yellow", color: "#FFC400" },
              { value: "green", label: "Green", color: "#36B37E" },
              { value: "forest", label: "Forest", color: "#00875A" },
              { value: "slate", label: "Slate", color: "#253858" },
              { value: "silver", label: "Silver", color: "#666666" },
              { value: "silver1", label: "Silver", color: "#666666" },
              { value: "silver2", label: "Silver", color: "#666666" },
              { value: "silver3", label: "Silver", color: "#666666" },
              { value: "silver4", label: "Silver", color: "#666666" },
              { value: "silver5", label: "Silver", color: "#666666" },
              { value: "silver6", label: "Silver", color: "#666666" },
            ]}
            className="basic-multi-select"
            classNamePrefix="select"
            placeholder="Pilih Cabang Tertentu"
          />
        </div>
        <SelectFilterDynamic
          className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
          dataSelectList={[
            { name: "Nama Cabang", id: 1 },
            { name: "Appointment Tertinggi", id: 2 },
            { name: "Appointment Terendah", id: 3 },
            { name: "Appointment Tertinggi Hari Ini", id: 4 },
            { name: "Appointment Terendah Hari Ini", id: 5 },
          ]}
          placeholder="Sort By"
          valueOption={sort}
          valueGetSingle={(v) => {
            if (v == null) {
              setSort(null);
            } else {
              setSort(v.id);
            }
          }}
        />

        {/* <SelectFilterDynamic
          className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
          dataSelectList={monthYear}
          placeholder="Month"
          valueOption={month}
          valueGetSingle={(v) => {
            if (v == null) {
              setMonth(null);
            } else {
              setMonth(v.id);
            }
          }}
        /> */}

        <button
          disabled={false}
          type="button"
          className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
        >
          Filter
        </button>
      </div>
    </div>
  );
}
