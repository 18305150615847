import { penjualanModul_get_all_cabang_reducers } from "../reducers/PenjualanAllCabangReducers";
import { penjualanModul_get_cabang_detail_reducers } from "../reducers/PenjualanCabangDetailReducers";
import { penjualanModul_get_cabang_reducers } from "../reducers/PenjualanCabangReducers";

const modulPenjualan = {
  penjualanModul_get_all_cabang: penjualanModul_get_all_cabang_reducers,
  penjualanModul_get_cabang: penjualanModul_get_cabang_reducers,
  penjualanModul_get_cabang_detail: penjualanModul_get_cabang_detail_reducers,
};

export default modulPenjualan;
