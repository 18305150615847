import React from "react";
import logoCompany from "../asseets/image/logo/LOGO-EKLES-Large-New.svg";

export default function PageWelcome() {
  return (
    <div>
      <div class="min-h-[85vh] flex flex-grow items-center justify-center bg-gray-50 flex-col">
        <div className=" text-center ">
          <a
            href="#"
            className="flex items-center  text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img src={logoCompany} className="h-8 mr-0" alt="Ekles Logo" />
            Dashboard
          </a>
        </div>
        <p className="text-lg font-semibold text-gray-900 dark:text-white">
          Selamat Datang
        </p>
      </div>
    </div>
  );
}
