import React from "react";
import MultipleLineChart from "../../molekuls/Chart/MultipleLineChart";

export default function ChartAppointment({ labelData, listData }) {
  return (
    <div className="h-56">
      <MultipleLineChart
        labelData={labelData}
        datasets={[
          {
            label: "Appointment",
            data: listData,
            borderWidth: 2,
            borderColor: "rgba(17, 24, 39, 1)",
            backgroundColor: "rgba(17, 24,39, 0.8)",
            fill: false,
          },
        ]}
        title="Appointment"
      />
    </div>
  );
}
