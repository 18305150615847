import { URL_API } from "../../../general/init/url";

// InventoryAllCabang
export const API_INVENTORY_ALL_CABANG_GET = URL_API + "/api/InventoryData";
export const API_INVENTORY_ALL_CABANG_GET_DOWNLOAD =
  URL_API + "/api/InventoryData/Download";
// InventoryCabang
export const API_INVENTORY_CABANG_GET = URL_API + "/api/InventoryData/Detail";
export const API_INVENTORY_CABANG_GET_DOWNLOAD =
  URL_API + "/api/InventoryData/Detail/Download";
