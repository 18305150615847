export function inputanEmailCheck(email) {
  const re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function inputanStringCheck(val) {
  switch (val) {
    case undefined:
      return false;
    case null:
      return false;
    case "":
      return false;
    default:
      return true;
  }
}

export function filterbyArray(arraySumber, arrayCheck) {
  console.log(arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id)));
  return arraySumber?.filter((o) => arrayCheck.some((p) => o.id == p.id));
}
