import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { classNames } from "../../../general/functions/atributComponent";
import { ChangePassUser, RChangePassUser } from "../actions/changepassAction";
import { SetAlertBasic } from "../../../general/components/alertLibrary/actions/alertBasicAction";

export default function PageChangePass() {
  const [valid, setValid] = useState(false);
  const [passwordOld, setPasswordOld] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const storeCPass = useSelector((state) => state.userChangePass);

  useEffect(() => {
    // console.log("store", storeCPass);
    if (storeCPass.success) {
      dispatch(SetAlertBasic("SUCCESS", <> "Berhasil ubah kata sandi" </>));
      setLoading(false);
      dispatch(RChangePassUser());
    }
    if (storeCPass.error) {
      dispatch(SetAlertBasic("WARNING", <> {storeCPass.error} </>));
      setLoading(false);
      dispatch(RChangePassUser());
    }
  }, [storeCPass]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValid(true);
    const form = event.currentTarget;
    let passwordOld_tmp = form.passwordOld.value;
    let password_tmp = form.passwordNew.value;
    let password2_tmp = form.passwordNew2.value;

    if (
      !(passwordOld_tmp?.length <= 1) &&
      !(password_tmp?.length <= 1) &&
      password_tmp == password2_tmp
    ) {
      console.log("Berhasil");
      // send to server to check
      setLoading(true);
      dispatch(
        ChangePassUser({ passOld: passwordOld_tmp, passNew2: password2_tmp })
      );
    }
  };

  return (
    <div>
      <div class="min-h-[85vh] flex flex-grow items-center justify-center bg-gray-50 flex-col">
        <div className=" flex- max-w-sm w-full">
          <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
            {/* Sign in to Dashboard */}
            Ubah Kata Sandi
          </h1>
        </div>
        <div className="max-w-sm w-full pt-2">
          <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
            <div>
              <label
                htmlFor="passwordOld"
                className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
              >
                Kata Sandi Saat Ini
              </label>
              <input
                type="password"
                name="passwordOld"
                id="passwordOld"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                onChange={(v) => {
                  setPasswordOld(v.target.value.trim());
                }}
              />
              <label
                className={classNames(
                  valid && passwordOld?.length === 0 ? "block" : "hidden",
                  " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                )}
              >
                *silakan masukan
              </label>
            </div>
            <div>
              <label
                htmlFor="passwordNew"
                className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
              >
                Kata Sandi Baru
              </label>
              <input
                type="password"
                name="passwordNew"
                id="passwordNew"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                onChange={(v) => {
                  setPassword(v.target.value.trim());
                }}
              />
              <label
                className={classNames(
                  valid && password?.length === 0 ? "block" : "hidden",
                  " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                )}
              >
                *silakan masukan
              </label>
            </div>
            <div className="pb-6">
              <label
                htmlFor="passwordNew2"
                className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
              >
                Ulangi Kata Sandi Baru
              </label>
              <input
                type="password"
                name="passwordNew2"
                id="passwordNew2"
                placeholder="••••••••"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                required=""
                onChange={(v) => {
                  setPassword2(v.target.value.trim());
                }}
              />
              <div
                className={classNames(
                  valid && (password2?.length === 0 || password !== password2)
                    ? "block"
                    : "hidden",
                  "absolute "
                )}
              >
                <label
                  className={classNames(
                    valid && password2?.length === 0 ? "block" : "hidden",
                    " ml-2  text-sm font-medium text-left text-red-500 dark:text-red-500 "
                  )}
                >
                  *silakan masukan
                </label>
                <label
                  className={classNames(
                    valid && password !== password2 ? "block" : "hidden",
                    " ml-2  text-sm font-medium text-left text-red-500 dark:text-red-500 "
                  )}
                >
                  *kata sandi tidak sesuai
                </label>
              </div>
            </div>
            <button
              disabled={loading}
              type="submit"
              className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              {loading ? "Harap tunggu..." : "Simpan"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
