import axios from "axios";
import {
  LOGINMODUL_USER_INFO_BASIC_FAIL,
  LOGINMODUL_USER_INFO_BASIC_REQUEST,
  LOGINMODUL_USER_INFO_BASIC_RESET,
  LOGINMODUL_USER_INFO_BASIC_SUCCESS,
} from "../constants/userInfoBasicConstants";
import { API_USER_INFO_BASIC } from "../init/apiConstants";
import { Logout } from "./loginAction";

export const GetUserInfoBasic = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOGINMODUL_USER_INFO_BASIC_REQUEST,
    });
    const {
      userLogin: { token },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data: data } = await axios.get(API_USER_INFO_BASIC, config);
    dispatch({
      type: LOGINMODUL_USER_INFO_BASIC_SUCCESS,
      payload: data,
    });
  } catch (error) {
    if (error.response.status == 401) {
      dispatch(Logout());
    }
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: LOGINMODUL_USER_INFO_BASIC_FAIL,
      payload: message,
    });
  }
};

export const RGetUserInfoBasic = () => async (dispatch, getState) => {
  dispatch({
    type: LOGINMODUL_USER_INFO_BASIC_RESET,
  });
};
