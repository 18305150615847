import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

// datasets: [
//     {
//       label: " Total Participant",
//       data: valueData,

//       borderColor: "rgba(229, 136, 132, 1)",
//       backgroundColor: "rgba(255, 99, 132, 0.8)",
//     },
//   ],
export default function MultipleLineChart({
  labelData = [],
  datasets = [],
  title = "",
  darkMode = false,
}) {
  let fontColor = darkMode ? "rgb(209, 213, 219, 0.5)" : "rgb(17, 24, 39, 1)";
  const optionsVal = () => {
    let obj = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: title,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: { color: fontColor, beginAtZero: true },
        },

        y: {
          grid: {
            // display: false,
          },
          grace: "20%",
          ticks: { color: fontColor, beginAtZero: true },
        },
      },
    };
    obj.maintainAspectRatio = false;
    // if (darkMode == true) {
    //   obj.plugins.legend = {
    //     labels: {
    //       color: "red",
    //     },
    //     // labels: {
    //     //   // This more specific font property overrides the global property
    //     //   fontColors: "blue",
    //     //   font: {
    //     //     color: "red",
    //     //     size: 10,
    //     //   },
    //     // },
    //   };
    // }
    return obj;
  };
  const labels = labelData;
  const data = {
    labels,
    datasets: datasets,
  };

  return <Line options={optionsVal()} data={data} />;
}
