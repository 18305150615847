// import axios from "axios";
// import { GetConfigFrServer } from "../../../actions/configFrServerActions";
// import { URL_API } from "../../../constants/urlApiConstant";
import axios from "axios";
import {
  LOGINMODUL_LOGIN_REQUEST,
  LOGINMODUL_LOGIN_SUCCESS,
  LOGINMODUL_LOGIN_FAIL,
  LOGINMODUL_LOGIN_RESET,
} from "../constants/loginConstants";
import { URL_API } from "../../../general/init/url";
import { API_LOGIN, API_LOGOUT } from "../init/apiConstants";
import {
  LOGINMODUL_LOGOUT_FAIL,
  LOGINMODUL_LOGOUT_PENGGUNA,
  LOGINMODUL_LOGOUT_REQUEST,
  LOGINMODUL_LOGOUT_RESET,
  LOGINMODUL_LOGOUT_SUCCESS,
} from "../constants/logoutConstants";

export const RLogin = (data) => async (dispatch) => {
  dispatch({
    type: LOGINMODUL_LOGIN_RESET,
  });
};

export const Login = (d) => async (dispatch) => {
  try {
    dispatch({
      type: LOGINMODUL_LOGIN_REQUEST,
    });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      URL_API + API_LOGIN,
      { username: d.username, password: d.password },
      config
    );
    localStorage.setItem("token", data.access_token);
    dispatch({
      type: LOGINMODUL_LOGIN_SUCCESS,
      payload: data.access_token,
    });
    window.location.reload(false);
  } catch (error) {
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;
    dispatch({
      type: LOGINMODUL_LOGIN_FAIL,
      payload: message,
    });
  }
};

export const RLogout = (data) => async (dispatch) => {
  dispatch({
    type: LOGINMODUL_LOGOUT_RESET,
  });
};

export const Logout = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: LOGINMODUL_LOGOUT_REQUEST,
    });
    const {
      userLogin: { token },
    } = getState();
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const { data } = await axios.post(URL_API + API_LOGOUT, {}, config);
    localStorage.setItem("token", data.access_token);
    // console.log("here login");
    // dispatch(GetConfigFrServer(data.accessToken));
    // console.log("action Login akses", access_token);
    localStorage.removeItem("token");
    dispatch({
      type: LOGINMODUL_LOGOUT_PENGGUNA,
    });
    dispatch({
      type: LOGINMODUL_LOGOUT_SUCCESS,
      payload: data.access_token,
    });
    // window.location.reload(false);
  } catch (error) {
    if (error.response.status == 401) {
      localStorage.removeItem("token");
      dispatch({
        type: LOGINMODUL_LOGOUT_PENGGUNA,
      });
      dispatch({
        type: LOGINMODUL_LOGOUT_SUCCESS,
        payload: null,
      });
    } else {
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;
      dispatch({
        type: LOGINMODUL_LOGOUT_FAIL,
        payload: message,
      });
    }
  }
};
