import React from "react";
import { LIST_DATES, dummyValuebyDates } from "../../dummyData/dummyFunction";
import MultipleLineChart from "../molekuls/Chart/MultipleLineChart";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import ChartAppointment from "./DPartAppointment-F/ChartAppointment";
import ModalDetailAppointment from "./DPartAppointment-F/ModalDetailAppointment";

export default function DPartAppointment() {
  return (
    <div className="h-auto bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 relative">
      <div className="mb-4">
        <h5 className="text-xl -mb-2 font-bold dark:text-white text-gray-700">
          APPOINTMENT
        </h5>
        <label className="text-sm font-normal">show 232 dari 10000 </label>
      </div>
      <ModalDetailAppointment />
      <ChartAppointment
        labelData={LIST_DATES()}
        listData={dummyValuebyDates(0, 500)}
      />
    </div>
  );
}
