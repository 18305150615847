import React from "react";
import LineChart from "./views/molekuls/Chart/LineChart";
import MultipleLineChart from "./views/molekuls/Chart/MultipleLineChart";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import Modal from "../../general/components/modalLibrary";
import { LIST_DATES } from "./dummyData/dummyFunction";
import DPartKeuangan from "./views/parts/DPartKeuangan";
import DPartAppointment from "./views/parts/DPartAppointment";
import DPartItemTerjual from "./views/parts/DPartItemTerjual";
import DPartAbsensiTreat from "./views/parts/DPartAbsensiTreat";
import NavBar1 from "../../general/components/NavbarLibrary";
import DPartHead from "./views/parts/DPartHead";
import { getListYearMonth } from "../../general/functions/dateCustome";
import { useHandleFirstPageName } from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";

function testDate() {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_days = [];
  while (date.getMonth() == month) {
    var d =
      date.getFullYear() +
      "-" +
      date.getMonth().toString().padStart(2, "0") +
      "-" +
      date.getDate().toString().padStart(2, "0");
    all_days.push(d);
    date.setDate(date.getDate() + 1);
  }
  console.log(all_days);
}

function getArrayDateMonth() {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_days = [];
  while (date.getMonth() == month) {
    var d = date.getDate().toString().padStart(2, "0");
    all_days.push(d);
    date.setDate(date.getDate() + 1);
  }

  return all_days;
}

function dummyValuebyDates(min, max) {
  var date = new Date();
  var month = date.getMonth();
  date.setDate(1);
  var all_val = [];
  while (date.getMonth() == month) {
    var d = Math.floor(Math.random() * (max - min + 1)) + min;
    all_val.push(d);
    date.setDate(date.getDate() + 1);
  }
  return all_val;
}
console.log("List Bulan Tahun Testing", getListYearMonth(2017, 2023));
export default function DashboardModul() {
  useHandleFirstPageName("Dashboard", "#");
  return (
    <>
      <div className="flex flex-col w-full space-y-4 p-4 bg-gray-100 dark:bg-gray-900">
        <DPartHead />
        {/* <div className="grid grid-cols-3 gap-4 mb-4">
        <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
          <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
        </div>
        <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
          <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
        </div>
        <div className="flex items-center justify-center h-24 rounded bg-gray-50 dark:bg-gray-800">
          <p className="text-2xl text-gray-400 dark:text-gray-500">+</p>
        </div>
      </div> */}
        {/* <div className=" bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8">
        <h5 className="text-xl font-bold dark:text-white text-gray-700">
          Total Member
        </h5>

        <div className="w-full bg-gray-400 h-5 rounded-lg relative">
          <div className="absolute inset-x-0  w-10 mx-auto -mt-1  top-0">
            3200
          </div>
        </div>
      </div> */}
        <DPartKeuangan />

        <div className=" grid grid-cols-12 gap-4 w-full ">
          <div className=" col-span-7 space-y-4 ">
            <DPartAppointment />
            <DPartItemTerjual />
          </div>
          <div className="self-center w-full col-span-5 ">
            <DPartAbsensiTreat />
          </div>
        </div>
      </div>
    </>
  );
}
