import React, { useEffect, createContext } from "react";
import { useSelector } from "react-redux";
import { Navigate, Outlet, useNavigate } from "react-router-dom/dist";

// export const UserTypeContext = createContext();
export default function AuthValidasi() {
  const navigate = useNavigate();
  const store = useSelector((state) => state.userLogin);
  const storeLogout = useSelector((state) => state.userLogout);

  useEffect(() => {
    console.log("Here call login", store.token);
    if (store.error) {
      navigate("/404");
    }
    if (store.token == null) {
      navigate("/login");
    }
  }, [store]);
  useEffect(() => {
    console.log("Here call login", store.token);
    if (storeLogout.success) {
      navigate("/login");
    }
  }, [storeLogout]);
  // console.log("ss", store.loading, store.token != null, typeof store.token);
  return (
    <div>
      {store.token != null && typeof store.token == "string" ? (
        <Outlet />
      ) : (
        <Navigate to="/login" />
      )}
    </div>
  );
}
