import React, { useEffect, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../../general/components/modalLibrary";
import SelectFilterDynamic from "../../../../dashboardModul/views/molekuls/Select/SelectFilterDynamic";
import {
  getListYears,
  getMonth,
  getMonthById,
} from "../../../../../general/functions/dateCustome";

export default function ModalFilterTanggalLabaRugi(props) {
  const { monthId, yearId, handleOnChange } = props;
  const date = new Date();
  const [show, setShow] = useState(false);
  const [month, setMonth] = useState(null);
  const [year, setYear] = useState(date.getFullYear());
  const [editeMode, setEditeMode] = useState(false);
  let yearList = getListYears(2017, date.getFullYear());

  useEffect(() => {
    if (editeMode == false) {
      setMonth(monthId);
      if (!isNaN(yearId)) {
        setYear(parseInt(yearId));
      }
      console.log("date test filter", yearId, monthId);
    }
  }, [monthId, yearId, editeMode]);

  const handleFilter = (e) => {
    e.preventDefault();
    let y = year,
      m = month;
    if (month == "00") {
      m = null;
    }
    handleOnChange({ month: m, year: y });
    setEditeMode(false);
    setShow(false);
  };

  return (
    <>
      <div className="bg-gray-600  right-0 top-0  rounded-lg flex items-center gap-2">
        <button
          onClick={(e) => {
            e.preventDefault();
            setEditeMode(true);
            setShow(true);
          }}
          type="button"
          className="bg-gray-800 hover:bg-gray-700 outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none  text-gray-100 hover:text-gray-800 rounded-bl-lg"
        >
          <FunnelIcon className="h-6 w-6 text-gray-200" />
        </button>
        <p className="text-gray-200">
          {monthId != null
            ? getMonthById(monthId).name + ","
            : "Semua bulan pada"}{" "}
          {year}
        </p>
      </div>

      <Modal
        show={show}
        overflow={false}
        onClose={() => {
          setShow(false);
        }}
        size="md"
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700  top-0 p-4  ">
            Filter
          </h5>{" "}
        </div>
        <div className="p-4 bg-white border-t">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div>Month</div>
              <div className="">
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto "
                  dataSelectList={getMonth()}
                  placeholder="Month"
                  valueOption={month}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setMonth("00");
                    } else {
                      setMonth(v.id);
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Year</div>
              <div>
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
                  dataSelectList={yearList}
                  placeholder="Year"
                  valueOption={year}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setYear(date.getFullYear());
                    } else {
                      setYear(v.id);
                    }
                  }}
                />
              </div>
            </div>
            {/* footer */}
            <div className="flex justify-end  gap-2">
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-primary-600 bg-gray-200 hover:bg-primary-300 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Tutup
                </button>
              </div>
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
