import React from "react";

export default function ChartItemTerjualLabel() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
      {/*  */}
      <div>
        <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
          <div className="w-5 h-5 bg-green-500 rounded-full"></div>
          <div className="w-full">
            <h4 className="text-base font-bold dark:text-white  mt-1"> 111</h4>
            <h5 className="text-base font-semibold dark:text-white  text-gray-700 -mt-1">
              Treatment non Medis
            </h5>
          </div>
        </div>
        <p className="text-sm font-normal text-gray-700 ml-12">
          Top Ranking:
          <ol className=" text-gray-500 list-decimal list-inside dark:text-gray-400">
            <li>List item one</li>
            <li>List item two</li>
            <li>List item three</li>
          </ol>
        </p>
      </div>

      {/*  */}

      {/*  */}
      <div>
        <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
          <div className="w-5 h-5 bg-red-500 rounded-full"></div>
          <div className="w-full">
            <h4 className="text-base font-bold dark:text-white  mt-1"> 1000</h4>
            <h5 className="text-base font-semibold dark:text-white  text-gray-700 -mt-1">
              Treatment
            </h5>
          </div>
        </div>
        <p className="text-sm font-normal text-gray-700 ml-12">
          Top Ranking:
          <ol className=" text-gray-500 list-decimal list-inside dark:text-gray-400">
            <li>List item one</li>
            <li>List item two</li>
            <li>List item three</li>
          </ol>
        </p>
      </div>
      {/*  */}

      {/*  */}
      <div>
        <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
          <div className="w-5 h-5 bg-blue-500 rounded-full"></div>
          <div className="w-full">
            <h4 className="text-base font-bold dark:text-white  mt-1"> 234</h4>
            <h5 className="text-base font-semibold dark:text-white  text-gray-700 -mt-1">
              Produk
            </h5>
          </div>
        </div>
        <p className="text-sm font-normal text-gray-700 ml-12">
          Top Ranking:
          <ol className=" text-gray-500 list-decimal list-inside dark:text-gray-400">
            <li>List item one</li>
            <li>List item two</li>
            <li>List item three</li>
          </ol>
        </p>
      </div>
      {/*  */}
      {/*  */}
      <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
        <div className="w-5 h-5 bg-gray-200 rounded-full"></div>
        <div className="w-full">
          <h4 className="text-base font-bold dark:text-white  mt-1"> 1345</h4>
          <h5 className="text-base font-semibold dark:text-white  text-gray-700 -mt-1">
            Total
          </h5>
        </div>
      </div>
      {/*  */}
    </div>
  );
}
