import React from "react";

export default function ChartKeuanganLabelSm() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
      {/*  */}
      <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
        <div className="w-5 h-5 bg-green-500 rounded-full"></div>
        <div className="w-full">
          <h4 className="text-base font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-sm font-noemal dark:text-white  text-gray-700 -mt-1">
            Pemasukan
          </h5>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
        <div className="w-5 h-5 bg-red-500 rounded-full"></div>
        <div className="w-full">
          <h4 className="text-base font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-sm font-noemal dark:text-white  text-gray-700 -mt-1">
            Pengeluaran
          </h5>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
        <div className="w-5 h-5 bg-yellow-500 rounded-full"></div>
        <div className="w-full">
          <h4 className="text-base font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-sm font-noemal dark:text-white  text-gray-700 -mt-1">
            Piutang
          </h5>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className=" p-4 sm:p-6 xl:p-6 flex items-center  gap-2 h-10  dark:bg-gray-800 ">
        <div className="w-5 h-5 bg-blue-500 rounded-full"></div>
        <div className="w-full">
          <h4 className="text-base font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-sm font-noemal dark:text-white  text-gray-700 -mt-1">
            Omset
          </h5>
        </div>
      </div>
      {/*  */}
    </div>
  );
}
