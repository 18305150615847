import React, { useState } from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { miniSerializeError } from "@reduxjs/toolkit";
const HEADER_TABEL_FIX = [
  { name: "No", left: 0 },
  { name: "Nama", left: 8 },
];

const HEADER_TABLE = [
  { name: "Komisi Produk" },
  { name: "Komisi Treatment Medis" },
  { name: "Komisi Treatment Non Medis" },
  { name: "Komisi Lain" },
  { name: "Total Komisi" },
];
export default function TabelKomisiCabang(props) {
  const { data, minStok, title = "" } = props;
  const [search, setSearch] = useState("");
  let tUser = 0;
  let tKomisi = 0;
  console.log("table data", data);
  return (
    <div>
      <div className="flex justify-between ml-2">
        <h1 className="text-xl font-bold text-gray-700 pb-2 ">{title}</h1>
        <div>
          <input
            type="text"
            name="first-name"
            id="first-name"
            placeholder="Cari"
            className=" h-8 px-2 ring-primary-500 focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border border-gray-300 rounded-md sm:w-auto  bg-white"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <div
        style={{ minHeight: "20rem" }}
        className="bg-white rounded-md border-2 pb-2"
      >
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
              <div
                className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative"
                style={{ maxHeight: "50vh" }}
              >
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100 ">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      {HEADER_TABEL_FIX.map((x, i) => {
                        let name = x.name;

                        return (
                          <th
                            scope="col"
                            className={classNames(
                              false
                                ? "bg-gray-50"
                                : "text-gray-500 bg-gray-100",
                              "sticky left-" +
                                x.left +
                                " z-20 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200 select-none "
                            )}
                          >
                            {name}
                          </th>
                        );
                      })}
                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {data
                      ?.filter((x) => {
                        if (search == "") {
                          return true;
                        } else {
                          return x.nama
                            .toLowerCase()
                            .includes(search.toLowerCase());
                        }
                      })
                      .map((x, i) => {
                        tUser++;
                        tKomisi += parseInt(x.totalkomisi);
                        return (
                          <tr
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white"
                            )}
                          >
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-center py-2 sticky  left-0 z-10"
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500 ">
                                {++i}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-left sticky  left-8 z-0"
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500 w-52   px-2 flex justify-between items-center">
                                <div>{x?.nama}</div>
                              </div>
                            </td>

                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {formatCurrency(x.komisiProduk)}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {formatCurrency(x.komisiTreatmentMedis)}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {formatCurrency(x.komisiTreatmentNonMedis)}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {formatCurrency(x.komisiLain)}
                              </div>
                            </td>
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-right "
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500  px-2 ">
                                {formatCurrency(x.totalkomisi)}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
