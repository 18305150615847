import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { getAlertBasicReducers } from "./general/components/alertLibrary/reducers/alertBasicReducers";
import modulLoginReducers from "./moduls/loginModul/init/store";
import modulLaporanAkhir from "./moduls/laporanAkhirModul/init/store";
import modulInventory from "./moduls/inventoryModul/init/store";
import modulTopSpander from "./moduls/topSpenderModul/init/store";
import modulKomisi from "./moduls/komisiModul/init/store";
import modulPenjualan from "./moduls/penjualanModul/init/store";
import modulLabaRugi from "./moduls/labaRugiModul/init/store";
import generalComponent from "./general/initComponents/store";
import modulCabang from "./moduls/cabangModul/init/store";
const reducerGeneral = {
  getAlertBasic: getAlertBasicReducers,
};

const allReducerConst = Object.assign(
  modulCabang,
  generalComponent,
  reducerGeneral,
  modulLoginReducers,
  modulLaporanAkhir,
  modulInventory,
  modulTopSpander,
  modulKomisi,
  modulPenjualan,
  modulLabaRugi
);
const reducer = combineReducers(allReducerConst);

const userInfoFromStorage = localStorage.getItem("token")
  ? localStorage.getItem("token")
  : null;
//   const tokenAddress = localStorage.getItem("tad")
//     ? localStorage.getItem("tad")
//     : null;

const initialState = {
  userLogin: { token: userInfoFromStorage },
  // addressmodul_get_token: { tad: tokenAddress },
};

const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
// console.log("store", store.getState());
export default store;
