import {
  PENJUALAN_CABANG_DETAIL_GET_FAIL,
  PENJUALAN_CABANG_DETAIL_GET_REQUEST,
  PENJUALAN_CABANG_DETAIL_GET_RESET,
  PENJUALAN_CABANG_DETAIL_GET_SUCCESS,
} from "../constants/PenjualanCabangDetailConstants";

export const penjualanModul_get_cabang_detail_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case PENJUALAN_CABANG_DETAIL_GET_REQUEST:
      return { loading: true };
    case PENJUALAN_CABANG_DETAIL_GET_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case PENJUALAN_CABANG_DETAIL_GET_FAIL:
      return { loading: false, error: action.payload };
    case PENJUALAN_CABANG_DETAIL_GET_RESET:
      return {};
    default:
      return state;
  }
};
