import React, { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";
import { classNames } from "../../functions/atributComponent";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
const moment = require("moment");
export default function DateBasic(props) {
  const { startDate, getDate, minDate, endDate = new Date(), setDate } = props;

  const [postStartDate, setPostStartDate] = useState(0);
  const [postEndDate, setPostEndDate] = useState(0);
  return (
    <div className=" ">
      {" "}
      <div className="relative w-full mt-1">
        <DatePicker
          minDate={new Date()}
          selected={
            postStartDate != 0
              ? new Date(moment.unix(postStartDate).format("YYYY-MM-DD"))
              : null
          }
          onChange={(date) => setPostStartDate(moment(date).unix())}
          selectsStart
          startDate={new Date(moment.unix(postStartDate).format("YYYY-MM-DD"))}
          endDate={new Date(moment.unix(postEndDate).format("YYYY-MM-DD"))}
          nextMonthButtonLabel=">"
          previousMonthButtonLabel="<"
          popperClassName="react-datepicker-left"
          customInput={
            <ButtonInput
              className={classNames(
                true
                  ? classNames(
                      postStartDate != 0
                        ? "border-gray-200"
                        : "border-red-700 border-2",
                      "  "
                    )
                  : "",
                ""
              )}
            />
          }
          renderCustomHeader={renderCustomHeader}
        />
        <label
          className={classNames(
            true
              ? classNames(
                  postStartDate != 0 ? "hidden" : "block",
                  "text-red-400 text-sm ml-2"
                )
              : "hidden",
            ""
          )}
        >
          * Please set more than 'Start' date of open publish
        </label>
      </div>
      {/* <DatePicker
        minDate={moment(minDate, "YYYY-MM-DD")}
        // selected={
        //   moment(getDate, "YYYY-MM-DD").unix() != 0
        //     ? moment(getDate, "YYYY-MM-DD")
        //     : null
        // }
        // onChange={(date) => setDate()}
        // selectsStart
        // startDate={moment(startDate, "YYYY-MM-DD")}
        // endDate={moment(endDate, "YYYY-MM-DD")}
        nextMonthButtonLabel=">"
        previousMonthButtonLabel="<"
        popperClassName="react-datepicker-left"
        customInput={
          <ButtonInput
            className={classNames(
              true
                ? classNames(
                    getDate != null
                      ? "border-gray-200"
                      : "border-red-700 border-2",
                    "  "
                  )
                : "",
              ""
            )}
          />
        }
        renderCustomHeader={renderCustomHeader}
      /> */}
    </div>
  );
}

const ButtonInput = forwardRef(({ value, onClick, className, ph }, ref) => (
  <button
    onClick={onClick}
    ref={ref}
    type="button"
    className={
      "w-full sm:text-sm rounded-md inline-flex justify-start px-3 py-2 text-sm font-medium text-gray-700 bg-white border-2 focus:outline-none focus:ring-1 focus:ring-offset-0 0 focus:ring-primary-500 focus:border-primary-500 " +
      className
    }
  >
    {value ? (
      moment(new Date(value)).format("DD MMMM yyyy")
    ) : (
      <label className="text-gray-400">{ph ? ph : "Select Date..."}</label>
    )}{" "}
  </button>
));

const renderCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
}) => (
  <div className="flex items-center justify-between px-2 py-2">
    <span className="text-lg text-gray-700">
      {moment(date).format("MMMM yyyy")}
    </span>

    <div className="space-x-2">
      <button
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
        type="button"
        className={`
                    ${
                      prevMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    }
                    inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-500
                `}
      >
        <ChevronLeftIcon className="w-5 h-5 text-gray-600" />
      </button>

      <button
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
        type="button"
        className={`
                    ${
                      nextMonthButtonDisabled && "cursor-not-allowed opacity-50"
                    }
                    inline-flex p-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-0 focus:ring-primary-500
                `}
      >
        <ChevronRightIcon className="w-5 h-5 text-gray-600" />
      </button>
    </div>
  </div>
);
