import React from "react";

export default function DPartAbsensiTreat() {
  return (
    <div
      className="bg-white shadow rounded-lg  overflow-y-auto"
      style={{ height: "75vh" }}
    >
      {" "}
      <div className="mb-2 pt-4 sticky top-0 bg-white px-4 sm:px-6 xl:px-8 border-b pb-2">
        <div className="flex justify-between items-end relative">
          <h5 className="text-lg font-bold dark:text-white text-gray-700 absolute top-0 w-full ">
            ABSENSI TREATMENT
          </h5>
          <div className="mt-8">
            <label className="text-sm font-normal">
              Total 1000 / 400 kehadiran
            </label>
          </div>
          <form className="w-3/6 -mr-6">
            <label
              for="default-search"
              className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
            >
              Search
            </label>
            <div className="relative w-full ">
              <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                <svg
                  aria-hidden="true"
                  className="w-5 h-5 text-gray-500 dark:text-gray-400"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  ></path>
                </svg>
              </div>
              <input
                type="search"
                id="default-search"
                className="block w-full p-2 pr-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Cari Nama Cabang"
                required
              />
            </div>
          </form>
        </div>
      </div>
      <div className="">
        <div className="flow-root px-4 sm:px-6 xl:px-8">
          <ul
            role="list"
            className="divide-y divide-gray-200 dark:divide-gray-700 "
          >
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-semibold text-gray-900 truncate dark:text-white">
                    E Manado
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 overflow-hidden">
                    <div
                      className="bg-gray-900 h-4 rounded-lg
                  text-white text-xs text-center "
                      style={{ width: "30%" }}
                    >
                      {" "}
                      200
                    </div>
                  </div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 / 200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
            <li className="py-3 sm:py-4">
              <div className="flex items-center space-x-4 ">
                <div className="flex-1 min-w-0">
                  <p className="text-xl font-medium text-gray-900 truncate dark:text-white">
                    E Jakarta
                  </p>

                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                    Clasic
                  </p>
                  <div className="bg-gray-200 w-full rounded-lg h-4 "></div>
                </div>
                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                  200 /200
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
