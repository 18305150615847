import React, { useRef } from "react";
import { classNames } from "../../functions/atributComponent";
import { useOnClickOutside } from "../../functions/customHookReact";

function modalType(type) {
  switch (type) {
    case "lg":
      return "md:w-4/5 max-w-7xl w-11/12";
    case "md":
      return "md:w-4/5 max-w-md w-11/12";

    default:
      break;
  }
}

export default function Modal({
  show,
  onClose,
  children,
  size = "lg",
  overflow = true,
}) {
  const ref = useRef();
  useOnClickOutside(ref, () => {
    console.log("outside call");
    onClose();
  });

  return (
    <>
      {show && (
        <>
          <div className="backdrop-filter backdrop-blur-sm justify-center  flex fixed inset-0 z-50 outline-none focus:outline-none">
            <div
              className={"relative  my-6 mx-auto " + modalType(size)}
              ref={ref}
            >
              {/*content*/}
              <div
                className={classNames(
                  "border-t-8 rounded-md border-primary-500 shadow-lg relative flex flex-col w-full bg-gray-200 outline-none focus:outline-none max-h-full ",
                  overflow && "overflow-y-auto"
                )}
              >
                {/* endHeader */}
                {/* body */}
                {children}
                {/* endbody */}
              </div>
              <button
                className="absolute top-2 right-4 p-1 ml-auto bg-transparent border-0 text-black opacity-4 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => onClose()}
              >
                <span className="bg-transparent text-secondary-500 opacity-75 h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
