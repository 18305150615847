import React, { useEffect, useState } from "react";
import { classNames } from "../../general/functions/atributComponent";
import { useDispatch, useSelector } from "react-redux";
import { Login } from "./actions/loginAction";
import AlertLayout from "../../general/components/alertLibrary/view/alertLayout";
import { SetAlertBasic } from "../../general/components/alertLibrary/actions/alertBasicAction";
import { useNavigate } from "react-router-dom";
import logoCompany from "../../general/asseets/image/logo/LOGO-EKLES-Large-New.svg";

export default function LoginModul() {
  const [valid, setValid] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeLogin = useSelector((state) => state.userLogin);

  // useEffect(() => {
  //   if (localStorage.getItem("token") != null) {
  //     navigate("/");
  //   }
  // });

  useEffect(() => {
    if (storeLogin.success === true) {
      setLoading(false);
      // navigate("/");
    }
    if (storeLogin.error) {
      dispatch(SetAlertBasic("WARNING", <> {storeLogin.error} </>));
      setLoading(false);
    }
  }, [storeLogin]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setValid(true);
    const form = event.currentTarget;
    let username_tmp = form.username.value;
    let password_tmp = form.password.value;

    if (!(username_tmp?.length <= 1) && !(password_tmp?.length <= 1)) {
      console.log("Berhasil");
      // send to server to check
      setLoading(true);
      dispatch(Login({ username: username_tmp, password: password_tmp }));
    }
  };
  return (
    <div>
      <AlertLayout />
      <section className="bg-gray-100 dark:bg-gray-900">
        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a
            href="#"
            className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white"
          >
            <img src={logoCompany} className="h-8 mr-0" alt="Ekles Logo" />
            Dashboard
          </a>
          <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
                {/* Sign in to Dashboard */}
                Masuk ke Dashboard
              </h1>
              <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="username"
                    className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
                  >
                    Nama Pengguna
                  </label>
                  <input
                    type="username"
                    name="username"
                    id="username"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="username / nama pengguna"
                    required=""
                    onChange={(v) => {
                      setUsername(v.target.value.trim());
                    }}
                  />
                  <label
                    className={classNames(
                      valid && username?.length === 0 ? "block" : "hidden",
                      " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                    )}
                  >
                    *silakan masukan
                  </label>
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="block mb-2 text-sm font-medium text-left text-gray-900 dark:text-white"
                  >
                    Kata Sandi
                  </label>
                  <input
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    required=""
                    onChange={(v) => {
                      setPassword(v.target.value.trim());
                    }}
                  />
                  <label
                    className={classNames(
                      valid && password?.length === 0 ? "block" : "hidden",
                      " ml-2 mb-2 text-sm font-medium text-left text-red-500 dark:text-red-500 absolute"
                    )}
                  >
                    *silakan masukan
                  </label>
                </div>
                {/* <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input
                        id="remember"
                        aria-describedby="remember"
                        type="checkbox"
                        className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800"
                        required=""
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="remember"
                        className="text-gray-500 dark:text-gray-300"
                      >
                        Remember me
                      </label>
                    </div>
                  </div>
                  <a
                    href="#"
                    className="text-sm font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Forgot password?
                  </a>
                </div> */}
                <button
                  disabled={loading}
                  type="submit"
                  className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                >
                  {loading ? "Harap tunggu..." : "Masuk"}
                </button>
                <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                  {/* Don’t have an account yet?{" "} */}
                  Tidak punya akun?{" "}
                  <a
                    href="#"
                    className="font-medium text-primary-600 hover:underline dark:text-primary-500"
                  >
                    Hubungi Administrator
                  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
