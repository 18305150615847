import React from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import { DUMMY_DATA_LAPORAN_PENJUALAN_BULANAN } from "../../../dataDummy/Data_Laporan_Bulanan";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const HEADER_TABEL_FIX = [
  { name: "No", left: 0 },
  { name: "Item", left: 8 },
];
const HEADER_TABLE = [
  { name: "Jumlah" },
  { name: "Harga" },
  { name: "Diskon" },
  { name: "Total" },
];

export default function TableListItemPenjualan(props) {
  const { data, loading, onClickRow } = props;

  let tSesi = 0,
    tTransaksi = 0;
  return (
    <>
      <div className="bg-white rounded-md">
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full ">
              <div className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative ">
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      {HEADER_TABEL_FIX.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className={classNames(
                              false
                                ? "bg-gray-50"
                                : "text-gray-500 bg-gray-100",
                              "sticky left-" +
                                x.left +
                                " z-20 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200 select-none "
                            )}
                          >
                            {x.name}
                          </th>
                        );
                      })}

                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {data?.map((x, i) => {
                      return (
                        <tr
                          className={classNames(
                            false ? "bg-gray-50" : "bg-white"
                          )}
                        >
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-center py-2 sticky  left-0 z-10"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 ">
                              {++i}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left sticky  left-8 z-0"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-52   px-2 flex justify-between items-center">
                              <div>
                                {x?.nama} <small>(id:{x?.id})</small>{" "}
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left left-60 z-0"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-24 px-2 text-center">
                              {formatCurrency(x.jumlah)}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.harga)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.diskon)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.total)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
