import { LIST_MONTH } from "../constants/dateConstants";

const moment = require("moment");

export function getMonthById(n) {
  return LIST_MONTH.find((_n) => _n.id == n);
}

export function getMonth() {
  return LIST_MONTH?.map((x) => {
    return { id: x.id, name: x.name };
  });
}

export function getMonthFromDate(dateString) {
  // YYYY-MM-DD
  var momentObj = moment(dateString, "YYYY-MM-DD");

  return getMonthById(momentObj.format("MM"));
}

export function getListYearMonth(start, end) {
  var all_monthYear = [];
  let n = [];
  for (let i = start; i <= end; i++) {
    for (let y = 1; y <= 12; y++) {
      all_monthYear.push({ id: i + "-" + y, name: i + "-" + y });
    }
  }
  return all_monthYear;
}

export function getListYears(start, end) {
  var all_Year = [];
  for (let i = start; i <= end; i++) {
    all_Year.push({ id: i, name: i });
  }
  return all_Year;
}

// export function getMonthYear(year) {
//   var date = new Date(year + "-01-01");
//   var month = date.getFullYear();
//   date.setDate(1);
//   var month_tmp;
//   var all_days = [];
//   while (date.getFullYear() == month) {
//     month_tmp;
//     var d =
//       date.getFullYear() + "-" + date.getMonth().toString().padStart(2, "0");
//     all_days.push(d);
//     date.setDate(date.getDate() + 1);
//   }

//   return all_days;
// }
