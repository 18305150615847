import axios from "axios";
import {
  CABANG_GET_LIST_OUTLET_FAIL,
  CABANG_GET_LIST_OUTLET_REQUEST,
  CABANG_GET_LIST_OUTLET_RESET,
  CABANG_GET_LIST_OUTLET_SUCCESS,
} from "../constans/cabangConstants";
import { API_CABANG_GET_LIST_OUTLET } from "../init/api_url";
import { Logout } from "../../loginModul/actions/loginAction";

export const GetListOutletCabang = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: CABANG_GET_LIST_OUTLET_REQUEST,
    });
    const {
      userLogin: { token },
    } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const { data: data } = await axios.get(API_CABANG_GET_LIST_OUTLET, config);
    console.log("action list cabang", data);
    dispatch({
      type: CABANG_GET_LIST_OUTLET_SUCCESS,
      payload: data.listData,
    });
  } catch (error) {
    if (error.response.status == 401) {
      dispatch(Logout());
    }
    const message =
      error.response && error.response.data.message
        ? error.response.data.message
        : error.message;

    dispatch({
      type: CABANG_GET_LIST_OUTLET_FAIL,
      payload: message,
    });
  }
};

export const RGetListOutletCabang = () => async (dispatch, getState) => {
  dispatch({
    type: CABANG_GET_LIST_OUTLET_RESET,
  });
};
