import React, { useEffect, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../../general/components/modalLibrary";
import SelectFilterDynamic from "../../../../dashboardModul/views/molekuls/Select/SelectFilterDynamic";
import {
  getListYears,
  getMonth,
  getMonthById,
} from "../../../../../general/functions/dateCustome";

export default function ModalFilterTanggalPenjualan(props) {
  const { monthIdS, yearIdS, monthIdE, yearIdE, handleOnChange } = props;
  const date = new Date();
  const [show, setShow] = useState(false);
  const [monthS, setMonthS] = useState(null);
  const [yearS, setYearS] = useState(date.getFullYear());
  const [monthE, setMonthE] = useState(null);
  const [yearE, setYearE] = useState(date.getFullYear());
  const [editeMode, setEditeMode] = useState(false);
  let yearList = getListYears(2017, date.getFullYear());

  useEffect(() => {
    if (editeMode == false) {
      setMonthS(monthIdS);
      if (!isNaN(yearIdS)) {
        setYearS(parseInt(yearIdS));
      }
      setMonthE(monthIdE);
      if (!isNaN(yearIdE)) {
        setYearE(parseInt(yearIdE));
      }
      console.log("date test filter", yearIdS, monthIdS);
    }
  }, [monthIdS, yearIdS, monthIdE, yearIdE, editeMode]);

  const handleFilter = (e) => {
    e.preventDefault();
    let y = yearS,
      m = monthS,
      yE = yearE,
      mE = monthE;

    handleOnChange({ monthS: m, yearS: y, monthE: mE, yearE: yE });
    setEditeMode(false);
    setShow(false);
  };

  return (
    <>
      <div className="bg-gray-600  right-0 top-0  rounded-lg flex items-center gap-2">
        <button
          onClick={(e) => {
            e.preventDefault();
            setEditeMode(true);
            setShow(true);
          }}
          type="button"
          className="bg-gray-800 hover:bg-gray-700 outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none  text-gray-100 hover:text-gray-800 rounded-bl-lg"
        >
          <FunnelIcon className="h-6 w-6 text-gray-200" />
        </button>
        <p className="text-gray-200">
          {monthIdS != null ? getMonthById(monthIdS).name + "," : ""} {yearS} -{" "}
          {monthIdE != null ? getMonthById(monthIdE).name + "," : ""} {yearE}
        </p>
      </div>

      <Modal
        show={show}
        overflow={false}
        onClose={() => {
          setShow(false);
        }}
        size="md"
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700  top-0 p-4  ">
            Filter
          </h5>{" "}
        </div>
        <div className="p-4 bg-white border-t">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div>Bulan</div>
              <div className="">
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto "
                  dataSelectList={getMonth().filter((x) => x.id != "00")}
                  placeholder="Month"
                  valueOption={monthS}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setMonthS("00");
                    } else {
                      setMonthS(v.id);
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Tahun</div>
              <div>
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
                  dataSelectList={yearList}
                  placeholder="Year"
                  valueOption={yearS}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setYearS(date.getFullYear());
                    } else {
                      setYearS(v.id);
                    }
                  }}
                />
              </div>
            </div>
            <div className="text-center relative my-2">
              <div>
                <hr />
              </div>
              <div className="absolute -top-3 left-0 right-0 ">
                <label className=" text-center font-medium bg-white px-2 ">
                  {" "}
                  Sampai{" "}
                </label>
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Bulan</div>
              <div className="">
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto "
                  dataSelectList={getMonth()
                    .filter((x) => x.id != "00")
                    .filter((x) =>
                      yearS == yearE ? parseInt(x.id) >= monthS : true
                    )}
                  placeholder="Month"
                  valueOption={monthE}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setMonthE("00");
                    } else {
                      setMonthE(v.id);
                    }
                  }}
                />
              </div>
            </div>

            <div className="flex justify-between items-center">
              <div>Tahun</div>
              <div>
                <SelectFilterDynamic
                  className="   focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border-gray-300 rounded-md sm:w-auto"
                  dataSelectList={yearList.filter(
                    (x) => parseInt(x.name) >= yearS
                  )}
                  placeholder="Year"
                  valueOption={yearE}
                  valueGetSingle={(v) => {
                    if (v == null) {
                      setYearE(date.getFullYear());
                    } else {
                      setYearE(v.id);
                    }
                  }}
                />
              </div>
            </div>
            {/* footer */}
            <div className="flex justify-end  gap-2">
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-primary-600 bg-gray-200 hover:bg-primary-300 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Tutup
                </button>
              </div>
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleFilter}
                >
                  Filter
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
