import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetPenjualanAllCabang,
  GetPenjualanAllCabang,
} from "../../actions/PenjualanAllCabangActions";
import TableFixHeaderPenjualanAllCabang from "../molekuls/table/TableFixHeaderPenjualanAllCabang";
import { filterbyArray } from "../../../../general/functions/checkingInput";
import {
  MagnifyingGlassIcon,
  DocumentArrowDownIcon,
} from "@heroicons/react/24/outline";
import Select from "react-select";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { ConfigInvContext } from "../../context/configContext";
import { useNavigate, useParams } from "react-router-dom";
import ModalFilterTanggalPenjualan from "../molekuls/modal/ModalFilterTanggalPenjualan";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import { useHandleListBreadcrumb } from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";

export default function PagePenjualanAllCabang() {
  const date = new Date();
  const [loading, setLoading] = useState(true);
  const [listData, setlistData] = useState();
  const [listFilter, setListFilter] = useState([]);

  //
  const [monthS, setMonthS] = useState(null);
  const [yearS, setYearS] = useState(null);
  const [monthE, setMonthE] = useState(null);
  const [yearE, setYearE] = useState(null);
  //
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const storeGetData = useSelector(
    (state) => state.penjualanModul_get_all_cabang
  );
  let { dateParam } = useParams();

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      setListBreadcrumb([
        {
          name: "Penjualan",
          link: "/penjualan/" + yearS + "" + monthS + "-" + yearE + "" + monthE,
        },
      ]);
    }
  }, [loading]);

  useEffect(() => {
    if (dateParam != null && dateParam != undefined) {
      let y = null;
      let m = null;
      let yE = null;
      let mE = null;
      let nDate;
      if (dateParam.length == 13) {
        nDate = dateParam.split("-");
      }
      if (dateParam.length == 13 && !isNaN(nDate[0]) && !isNaN(nDate[1])) {
        y = nDate[0].slice(0, 4);
        m = nDate[0].slice(4, 6);
        setYearS(y);
        setMonthS(m);

        yE = nDate[1].slice(0, 4);
        mE = nDate[1].slice(4, 6);
        setYearE(yE);
        setMonthE(mE);
        console.log("dateParam Set value");
      }
    } else {
      // setMonthS("01");
      // setYearS(date.getFullYear());
      // setYearE(date.getFullYear());
      // setMonthE("12");
      navigate(
        "/penjualan/" +
          date.getFullYear() +
          "" +
          "01" +
          "-" +
          date.getFullYear() +
          "" +
          "12"
      );
    }
  }, [dateParam]);

  useEffect(() => {
    setLoading(true);
    console.log("triger update");
    if (yearS != null && monthS != null && yearE != null && monthE != null) {
      let firstDay = new Date(yearS, monthS, 1);
      let lastDay = new Date(yearE, parseInt(monthE), 0);
      let dateStart =
        yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
      let dateEnd =
        yearE + "-" + monthE + "-" + ("0" + lastDay.getDate()).slice(-2);
      dispatch(
        GetPenjualanAllCabang({ dateStart: dateStart, dateEnd: dateEnd })
      );
    }
  }, [yearS, monthS, yearE, monthE]);

  useEffect(() => {
    console.log("dataPure", storeGetData);
    if (storeGetData?.success) {
      setlistData(
        storeGetData.data.listData?.map((x) => {
          return {
            nama: x.namaOutlet,
            id: x.idOutlet,
            tipeOutlet: x.kategori,
            countTransaksi: x.countTransaksi,
            grandTotal: x?.grandTotal,
          };
        })
      );

      setLoading(false);
    }
    if (storeGetData?.error) {
      setLoading(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.yearS;
    let m = v.monthS;
    let n = "-";
    let yE = v.yearE;
    let mE = v.monthE;
    if (m == null) {
      m = "01";
    }
    if (mE == null) {
      mE = "01";
    }
    if (yE == null) {
      m = y;
    }
    console.log("/penjualan/" + y + "" + m + n + yE + "" + mE);
    navigate("/penjualan/" + y + "" + m + n + yE + "" + mE);
  };
  const handleOpenDetailCabang = (v) => {
    navigate(
      "/penjualan/" +
        yearS +
        "" +
        monthS +
        "-" +
        yearE +
        "" +
        monthE +
        "/" +
        v.id
    );
  };
  const handleDowload = () => {
    let firstDay = new Date(yearS, monthS, 1);
    let lastDay = new Date(yearE, parseInt(monthE), 0);
    let dateStart =
      yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
    let dateEnd =
      yearE + "-" + monthE + "-" + ("0" + lastDay.getDate()).slice(-2);
    dispatch(
      DownloadGetPenjualanAllCabang({ dateStart: dateStart, dateEnd: dateEnd })
    );
  };
  return (
    <div className="">
      <HeaderPage title="Penjualan" />
      <div className="pt-2">
        <ModalFilterTanggalPenjualan
          monthIdS={monthS}
          yearIdS={yearS}
          monthIdE={monthE}
          yearIdE={yearE}
          handleOnChange={handleChangeDate}
        />
      </div>
      <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
        <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
          <div className="order-first">
            <DownloadButton downloadAction={() => handleDowload()} />
          </div>
          <div className="flex gap-2  items-center flex-row z-30">
            <div className="w-auto max-w-fit  bg-red-50">
              <Select
                //   defaultValue={[]}
                isMulti
                name="colors"
                options={listData?.map((x) => {
                  return { value: x.nama, label: x.nama, id: x.id };
                })}
                onChange={(e) => {
                  console.log(e);
                  setListFilter(e);
                }}
                className="react-select-container "
                classNamePrefix="select"
                placeholder="Pilih Cabang Tertentu"
              />
            </div>
            <button
              type="button"
              className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <MagnifyingGlassIcon className="h-6 w-6 text-gray-200" />
            </button>
          </div>
        </div>
        {loading ? (
          <SimpleLoading />
        ) : (
          <TableFixHeaderPenjualanAllCabang
            data={
              listFilter != null && listFilter.length != 0
                ? filterbyArray(listData, listFilter)
                : listData
            }
            onClickRow={handleOpenDetailCabang}
          />
        )}
      </div>
    </div>
  );
}
