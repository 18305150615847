import axios from "axios";
import {
  INVENTORY_CABANG_GET_FAIL,
  INVENTORY_CABANG_GET_REQUEST,
  INVENTORY_CABANG_GET_RESET,
  INVENTORY_CABANG_GET_SUCCESS,
} from "../constants/InventoryCabangConstants";
import {
  API_INVENTORY_CABANG_GET,
  API_INVENTORY_CABANG_GET_DOWNLOAD,
} from "../init/api_url";
import { Logout } from "../../loginModul/actions/loginAction";
import { ActGeneralButtonDownloadSuccess } from "../../../general/components/button/ButtonDownloadsrc/action";

export const DownloadGetInventoryCabang =
  ({ minStok, outlet, namaOutlet }) =>
  async (dispatch, getState) => {
    let fileName = "Inventory " + namaOutlet + ".xlsx";
    const {
      userLogin: { token },
    } = getState();
    const data = await axios({
      headers: {
        Authorization: `Bearer ${token}`,
      },
      url: API_INVENTORY_CABANG_GET_DOWNLOAD,
      method: "GET",
      responseType: "blob", // important
      params: {
        minStok: minStok,
        outlet: outlet,
      },
    });
    console.log("Download Data", data["Content-Disposition"], data);
    const url = window.URL.createObjectURL(new Blob([data.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    dispatch(ActGeneralButtonDownloadSuccess());
  };

export const GetInventoryCabang =
  ({ minStok, outlet }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: INVENTORY_CABANG_GET_REQUEST,
      });
      const {
        userLogin: { token },
      } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: {
          minStok: minStok,
          outlet: outlet,
        },
      };
      const { data: data } = await axios.get(API_INVENTORY_CABANG_GET, config);
      console.log("testing Action get inventory  cabang", data);
      dispatch({
        type: INVENTORY_CABANG_GET_SUCCESS,
        payload: data,
      });
    } catch (error) {
      if (error.response.status == 401) {
        dispatch(Logout());
      }
      const message =
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message;

      dispatch({
        type: INVENTORY_CABANG_GET_FAIL,
        payload: message,
      });
    }
  };

export const RGetInventoryCabang = () => async (dispatch, getState) => {
  dispatch({
    type: INVENTORY_CABANG_GET_RESET,
  });
};
