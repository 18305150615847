import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import LoginModul from "./moduls/loginModul";
import DashboardModul from "./moduls/dashboardModul";
import TestDashboard from "./general/layout/testDashboard";
import LaporanAkhirModul from "./moduls/laporanAkhirModul";
import AuthValidasi from "./moduls/loginModul/views/AuthValidasi";
import { useSelector } from "react-redux";
import TestLayout2 from "./general/layout/testLayout2";
import MainLayout from "./general/layout/MainLayout";
import InventoryModul from "./moduls/inventoryModul";
import MemberModul from "./moduls/memberModul";
import TopSpenderModul from "./moduls/topSpenderModul";
import PenjualanModul from "./moduls/penjualanModul";
import KomisiModul from "./moduls/komisiModul";
import LabaRugiModul from "./moduls/labaRugiModul";
import Page404 from "./general/page/Page404";
import PageWelcome from "./general/page/PageWelcome";
import PageChangePass from "./moduls/loginModul/views/PageChangePass";

function App() {
  const userLogin = useSelector((state) => state.userLogin);
  const { token } = userLogin;
  console.log = console.warn = console.error = () => {};
  document.documentElement.classList.remove("dark");
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<AuthValidasi />}>
            <Route element={<MainLayout />}>
              <Route path="/" element={<PageWelcome />} />
              <Route path="/reset-pass" element={<PageChangePass />} />
              {/* <Route path="/" element={<DashboardModul />} /> */}

              <Route
                path="/inventory/detail/:outlet"
                element={<InventoryModul />}
              />
              <Route path="/inventory" element={<InventoryModul />} />
              <Route path="/member" element={<MemberModul />} />
              <Route
                path="/top-spander/:dateParam?/:outlet?"
                element={<TopSpenderModul />}
              />
              <Route
                path="/top-spander/:dateParam?"
                element={<TopSpenderModul />}
              />
              <Route
                path="/penjualan/:dateParam?/:outlet?"
                element={<PenjualanModul />}
              />
              <Route
                path="/penjualan/:dateParam?"
                element={<PenjualanModul />}
              />

              <Route
                path="/komisi/:dateParam?/:outlet?"
                element={<KomisiModul />}
              />
              <Route path="/komisi/:dateParam?" element={<KomisiModul />} />
              <Route path="/laba-rugi" element={<LabaRugiModul />} />
              <Route
                path="/laba-rugi/:dateReportParam?/:idOutletParam?"
                element={<LabaRugiModul />}
              />
              {/* idCabang kalau bisa encode decode, param get  */}
              <Route
                path="/laporan-akhir-outlet/detail/:cabangId"
                element={<LaporanAkhirModul />}
              />
              <Route
                path="/laporan-akhir-outlet/:dateReportParam?/:idOutletParam?"
                element={<LaporanAkhirModul />}
              />

              <Route path="/laporan" element={<LaporanAkhirModul />} />
              <Route path="/test" element={<TestDashboard />} />
              <Route path="/test2" element={<DashboardModul />} />
              <Route path="/" element={<DashboardModul />} />
            </Route>
            <Route path="/404" element={<Page404 />} />
          </Route>
          <Route path="/404" element={<Page404 />} />
          <Route
            path="/login"
            element={!!token ? <Navigate to="/" /> : <LoginModul />}
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
