import {
  LAPORAN_AKHIR_GET_FAIL,
  LAPORAN_AKHIR_GET_REQUEST,
  LAPORAN_AKHIR_GET_RESET,
  LAPORAN_AKHIR_GET_SUCCESS,
  LAPORAN_AKHIR_GENERATE_FAIL,
  LAPORAN_AKHIR_GENERATE_REQUEST,
  LAPORAN_AKHIR_GENERATE_RESET,
  LAPORAN_AKHIR_GENERATE_SUCCESS,
} from "../constants/laporanAkhirConstants";

export const laporanakhirmodul_get_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case LAPORAN_AKHIR_GET_REQUEST:
      return { loading: true };
    case LAPORAN_AKHIR_GET_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LAPORAN_AKHIR_GET_FAIL:
      return { loading: false, error: action.payload };
    case LAPORAN_AKHIR_GET_RESET:
      return {};
    default:
      return state;
  }
};

export const laporanakhirmodul_genereate_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case LAPORAN_AKHIR_GENERATE_REQUEST:
      return { loading: true };
    case LAPORAN_AKHIR_GENERATE_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LAPORAN_AKHIR_GENERATE_FAIL:
      return { loading: false, error: action.payload };
    case LAPORAN_AKHIR_GENERATE_RESET:
      return {};
    default:
      return state;
  }
};
