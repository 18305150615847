import React, { useEffect, useState } from "react";
import { Cog6ToothIcon } from "@heroicons/react/24/outline";
import Modal from "../../../../../general/components/modalLibrary";
import SelectFilterDynamic from "../../../../dashboardModul/views/molekuls/Select/SelectFilterDynamic";

export default function ModalConfigInventory(props) {
  const { minStok, handleOnChange } = props;
  const [tempMinStok, setTempMinStok] = useState(minStok);
  const [show, setShow] = useState(false);

  const handleSave = (e) => {
    e.preventDefault();
    if (tempMinStok >= 1) {
      handleOnChange({ minStok: tempMinStok });
    } else {
      handleOnChange({ minStok: 0 });
    }
    setShow(false);
  };

  return (
    <>
      <div className="bg-gray-600 right-0 top-0  rounded-lg flex items-center gap-2">
        <button
          onClick={(e) => {
            e.preventDefault();
            setTempMinStok(minStok);
            setShow(true);
          }}
          type="button"
          className="bg-gray-800 hover:bg-gray-700 outline-none focus:ring-blue-300 font-medium text-sm px-5 py-2.5  dark:bg-gray-600 dark:hover:bg-gray-700 focus:outline-none  text-gray-100 hover:text-gray-800 rounded-bl-lg"
        >
          <Cog6ToothIcon className="h-6 w-6 text-gray-200" />
        </button>
        <p className="text-gray-200 text-sm">
          {"Menampilkan Stok Item dibawah " + minStok}
        </p>
      </div>

      <Modal
        show={show}
        overflow={false}
        onClose={() => {
          setShow(false);
        }}
        size="md"
      >
        <div className="bg-white w-full sticky top-0">
          <h5 className="text-lg font-bold  dark:text-white text-gray-700  top-0 p-4  ">
            Configuration
          </h5>{" "}
        </div>
        <div className="p-4 bg-white border-t">
          <div className="flex flex-col gap-4">
            <div className="flex justify-between items-center">
              <div>Minimum Stok Item</div>
              <div className="">
                <input
                  className="h-8 px-2 ring-primary-500 focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border border-gray-300 rounded-md sm:w-auto  bg-white"
                  type="text"
                  min="0"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setTempMinStok(e.target.value);
                  }}
                  value={tempMinStok}
                />
              </div>
            </div>

            {/* footer */}
            <div className="flex justify-end  gap-2">
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-primary-600 bg-gray-200 hover:bg-primary-300 focus:ring-4 focus:outline-none  font-medium rounded-lg text-sm px-5 py-2.5 text-center "
                  onClick={(e) => {
                    e.preventDefault();
                    setShow(false);
                  }}
                >
                  Tutup
                </button>
              </div>
              <div>
                {" "}
                <button
                  disabled={false}
                  type="button"
                  className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
                  onClick={handleSave}
                >
                  Simpan
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
