import React, { useMemo, useState } from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { miniSerializeError } from "@reduxjs/toolkit";
import Pagination from "../../../../../general/components/Pagination/Pagination";

const HEADER_TABLE = [
  // { name: "Id" },
  { name: "Nomor Nota" },
  { name: "Tanggal" },
  { name: "Member" },
  { name: "status" },
  { name: "type pem." },

  { name: "Terbayar" },
];
let PageSize = 10;
export default function TabelPenjualanCabang(props) {
  const { data = [], minStok, title = "", handleOpenData } = props;
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return data
      ?.filter((x) => {
        if (search == "") {
          return true;
        } else {
          return (
            x.namamember.toLowerCase().includes(search.toLowerCase()) ||
            x.nonota.toLowerCase().includes(search.toLowerCase())
          );
        }
      })
      ?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, search, data]);

  let tMember = 0;
  let tTransaksi = 0;
  return (
    <div>
      <div className="flex justify-between ml-2">
        <h1 className="text-xl font-bold text-gray-700 pb-2 ">{title}</h1>
        <div>
          <input
            type="text"
            name="first-name"
            id="first-name"
            placeholder="Cari"
            className=" h-8 px-2 ring-primary-500 focus:ring-primary-500 focus:border-primary-500 block md:w-60 w-full  shadow-sm sm:text-sm border border-gray-300 rounded-md sm:w-auto  bg-white"
            onChange={(e) => {
              setCurrentPage(1);
              setSearch(e.target.value);
            }}
          />
        </div>
      </div>
      <div
        style={{ minHeight: "20rem" }}
        className="bg-white rounded-md border-2 pb-2"
      >
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full">
              <div
                className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative"
                style={{ maxHeight: "50vh" }}
              >
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100 ">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                      >
                        No.
                      </th>
                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {currentTableData?.map((x, i) => {
                      tMember++;
                      tTransaksi += parseInt(x.grandtotal);
                      return (
                        <tr
                          className={classNames(
                            false ? "bg-gray-50" : "bg-white"
                          )}
                          onClick={() => {
                            handleOpenData(x);
                            console.log("click penjualan detail", x);
                          }}
                        >
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-center py-2 "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 ">
                              {x.no}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500    px-2 flex flex-col justify-between items-start">
                              <div>{x?.nonota}</div>
                              <div className="text-gray-400">
                                {"Staff: " + x.idstaff + " | " + x.namastaff}
                              </div>
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                              {x.tanggal}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left w-60 px-2 "
                            )}
                          >
                            <div className="text-sm text-gray-400">
                              {"Id: " + x.idmember}
                            </div>
                            <div className="text-sm font-medium text-gray-500  ">
                              {x.namamember}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                              {x.statuspiutang}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                              {x.tipe}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(
                                parseInt(x.tunai) - parseInt(x.kembalian)
                              )}
                            </div>
                            {formatCurrency(x.diskon) != 0 && (
                              <div className="text-xs font-medium text-gray-400  px-2 ">
                                {"Diskon: " + formatCurrency(x.diskon)}
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Pagination
        className="pagination-bar"
        currentPage={currentPage}
        totalCount={
          data?.filter((x) => {
            if (search == "") {
              return true;
            } else {
              return x.namamember.toLowerCase().includes(search.toLowerCase());
            }
          })?.length
        }
        pageSize={PageSize}
        onPageChange={(page) => setCurrentPage(page)}
      />
      <div className="grid grid-cols-2">
        <div className="bg-white p-4 rounded-md divide-y divide-gray-500">
          <h1 className="text-xl font-bold text-gray-700 pb-2">Total</h1>
          <dl className="max-w-md text-gray-900  dark:text-white grid grid-cols-2 pt-2">
            <div className="flex flex-col">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Penjualan
              </dt>
              <dd className="text-lg font-semibold">
                {
                  data?.filter((x) => {
                    if (search == "") {
                      return true;
                    } else {
                      return x.namamember
                        .toLowerCase()
                        .includes(search.toLowerCase());
                    }
                  })?.length
                }
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Transaksi
              </dt>
              <dd className="text-lg font-semibold">
                {" "}
                {formatCurrency(
                  data?.reduce((accumulator, object) => {
                    return (
                      accumulator +
                      parseInt(object.tunai) -
                      parseInt(object.kembalian)
                    );
                  }, 0)
                )}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  );
}
