import React from "react";

export default function ChartKeuanganLabel() {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-4">
      {/*  */}
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 flex items-center justify-center h-24  dark:bg-gray-800  border-l-8 border-green-500">
        <div className="w-full">
          <h4 className="text-2xl font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <div className="flex justify-between items-center">
            <h5 className="text-xl font-noemal dark:text-white  text-gray-700">
              Pemasukan
            </h5>
            {/* <div>icon</div> */}
          </div>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 flex items-center justify-center h-24  dark:bg-gray-800  border-l-8 border-red-500">
        <div className="w-full">
          <h4 className="text-2xl font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-xl font-noemal dark:text-white  text-gray-700">
            Pengeluaran
          </h5>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 flex items-center justify-center h-24  dark:bg-gray-800  border-l-8 border-yellow-500">
        <div className="w-full">
          <h4 className="text-2xl font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-xl font-noemal dark:text-white  text-gray-700">
            Piutang
          </h5>
          <h6 className="text-sm font-noemal dark:text-white  text-gray-700">
            dari 0.000.000.000
          </h6>
        </div>
      </div>
      {/*  */}
      {/*  */}
      <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 flex items-center justify-center h-24  dark:bg-gray-800  border-l-8 border-blue-500">
        <div className="w-full">
          <h4 className="text-2xl font-bold dark:text-white  mt-1">
            {" "}
            0.000.000.000
          </h4>
          <h5 className="text-xl font-noemal dark:text-white  text-gray-700">
            Omset
          </h5>
        </div>
      </div>
      {/*  */}
    </div>
  );
}
