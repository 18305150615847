import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);
export default function LineChart({
  labelData = [],
  valueData = [],
  title = "",
}) {
  const optionsVal = () => {
    let obj = {
      responsive: true,
      plugins: {
        legend: {
          display: false,
          position: "top",
        },
        title: {
          display: false,
          text: title,
        },
      },
    };
    obj.maintainAspectRatio = false;

    // obj.plugins.legend = {
    //   display: true,
    //   align: "center",
    //   position: "top",
    //   labels: {
    //     // This more specific font property overrides the global property
    //     font: {
    //       size: 20,
    //     },
    //   },
    // };
    return obj;
  };

  const labels = labelData;

  const data = {
    labels,
    datasets: [
      {
        label: " Total Participant",
        // borderWidth: 4,
        data: valueData,

        borderColor: "rgba(229, 136, 132, 1)",
        backgroundColor: "rgba(255, 99, 132, 0.8)",
      },
    ],
  };

  return <Line options={optionsVal()} data={data} />;
}
