import {
  BUTTON_DOWNLOAD_FAIL,
  BUTTON_DOWNLOAD_REQUEST,
  BUTTON_DOWNLOAD_RESET,
  BUTTON_DOWNLOAD_SUCCESS,
} from "./constant";

export const general_button_download_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case BUTTON_DOWNLOAD_REQUEST:
      return { loading: true };
    case BUTTON_DOWNLOAD_SUCCESS:
      return { loading: false, success: true };
    case BUTTON_DOWNLOAD_FAIL:
      return { loading: false, error: true };
    case BUTTON_DOWNLOAD_RESET:
      return {};
    default:
      return state;
  }
};
