import React, { useEffect, useState } from "react";
import { useHandleFirstPageName } from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import HeaderPage from "../../general/components/text/HeaderPage";
import DateBasic from "../../general/components/Datepicker/DateBasic";
import PageTopSpanderDetailCabang from "./view/parts/PageTopSpanderDetailCabang";
import PageTopSpanderAllCabang from "./view/parts/PageTopSpanderAllCabang";
import { ConfigInvContext } from "./context/configContext";
import { useParams } from "react-router-dom";

export default function TopSpenderModul() {
  const [paramCon, setParamCon] = useState("");
  let { dateParam } = useParams(null);
  useEffect(() => {
    if (dateParam != null && dateParam != undefined) {
      setParamCon(dateParam);
    }
  }, [dateParam]);
  useHandleFirstPageName("Top Spender", "/top-spander/" + dateParam);
  const valueConfing = {
    configInv: { startDate: null, endDate: null },
    setConfigInv: () => {},
  };

  return (
    <div>
      <ConfigInvContext.Provider value={valueConfing}>
        <View />
      </ConfigInvContext.Provider>
    </div>
  );
}

const View = () => {
  let { outlet } = useParams();
  let n = 0;
  if (outlet != null) {
    n = 1;
  }
  switch (n) {
    case 1:
      return <PageTopSpanderDetailCabang outlet={outlet} />;

    default:
      return <PageTopSpanderAllCabang />;
  }
};
