import React from "react";
import { LIST_DATES, dummyValuebyDates } from "../../dummyData/dummyFunction";
import MultipleLineChart from "../molekuls/Chart/MultipleLineChart";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import ChartItemTerjual from "./DPartItemTerjual-F/ChartItemTerjual";
import ModalDetailItemTerjual from "./DPartItemTerjual-F/ModalDetailItemTerjual";
export default function DPartItemTerjual() {
  return (
    <div className="bg-white shadow rounded-lg p-4 sm:p-6 xl:p-8 h-auto relative">
      <div className="mb-4">
        <h5 className="text-xl -mb-1 font-semibold dark:text-white text-gray-700">
          <label className="text-3xl ">1500</label> ITEM TERJUAL
        </h5>
        <label className="text-sm font-normal">
          Produk 200, Non Medis 300, Treatment 10000{" "}
        </label>
      </div>
      <ModalDetailItemTerjual />
      <ChartItemTerjual
        valProduk={dummyValuebyDates(0, 500)}
        valTreatmentNonMedis={dummyValuebyDates(0, 500)}
        valTreatment={dummyValuebyDates(0, 500)}
        labelData={LIST_DATES()}
      />
    </div>
  );
}
