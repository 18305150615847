import React from "react";
import { useHandleFirstPageName } from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import HeaderPage from "../../general/components/text/HeaderPage";

export default function MemberModul() {
  useHandleFirstPageName("Member", "#");

  return (
    <div>
      <HeaderPage title="Member" />
    </div>
  );
}
