import {
  CheckIcon,
  ExclamationCircleIcon,
  ExclamationTriangleIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import React, { useEffect } from "react";

// type DANGER, SUCCESS, WARNING, INFO
const ALERT_TYPE = [
  { name: "DANGER", color: "bg-red-500", icon: ExclamationCircleIcon },
  { name: "SUCCESS", color: "bg-green-500", icon: CheckIcon },
  { name: "WARNING", color: "bg-yellow-500", icon: ExclamationTriangleIcon },
  { name: "INFO", color: "bg-secondary-500", icon: InformationCircleIcon },
];
function checkType(obj) {
  return ALERT_TYPE.find((x) => x.name == obj);
}
export const AlertBasic = ({ type, mes }) => {
  const [showAlert, setShowAlert] = React.useState(true);
  const [closeAlert, setCloseAlert] = React.useState(false);
  const Icon = checkType(type).icon;
  useEffect(() => {
    setTimeout(function () {
      setCloseAlert(true);
    }, 5000);
  });
  useEffect(() => {
    if (closeAlert == true) {
      setTimeout(function () {
        setShowAlert(false);
      }, 500);
    }
  }, [closeAlert]);
  return (
    <>
      {showAlert ? (
        <div className="w-full transition-all duration-75 ease-in-out shadow-lg ">
          <div
            className={
              "text-white px-6 py-2 border-0 rounded relative  transition-all duration-75  " +
              checkType(type).color +
              " " +
              (closeAlert ? " animate-fadeO" : " animate-fadeIn")
            }
          >
            <div className="flex items-center">
              <span className="text-xl inline-block mr-3 align-middle">
                <Icon className="h-7 w-7 text-white " />
              </span>

              <span className="inline-block align-middle mr-8 ">
                <b className="capitalize">{type.toLowerCase()}!</b> {mes}
              </span>
            </div>
            <button
              className="absolute bg-transparent text-2xl font-semibold leading-none right-0 top-0 mt-2 mr-4 outline-none focus:outline-none"
              onClick={() => setShowAlert(false)}
            >
              <span>×</span>
            </button>
          </div>
        </div>
      ) : null}
    </>
  );
};
