import React, { Component } from "react";
import AsyncSelect from "react-select/async";

import { connect } from "react-redux";

export class SelectFilterDynamic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSelectList: this.props.dataSelectList,
      valueOption: null,
      inputValue: "",
    };
  }
  componentDidMount() {
    this.setState({
      valueOption: this.props.valueOption,
      dataSelectList: this.props.dataSelectList,
    });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.valueOption !== prevProps.valueOption) {
      if (this.props.valueOption !== undefined) {
        this.setState({
          valueOption: this.props.valueOption,
        });
      }
    }
    if (this.props.dataSelectList !== prevProps.dataSelectList) {
      if (this.props.valueOption !== undefined) {
        this.setState({
          dataSelectList: this.props.dataSelectList,
        });
      }
    }
    if (this.props.isDisabled !== prevProps.isDisabled) {
      if (this.props.isDisabled !== undefined) {
        this.setState((state) => ({
          isDisabled: this.props.isDisabled,
        }));
      }
    }
  }

  filterColors = (inputValue) => {
    let bab;
    bab = this.state.dataSelectList?.filter((i) =>
      i.name?.toLowerCase().includes(inputValue.toLowerCase())
    );
    return bab;
  };

  promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterColors(inputValue));
      }, 1000);
    });

  valueGetSend = (v) => {
    this.props.valueGetSingle(v);
  };

  render() {
    return (
      <AsyncSelect
        cacheOptions
        onChange={this.valueGetSend}
        value={this.state.dataSelectList?.filter((option) => {
          return option.id === this.state.valueOption;
        })}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        defaultOptions={this.state.dataSelectList}
        isDisabled={this.props?.isDisabled}
        isClearable
        loadOptions={this.promiseOptions}
        placeholder={
          this.props.placeholder ? this.props.placeholder : "Select..."
        }
        className={this.props?.className}
        menuPlacement="auto"
        //  isMulti
      />
    );
  }
}

const mapStateToProps = (state) => {
  return {
    // get_profinsi: state.addressmodul_get_profinsi,
  };
};

export default connect(mapStateToProps, {})(SelectFilterDynamic);
