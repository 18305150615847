import React from "react";
import MultipleLineChart from "../../molekuls/Chart/MultipleLineChart";

export default function ChartKeuangan({
  labelData,
  valOmsetList,
  valPemasukanList,
  valListPengeluaran,
  valListPiutang,
  darkMode = false,
}) {
  return (
    <div className="h-64">
      <MultipleLineChart
        darkMode={darkMode}
        labelData={labelData}
        datasets={[
          {
            label: " Omset",
            data: valOmsetList,
            borderWidth: 2,
            borderColor: "rgba(59, 130, 246, 0.8)",
            backgroundColor: "rgba(59, 130, 246, 0.8)",
          },
          {
            label: " Pemasukan",
            data: valPemasukanList,
            borderWidth: 2,
            borderColor: "rgba(34, 197, 94, 1)",
            backgroundColor: "rgba(94, 197, 94, 0.8)",
          },
          {
            label: " Pengeluaran",
            data: valListPengeluaran,
            borderWidth: 2,
            borderColor: "rgba(239, 68, 68, 1)",
            backgroundColor: "rgba(239, 68, 68, 0.8)",
          },
          {
            label: " Piutang",
            data: valListPiutang,
            borderWidth: 2,
            borderColor: "rgba(234, 179, 8, 1)",
            backgroundColor: "rgba(234, 179, 8, 0.8)",
          },
        ]}
        title="Keuangan"
      />
    </div>
  );
}
