import React from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import { DUMMY_DATA_LAPORAN_PENJUALAN_BULANAN } from "../../../dataDummy/Data_Laporan_Bulanan";
import { formatCurrency } from "../../../../../general/functions/NumberFormat";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
const HEADER_TABEL_FIX = [
  { name: "No", left: 0 },
  { name: "Nama Outlet", left: 8 },
  { name: "Tipe Outlet", left: 60 },
];

const HEADER_TABLE = [{ name: "Jumlah Transaksi" }, { name: "Total Uang" }];
// type: LELVEL_1 (namaoutlet, type), Level_2(tgl)
export default function TableFixHeaderPenjualanAllCabang(props) {
  const {
    data,
    handleViewDetail,
    type = "LEVEL_1",
    loading,
    onClickRow,
  } = props;

  let tSesi = 0,
    tTransaksi = 0;
  return (
    <>
      <div style={{ minHeight: "20rem" }} className="bg-white rounded-md">
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full ">
              <div
                className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative "
                style={{ maxHeight: "50vh" }}
              >
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      {HEADER_TABEL_FIX.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className={classNames(
                              false
                                ? "bg-gray-50"
                                : "text-gray-500 bg-gray-100",
                              "sticky left-" +
                                x.left +
                                " z-20 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200 select-none "
                            )}
                          >
                            {x.name}
                          </th>
                        );
                      })}

                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {data?.map((x, i) => {
                      tTransaksi += parseInt(x.grandTotal);
                      tSesi += parseInt(x.countTransaksi);
                      return (
                        <tr
                          className={classNames(
                            false ? "bg-gray-50" : "bg-white"
                          )}
                        >
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-center py-2 sticky  left-0 z-10"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 ">
                              {++i}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left sticky  left-8 z-0"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-52   px-2 flex justify-between items-center">
                              <div>{x?.nama}</div>
                              <button
                                onClick={(e) => {
                                  e.preventDefault();
                                  onClickRow({ id: x.id });
                                }}
                              >
                                <MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
                              </button>
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left sticky left-60 z-0"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                              {x.tipeOutlet}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.countTransaksi)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.grandTotal)}
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2">
        <div className="bg-white p-4 rounded-md divide-y divide-gray-500">
          <h1 className="text-xl font-bold text-gray-700 pb-2">Total</h1>
          <dl className="max-w-md text-gray-900  dark:text-white grid grid-cols-2 pt-2">
            <div className="flex flex-col">
              <dt className="mb-1 text-gray-500 md:text-lg dark:text-gray-400">
                Transaksi
              </dt>
              <dd className="text-lg font-semibold">
                {" "}
                {formatCurrency(tSesi)} / Rp.
                {formatCurrency(tTransaksi)}
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </>
  );
}
