import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { classNames } from "../../general/functions/atributComponent";
import TableFixHeaderPenjualan from "./view/molekuls/table/TableFixHeaderPenjualan";
import { getListYearMonth } from "../../general/functions/dateCustome";
import SelectFilterDynamic from "../dashboardModul/views/molekuls/Select/SelectFilterDynamic";
import NavBar1 from "../../general/components/NavbarLibrary";
import PageGeneralBulanan from "./view/parts/PageGeneralBulanan";
import { BreadcrumbListContext } from "../../general/components/BreadcrumbLibrary/context";
import {
  useHandleAddPageName,
  useHandleFirstPageName,
} from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";

export default function LaporanAkhirModul() {
  const { listBreadcrumb, setListBreadcrumb } = useContext(
    BreadcrumbListContext
  );
  useHandleFirstPageName("Laporan Akhir Outlet", "#");
  // const pushBreanchcrumb = useHandleAddPageName;

  const handleAddPageName = (name, link) => {
    // pushBreanchcrumb(name, link);
  };

  return (
    <>
      <PageGeneralBulanan />
    </>
  );
}
