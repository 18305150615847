import {
  labarugimodul_generate_reducers,
  labarugimodul_get_reducers,
} from "../reducers/labaRugiReducers";

const modulLabaRugi = {
  labarugimodul_get: labarugimodul_get_reducers,
  labarugimodul_generate: labarugimodul_generate_reducers,
};

export default modulLabaRugi;
