import React, { useContext, useEffect, useState } from "react";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { ConfigInvContext } from "../../context/configContext";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetInventoryCabang,
  GetInventoryCabang,
  RGetInventoryCabang,
} from "../../actions/InventoryCabangActions";
import TabelStokInventoryCabang from "../molekuls/table/TabelStokInventoryCabang";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import ModalConfigInventory from "../molekuls/modal/ModalConfigInventory";
import { useNavigate } from "react-router-dom";
import {
  useHandleAddPageName,
  useHandleListBreadcrumb,
} from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";

export default function PageInventoryDetailCabang(props) {
  const { outlet } = props;
  const { configInv, setConfigInv } = useContext(ConfigInvContext);
  const [minStok, setMinStok] = useState(configInv.minStok);
  const [loading, setLoading] = useState(true);

  const [listDataProduk, setlistDataProduk] = useState([]);
  const [detailCabang, setDetailCabang] = useState({});
  const [listDataItemProduk, setListDataItemProduk] = useState([]);
  const [listDataItemNusrse, setListDataItemNusrse] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.inventoryModul_get_cabang);

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      setListBreadcrumb([
        { name: "Inventory", link: "/inventory" },
        { name: "Cabang " + detailCabang.nama, link: "#" },
      ]);
    }
  }, [loading]);

  useEffect(() => {
    setLoading(true);
    setConfigInv({ ...ConfigInvContext, minStok: minStok });
    dispatch(GetInventoryCabang({ minStok: minStok, outlet: outlet }));
  }, [minStok]);
  useEffect(() => {
    if (storeGetData.success) {
      console.log("dataPure", storeGetData.data);

      storeGetData.data.listData?.forEach((x) => {
        setDetailCabang({
          id: x?.idOutlet,
          nama: x?.namaOutlet,
          kategori: x?.kategori,
          summary: x?.summary,
        });
        setListDataItemProduk(
          x?.listStok?.map((y) => {
            return {
              id: y.Id,
              nama: y.nama,
              stok: y.stok,
              kategori: y.kategori,
              satuan: y.satuan,
            };
          })
        );

        setListDataItemNusrse(
          x?.listStokTreatment?.map((y) => {
            return {
              id: y.Id,
              nama: y.nama,
              stok: y.stoktreatment,
              kategori: y.kategori,
              satuan: y.satuan,
            };
          })
        );
      });
      setLoading(false);
    }
    if (storeGetData.error) {
      setLoading(false);
      dispatch(RGetInventoryCabang());
      setDetailCabang({});
      setListDataItemProduk([]);
      setListDataItemNusrse([]);
      navigate("/404");
    }
  }, [storeGetData]);

  const handleDowload = () => {
    dispatch(
      DownloadGetInventoryCabang({
        minStok: minStok,
        outlet: outlet,
        namaOutlet: detailCabang.nama,
      })
    );
  };
  return (
    <div>
      <HeaderPage
        title={"Inventory " + detailCabang.nama}
        p={"Inventory Cabang" + ", " + detailCabang.kategori}
      />

      <div className="pt-2">
        <ModalConfigInventory
          minStok={minStok}
          handleOnChange={(v) => {
            setMinStok(v.minStok);
          }}
        />
        <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
          <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
            <div className="order-first">
              <DownloadButton downloadAction={() => handleDowload()} />
            </div>
          </div>
          {loading ? (
            <SimpleLoading />
          ) : (
            <div className="grid grid-cols-2 gap-2">
              <TabelStokInventoryCabang
                title="Produk"
                data={listDataItemProduk}
                minStok={minStok}
              />
              <TabelStokInventoryCabang
                title="Produk Nurse"
                data={listDataItemNusrse}
                minStok={minStok}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
