import {
  ALERT_BASIC_REQUEST,
  ALERT_BASIC_SET,
} from "../constants/alertBasicConstants";

export const getAlertBasicReducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case ALERT_BASIC_REQUEST:
      return { loading: true };
    case ALERT_BASIC_SET:
      return { loading: false, data: action.payload, success: true };
    default:
      return state;
  }
};
