import React from "react";
import { Link, useMatches } from "react-router-dom";
import { classNames } from "../../functions/atributComponent";

export default function Breadcrumb({
  list = [
    { name: "Home", link: "#" },
    { name: "Second", link: "#" },
  ],
}) {
  return (
    <>
      <nav
        className="flex px-5 py-3 text-gray-700 border border-gray-200 rounded-lg bg-white "
        aria-label="Breadcrumb"
      >
        <ol className="inline-flex items-center space-x-1 md:space-x-3 overflow-x-auto">
          {list.map((n, key) => (
            <>
              <li
                className={classNames(
                  key != 0 ? "" : "inline-flex items-center"
                )}
              >
                {key != 0 ? (
                  <div className="flex items-center">
                    <svg
                      className="w-3 h-3 mx-1 text-gray-400"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 6 10"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 9 4-4-4-4"
                      />
                    </svg>
                    {list?.length == key + 1 ? (
                      <p
                        key={"link-" + key}
                        className={classNames(
                          list?.length == key + 1
                            ? "text-gray-400 cursor-default"
                            : " hover:text-blue-600 md:ml-2 ",
                          "ml-1 text-sm font-medium whitespace-nowrap"
                        )}
                      >
                        {n.name}
                      </p>
                    ) : (
                      <Link
                        key={"link-" + key}
                        to={list?.length == key + 1 ? "#" : n.link}
                        className={classNames(
                          list?.length == key + 1
                            ? "text-gray-400 cursor-default"
                            : " hover:text-blue-600 md:ml-2 ",
                          "ml-1 text-sm font-medium whitespace-nowrap"
                        )}
                      >
                        {n.name}
                      </Link>
                    )}
                  </div>
                ) : (
                  <>
                    <Link
                      key={"link-" + key}
                      to={n.link}
                      className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600  whitespace-nowrap"
                    >
                      <svg
                        className="w-3 h-3 mr-2.5"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                      </svg>
                      {n.name}
                    </Link>
                  </>
                )}
              </li>
            </>
          ))}
          {/* 
          <li className="inline-flex items-center">
            <a
              href="#"
              className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 "
            >
              <svg
                className="w-3 h-3 mr-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
              >
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
              </svg>
              Home
            </a>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="w-3 h-3 mx-1 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <a
                href="#"
                className="ml-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ml-2 "
              >
                Templates
              </a>
            </div>
          </li>
          <li aria-current="page">
            <div className="flex items-center">
              <svg
                className="w-3 h-3 mx-1 text-gray-400"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
              <span className="ml-1 text-sm font-medium text-gray-500 md:ml-2 ">
                Flowbite
              </span>
            </div>
          </li> */}
        </ol>
      </nav>
    </>
  );
}
