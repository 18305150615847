import { loginmodul_changepass_reducers } from "../reducers/changepassReducers";
import {
  loginmodul_login_reducers,
  loginmodul_logout_reducers,
} from "../reducers/loginReducers";
import { loginmodul_userInfoBasic_reducers } from "../reducers/userInfoBasicReducers";

const modulLoginReducers = {
  userLogin: loginmodul_login_reducers,
  userLogout: loginmodul_logout_reducers,
  userInfoBasic: loginmodul_userInfoBasic_reducers,
  userChangePass: loginmodul_changepass_reducers,
};

export default modulLoginReducers;
