import React from "react";
import { useHandleFirstPageName } from "../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import PageGeneralBulanan from "./view/parts/PageGeneralBulanan";
import HeaderPage from "../../general/components/text/HeaderPage";

export default function LabaRugiModul() {
  return (
    <>
      <PageGeneralBulanan />
    </>
  );
}
