import {
  LOGINMODUL_LOGIN_REQUEST,
  LOGINMODUL_LOGIN_SUCCESS,
  LOGINMODUL_LOGIN_FAIL,
  LOGINMODUL_LOGIN_RESET,
} from "../constants/loginConstants";
import {
  LOGINMODUL_LOGOUT_FAIL,
  LOGINMODUL_LOGOUT_PENGGUNA,
  LOGINMODUL_LOGOUT_REQUEST,
  LOGINMODUL_LOGOUT_RESET,
  LOGINMODUL_LOGOUT_SUCCESS,
} from "../constants/logoutConstants";

export const loginmodul_login_reducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case LOGINMODUL_LOGIN_REQUEST:
      return { loading: true };
    case LOGINMODUL_LOGIN_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LOGINMODUL_LOGIN_FAIL:
      return { loading: false, error: action.payload };
    case LOGINMODUL_LOGIN_RESET:
      return {};
    case LOGINMODUL_LOGOUT_PENGGUNA:
      return {};
    default:
      return state;
  }
};

export const loginmodul_logout_reducers = (state = { data: {} }, action) => {
  switch (action.type) {
    case LOGINMODUL_LOGOUT_REQUEST:
      return { loading: true };
    case LOGINMODUL_LOGOUT_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LOGINMODUL_LOGOUT_FAIL:
      return { loading: false, error: action.payload };
    case LOGINMODUL_LOGOUT_RESET:
      return {};
    default:
      return state;
  }
};
