import {
  LOGINMODUL_USER_INFO_BASIC_FAIL,
  LOGINMODUL_USER_INFO_BASIC_REQUEST,
  LOGINMODUL_USER_INFO_BASIC_RESET,
  LOGINMODUL_USER_INFO_BASIC_SUCCESS,
} from "../constants/userInfoBasicConstants";

export const loginmodul_userInfoBasic_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case LOGINMODUL_USER_INFO_BASIC_REQUEST:
      return { loading: true };
    case LOGINMODUL_USER_INFO_BASIC_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case LOGINMODUL_USER_INFO_BASIC_FAIL:
      return { loading: false, error: action.payload };
    case LOGINMODUL_USER_INFO_BASIC_RESET:
      return {};
    default:
      return state;
  }
};
