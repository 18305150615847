import { BUTTON_DOWNLOAD_REQUEST, BUTTON_DOWNLOAD_SUCCESS } from "./constant";

export const ActGeneralButtonDownloadRequest =
  () => async (dispatch, getState) => {
    dispatch({
      type: BUTTON_DOWNLOAD_REQUEST,
    });
  };

export const ActGeneralButtonDownloadSuccess =
  () => async (dispatch, getState) => {
    dispatch({
      type: BUTTON_DOWNLOAD_SUCCESS,
    });
  };
