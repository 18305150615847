import {
  KOMISI_ALL_CABANG_GET_FAIL,
  KOMISI_ALL_CABANG_GET_REQUEST,
  KOMISI_ALL_CABANG_GET_RESET,
  KOMISI_ALL_CABANG_GET_SUCCESS,
} from "../constants/KomisiAllCabangConstants";

export const komisiModul_get_all_cabang_reducers = (
  state = { data: {} },
  action
) => {
  switch (action.type) {
    case KOMISI_ALL_CABANG_GET_REQUEST:
      return { loading: true };
    case KOMISI_ALL_CABANG_GET_SUCCESS:
      return { loading: false, data: action.payload, success: true };
    case KOMISI_ALL_CABANG_GET_FAIL:
      return { loading: false, error: action.payload };
    case KOMISI_ALL_CABANG_GET_RESET:
      return {};
    default:
      return state;
  }
};
