import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AlertBasic } from "./alertBasic";
function AlertLayout() {
  const [listAlert, setListAlert] = useState([]);
  const getAlertBasic = useSelector((state) => state.getAlertBasic);
  const dispatch = useDispatch();
  const [spam, setSpam] = useState("");
  // useEffect(() => {
  //   setTimeout(() => {

  //   }, 3000);
  // });

  useEffect(() => {
    if (getAlertBasic.success) {
      console.log("call list efect", getAlertBasic.data);
      let i = listAlert;
      i.push(getAlertBasic.data);
      setListAlert(i);

      setSpam(getAlertBasic.data.mes);
    }
  }, [getAlertBasic]);
  console.log("listAlert", listAlert);
  return (
    <>
      <p className=" hidden">{spam}</p>
      <div className=" justify-center items-start fixed bottom-16 right-0 w-full max-w-md  -pt-12 z-[999] ">
        {/* <div className=" justify-center items-start fixed top-24 right-0 w-full  -pt-12 z-75 "> */}
        {/* <div className=" justify-center items-start fixed bottom-16 right-0 w-full  -pt-12 z-50"> */}
        <div className="mx-auto px-4 sm:px-6 container space-y-2 flex-col ">
          {listAlert?.map((n, k) => {
            return n.type ? (
              <AlertBasic
                key={k}
                type={n.type}
                mes={n.mes}
                className="sm:justify-end w-full  sm:flex"
              />
            ) : null;
          })}
        </div>
      </div>
    </>
  );
}

export default AlertLayout = memo(AlertLayout);
