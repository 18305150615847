export const DUMMY_DATA_LAPORAN_PENJUALAN_BULANAN = [
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
  {
    nama: "Nama Outlet",
    tipeOutlet: "Clasic",
    medis: 0,
    nonMedis: 0,
    produk: 0,
    pelunasan: 0,
    hutang: 0,
    ppn: 0,
    sc: 0,
    total: 0,
    tunai: 0,
    transfer: 0,
    creditBni: 0,
    creditBri: 0,
    creditBca: 0,
    creditMandiri: 0,
    debitBni: 0,
    debitBri: 0,
    debitBca: 0,
    debitMandiri: 0,
    ovo: 0,
    komisi: 0,
  },
];
