export const LIST_MONTH = [
  { id: "00", sort: "Semua", name: "Semua Bulan" },
  { id: "01", sort: "Jan", name: "Januari" },
  { id: "02", sort: "Feb", name: "Februari" },
  { id: "03", sort: "Mar", name: "Maret" },
  { id: "04", sort: "Apr", name: "April" },
  { id: "05", sort: "Mei", name: "Mei" },
  { id: "06", sort: "Jun", name: "Juni" },
  { id: "07", sort: "Jul", name: "Juli" },
  { id: "08", sort: "Agu", name: "Agustus" },
  { id: "09", sort: "Sep", name: "September" },
  { id: "10", sort: "Okt", name: "Oktober" },
  { id: "11", sort: "Nov", name: "November" },
  { id: "12", sort: "Des", name: "Desember" },
];
