import React, { useContext, useEffect, useState } from "react";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { ConfigInvContext } from "../../context/configContext";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetPenjualanCabang,
  GetPenjualanCabang,
  RGetPenjualanCabang,
} from "../../actions/PenjualanCabangActions";
import TabelPenjualanCabang from "../molekuls/table/TabelPenjualanCabang";
import { DocumentArrowDownIcon } from "@heroicons/react/24/outline";
import { useNavigate, useParams } from "react-router-dom";
import {
  useHandleAddPageName,
  useHandleListBreadcrumb,
} from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import ModalFilterTanggalPenjualan from "../molekuls/modal/ModalFilterTanggalPenjualan";
import ModalDetailPenjualan from "../molekuls/modal/ModalDetailPenjualan";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";

export default function PagePenjualanDetailCabang(props) {
  const date = new Date();

  const { outlet } = props;
  const [loading, setLoading] = useState(true);
  const [listData, setlistData] = useState();
  const [detailCabang, setDetailCabang] = useState({});

  //
  const [monthS, setMonthS] = useState(null);
  const [yearS, setYearS] = useState(null);
  const [monthE, setMonthE] = useState(null);
  const [yearE, setYearE] = useState(null);
  //
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.penjualanModul_get_cabang);
  let { dateParam } = useParams();
  console.log("detailCabang", detailCabang);

  const { setListBreadcrumb } = useHandleListBreadcrumb();
  useEffect(() => {
    if (loading == false) {
      setListBreadcrumb([
        {
          name: "Penjualan",
          link: "/penjualan/" + yearS + "" + monthS + "-" + yearE + "" + monthE,
        },
        { name: "Cabang " + detailCabang.nama, link: "#" },
      ]);
    }
  }, [loading]);

  useEffect(() => {
    if (dateParam != null && dateParam != undefined) {
      let y = null;
      let m = null;
      let yE = null;
      let mE = null;
      let nDate;
      if (dateParam.length == 13) {
        nDate = dateParam.split("-");
      }
      if (dateParam.length == 13 && !isNaN(nDate[0]) && !isNaN(nDate[1])) {
        y = nDate[0].slice(0, 4);
        m = nDate[0].slice(4, 6);
        setYearS(y);
        setMonthS(m);

        yE = nDate[1].slice(0, 4);
        mE = nDate[1].slice(4, 6);
        setYearE(yE);
        setMonthE(mE);
        console.log("dateParam Set value");
      }
    } else {
      setMonthS("01");
      setYearS(date.getFullYear());
      setYearE(date.getFullYear());
      setMonthE("12");
    }
  }, [dateParam]);

  useEffect(() => {
    setLoading(true);
    if (yearS != null && monthS != null && yearE != null && monthE != null) {
      let firstDay = new Date(yearS, monthS, 1);
      let lastDay = new Date(yearE, parseInt(monthE), 0);
      let dateStart =
        yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
      let dateEnd =
        yearE + "-" + monthE + "-" + ("0" + lastDay.getDate()).slice(-2);
      dispatch(
        GetPenjualanCabang({
          dateStart: dateStart,
          dateEnd: dateEnd,
          outlet: outlet,
        })
      );
    }
  }, [yearS, monthS, yearE, monthE]);

  useEffect(() => {
    console.log("dataPure", storeGetData);
    if (storeGetData?.success) {
      if (outlet == storeGetData?.data?.outlet) {
        storeGetData.data.listData?.forEach((x) => {
          setDetailCabang({
            id: x?.idOutlet,
            nama: x?.namaOutlet,
            kategori: x?.kategori,
          });
          let no = 1;
          setlistData(
            x?.listData?.map((y) => {
              return {
                no: no++,
                id: y.Id,
                catatan: y.catatan,
                diskon: y.diskon,
                grandtotal: y.grandtotal,
                idmember: y.idmember,
                idstaff: y.idstaff,
                kembalian: y.kembalian,
                namamember: y.namamember,
                namastaff: y.namastaff,
                nonota: y.nonota,
                piutang: y.piutang,
                ppn: y.ppn,
                servicecharge: y.servicecharge,
                statuspiutang: y.statuspiutang,
                tanggal: y.tanggal,
                tglpelunsasan: y.tglpelunasan,
                tipe: y.tipe,
                totalhargapokok: y.totalhargapokok,
                tunai: y.tunai,
              };
            })
          );
        });

        setLoading(false);
      }
    }
    if (storeGetData?.error) {
      setLoading(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.yearS;
    let m = v.monthS;
    let n = "-";
    let yE = v.yearE;
    let mE = v.monthE;
    if (m == null) {
      m = "01";
    }
    if (mE == null) {
      mE = "01";
    }
    if (yE == null) {
      m = y;
    }

    navigate("/penjualan/" + y + "" + m + n + yE + "" + mE + "/" + outlet);
  };

  const handleDowload = () => {
    let firstDay = new Date(yearS, monthS, 1);
    let lastDay = new Date(yearE, parseInt(monthE), 0);
    let dateStart =
      yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
    let dateEnd =
      yearE + "-" + monthE + "-" + ("0" + lastDay.getDate()).slice(-2);
    dispatch(
      DownloadGetPenjualanCabang({
        dateStart: dateStart,
        dateEnd: dateEnd,
        outlet: outlet,
        namaOutlet: detailCabang.nama,
      })
    );
  };

  const [show, setShow] = useState(false);
  const [dataOpen, setDataOpen] = useState({});
  const handleOpenData = (n) => {
    setDataOpen(n);
    setShow(true);
  };
  return (
    <>
      <div>
        <HeaderPage
          title={"Penjualan " + detailCabang.nama}
          p={"Penjualan Cabang" + ", " + detailCabang.kategori}
        />

        <div className="pt-2">
          <ModalFilterTanggalPenjualan
            monthIdS={monthS}
            yearIdS={yearS}
            monthIdE={monthE}
            yearIdE={yearE}
            handleOnChange={handleChangeDate}
          />
          <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
            <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
              <div className="order-first">
                <DownloadButton downloadAction={() => handleDowload()} />
              </div>
            </div>
            {loading ? (
              <SimpleLoading />
            ) : (
              <TabelPenjualanCabang
                title="Member"
                data={listData}
                handleOpenData={handleOpenData}
              />
            )}
          </div>
        </div>
      </div>
      <ModalDetailPenjualan
        show={show}
        setShow={(v) => {
          setShow(v);
          if (v == false) {
            setDataOpen({});
          }
        }}
        data={dataOpen}
        detailCabang={detailCabang}
      />
    </>
  );
}
