import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  DownloadGetKomisiAllCabang,
  GetKomisiAllCabang,
} from "../../actions/KomisiAllCabangActions";
import TableFixHeaderKomisiAllCabang from "../molekuls/table/TableFixHeaderKomisiAllCabang";
import { filterbyArray } from "../../../../general/functions/checkingInput";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import HeaderPage from "../../../../general/components/text/HeaderPage";
import { useNavigate, useParams } from "react-router-dom";
import ModalFilterTanggalKomisi from "../molekuls/modal/ModalFilterTanggalKomisi";
import SimpleLoading from "../../../../general/components/loading/SimpleLoading";
import DownloadButton from "../../../../general/components/button/DownloadButton";
import { useHandleListBreadcrumb } from "../../../../general/components/BreadcrumbLibrary/customHookBreadcrumb";
import ModalFilterBulananKomisi from "../molekuls/modal/ModalFilterBulananKomisi";

export default function PageKomisiAllCabang() {
  const date = new Date();
  const [loading, setLoading] = useState(true);
  const [listData, setlistData] = useState();
  const [listFilter, setListFilter] = useState([]);

  //
  const [monthS, setMonthS] = useState(null);
  const [yearS, setYearS] = useState(null);
  //
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const storeGetData = useSelector((state) => state.komisiModul_get_all_cabang);
  let { dateParam } = useParams();
  const { setListBreadcrumb } = useHandleListBreadcrumb();

  useEffect(() => {
    if (dateParam != null && dateParam != undefined) {
      setListBreadcrumb([
        {
          name: "Komisi",
          link: "/komisi/" + dateParam,
        },
      ]);

      let y = null;
      let m = null;

      if (dateParam.length == 6 && !isNaN(dateParam)) {
        y = dateParam.slice(0, 4);
        m = dateParam.slice(4, 6);
        setYearS(y);
        setMonthS(m);
      }
    } else {
      navigate(
        "/komisi/" +
          date.getFullYear() +
          "" +
          ("0" + date.getMonth() + 1).slice(-2)
      );
    }
  }, [dateParam]);

  useEffect(() => {
    setLoading(true);
    console.log("triger update");
    if (yearS != null && monthS != null) {
      let firstDay = new Date(yearS, monthS, 1);
      let lastDay = new Date(yearS, parseInt(monthS), 0);
      let dateStart =
        yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
      let dateEnd =
        yearS + "-" + monthS + "-" + ("0" + lastDay.getDate()).slice(-2);
      dispatch(GetKomisiAllCabang({ dateStart: dateStart, dateEnd: dateEnd }));
    }
  }, [yearS, monthS]);

  useEffect(() => {
    console.log("dataPure", storeGetData);
    if (storeGetData?.success) {
      setlistData(
        storeGetData.data.listData?.map((x) => {
          return {
            nama: x.namaOutlet,
            id: x.idOutlet,
            tipeOutlet: x.kategori,
            totalKomisi: x.totalKomisi,
            totalKomisiLain: x.totalKomisiLain,
            totalKomisiProduk: x.totalKomisiProduk,
            totalKomisiTreatment: x.totalKomisiTreatment,
            totalKomisiTreatmentNonMedis: x.totalKomisiTreatmentNonMedis,
            totalUser: x?.totalUser,
          };
        })
      );

      setLoading(false);
    }
    if (storeGetData?.error) {
      setLoading(false);
    }
  }, [storeGetData]);

  const handleChangeDate = (v) => {
    let y = v.year;
    let m = v.month;

    if (m == null) {
      m = "01";
    }
    navigate("/komisi/" + y + "" + m);
  };

  const handleOpenDetailCabang = (v) => {
    navigate("/komisi/" + yearS + "" + monthS + "/" + v.id);
  };
  const handleDowload = () => {
    let firstDay = new Date(yearS, monthS, 1);
    let lastDay = new Date(yearS, parseInt(monthS), 0);
    let dateStart =
      yearS + "-" + monthS + "-" + ("0" + firstDay.getDate()).slice(-2);
    let dateEnd =
      yearS + "-" + monthS + "-" + ("0" + lastDay.getDate()).slice(-2);
    dispatch(
      DownloadGetKomisiAllCabang({
        dateStart: dateStart,
        dateEnd: dateEnd,
      })
    );
  };
  return (
    <div className="">
      <HeaderPage title="Komisi" />
      <div className="pt-2">
        <ModalFilterBulananKomisi
          monthId={monthS}
          yearId={yearS}
          handleOnChange={handleChangeDate}
        />
      </div>
      <div className="flex flex-col w-full space-y-4 p-4 mt-2 bg-white dark:bg-gray-900  rounded-md">
        <div className="flex justify-between p-0 flex-col md:flex-row gap-2">
          <div className="order-first">
            <DownloadButton downloadAction={() => handleDowload()} />
          </div>
          <div className="flex gap-2  items-center flex-row z-30">
            <div className="w-auto max-w-fit  bg-red-50">
              <Select
                //   defaultValue={[]}
                isMulti
                name="colors"
                options={listData?.map((x) => {
                  return { value: x.nama, label: x.nama, id: x.id };
                })}
                onChange={(e) => {
                  console.log(e);
                  setListFilter(e);
                }}
                className="react-select-container "
                classNamePrefix="select"
                placeholder="Pilih Cabang Tertentu"
              />
            </div>
            <button
              type="button"
              className="w-auto text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
            >
              <MagnifyingGlassIcon className="h-6 w-6 text-gray-200" />
            </button>
          </div>
        </div>

        {loading ? (
          <SimpleLoading />
        ) : (
          <TableFixHeaderKomisiAllCabang
            data={
              listFilter != null && listFilter.length != 0
                ? filterbyArray(listData, listFilter)
                : listData
            }
            onClickRow={handleOpenDetailCabang}
          />
        )}
      </div>
    </div>
  );
}
