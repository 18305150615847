import React from "react";
import { classNames } from "../../../../../general/functions/atributComponent";
import {
  formatCDecimal,
  formatCurrency,
} from "../../../../../general/functions/NumberFormat";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
const HEADER_TABEL_FIX = [
  { name: "No", left: 0 },
  { name: "Nama Outlet", left: 8 },
  { name: "Tipe Outlet", left: 60 },
];

const HEADER_TABLE = [
  { name: "Penjualan Produk" },
  { name: "Penjualan Treatment Medis" },
  { name: "Penjualan Treatment Non Medis" },
  { name: "Total Penjualan" },

  { name: "HPP Produk" },
  { name: "HPP Treatment Medis" },
  { name: "HPP Treatment Non Medis" },
  { name: "Total HPP" },
  { name: "Laba Kotor" },
  { name: "Biaya" },
  { name: "Laba Bersih" },
  { name: "Service Charge" },
  { name: "PPN" },
];
const FIELD_OBJ = [
  "penjualanProduk",
  "penjualanTreatmentMedis",
  "penjualanTreatmentNonMedis",
  "penjualanTotal",
  "hppProduk",
  "hppTreatmentMedis",
  "hppTreatmentNonMedis",
  "hppTotal",
  "labaKotor",
  "biayaPengeluaran",
  "labaBersih",
  "sc",
  "ppn",
];
// type: LELVEL_1 (namaoutlet, type), Level_2(tgl)
export default function TableFixHeaderLabaRugi(props) {
  const { data, handleViewDetail, type = "LEVEL_1", loading } = props;
  let total = {
    totalRpItem: 0,
    hargaPokok: 0,
    pendapatan: 0,
  };

  return (
    <>
      <div style={{ minHeight: "20rem" }} className="bg-white rounded-md">
        <div className="flex flex-col mt-2">
          <div className="-my-2 overflow-x-auto">
            <div className="py-2 align-middle inline-block min-w-full ">
              <div
                className="shadow border-b border-gray-200 sm:rounded-lg max-h-screen overflow-y-visible  flex relative "
                style={{ maxHeight: "50vh" }}
              >
                {/* {loading && } */}
                <table className="min-w-full bg-gray-100">
                  <thead className="bg-gray-100 sticky top-0 z-20 ">
                    <tr className="border border-gray-200">
                      {HEADER_TABEL_FIX.map((x, i) => {
                        let name = x.name;
                        if (type != "LEVEL_1" && x.name == "Nama Outlet") {
                          name = "TANGGAL";
                        }
                        return type != "LEVEL_1" && x.name == "Tipe Outlet" ? (
                          ""
                        ) : (
                          <th
                            scope="col"
                            className={classNames(
                              false
                                ? "bg-gray-50"
                                : "text-gray-500 bg-gray-100",
                              "sticky left-" +
                                x.left +
                                " z-20 px-2 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider border border-gray-200 select-none "
                            )}
                          >
                            {name}
                          </th>
                        );
                      })}

                      {HEADER_TABLE.map((x, i) => {
                        return (
                          <th
                            scope="col"
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-l  border-gray-200"
                          >
                            {x.name}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="bg-gray-200 divide-y-2 divide-gray-200 overflow-y-scroll w-full ">
                    {data?.map((x, i) => {
                      return (
                        <tr
                          className={classNames(
                            false ? "bg-gray-50" : "bg-white"
                          )}
                        >
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-center py-2 sticky  left-0 z-10"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 ">
                              {++i}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-left sticky  left-8 z-0"
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500 w-52   px-2 flex justify-between items-center">
                              <div>
                                {x?.nama == undefined ? x?.tanggal : x?.nama}
                              </div>
                              {type == "LEVEL_1" && (
                                <button
                                  onClick={(e) => {
                                    handleViewDetail(x.id);
                                  }}
                                >
                                  <MagnifyingGlassIcon className="h-4 w-4 text-gray-500" />
                                </button>
                              )}
                            </div>
                          </td>
                          {type == "LEVEL_1" && (
                            <td
                              className={classNames(
                                false ? "bg-gray-50" : "bg-white",
                                "text-left sticky left-60 z-0"
                              )}
                            >
                              <div className="text-sm font-medium text-gray-500 w-24 px-2 ">
                                {x.tipeOutlet}
                              </div>
                            </td>
                          )}

                          {FIELD_OBJ?.map((f) => {
                            return (
                              <td
                                className={classNames(
                                  false ? "bg-gray-50" : "bg-white",
                                  "text-right "
                                )}
                              >
                                <div className="text-sm font-medium text-gray-500  px-2 ">
                                  {formatCDecimal(x[f])}
                                </div>
                              </td>
                            );
                          })}
                          {/* <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.penjualanItem)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.piutangItem)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.pelunasanItem)}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.totalPemasukanItem)}
                            </div>
                          </td>

                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.hargaPokok)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.labaKotor)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.pengeluaran)}
                            </div>
                          </td>
                          <td
                            className={classNames(
                              false ? "bg-gray-50" : "bg-white",
                              "text-right "
                            )}
                          >
                            <div className="text-sm font-medium text-gray-500  px-2 ">
                              {formatCurrency(x.labaBersih)}
                            </div>
                          </td> */}
                        </tr>
                      );
                    })}
                  </tbody>
                  {/* <tfoot className="absolute z-50 bg-red-100 bottom-0">
                    <tr
                      className={classNames(false ? "bg-gray-50" : "bg-white")}
                    >
                      <td
                        colSpan={3}
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-center py-2 sticky  right-0 z-10"
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500 ">
                          TOTAL
                        </div>
                      </td>

                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.totalRpItem}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.hargaPokok}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.pendapatan}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.pelunasan}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.hutang}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.ppn}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.sc}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.total}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.tunai}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.transfer}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.creditBni}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.creditBri}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.creditBca}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.creditMandiri}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.debitBni}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.debitBri}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.debitBca}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.debitMandiri}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.ovo}
                        </div>
                      </td>
                      <td
                        className={classNames(
                          false ? "bg-gray-50" : "bg-white",
                          "text-left "
                        )}
                      >
                        <div className="text-sm font-medium text-gray-500  px-2 ">
                          {total.komisi}
                        </div>
                      </td>
                    </tr>
                  </tfoot> */}
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* {dataView()?.length <= 0 && loading == false ? (
          <div className="w-full h-40 bg-gray-100">
            <div
              className="px-6 py-4 whitespace-nowrap"
              colSpan={2 + parseInt(listAspectMarge?.length)}
            >
              <div className="flex justify-center">
                <p className="text-gray-500">No data available</p>
              </div>
            </div>
          </div>
        ) : null} */}
      </div>
    </>
  );
}
