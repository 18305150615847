import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbListContext } from "./context";

export function useHandleListBreadcrumb() {
  const { listBreadcrumb, setListBreadcrumb } = useContext(
    BreadcrumbListContext
  );
  // useEffect(() => {
  //   setListBreadcrumb(list);
  // }, [list]);
  return { setListBreadcrumb };
}

export function useHandleFirstPageName(name, link) {
  const { listBreadcrumb, setListBreadcrumb } = useContext(
    BreadcrumbListContext
  );
  useEffect(() => {
    setListBreadcrumb([
      {
        name: name,
        link: link,
      },
    ]);
  }, [link]);
}

export function useHandleAddPageName(name, link) {
  const { listBreadcrumb, setListBreadcrumb } = useContext(
    BreadcrumbListContext
  );
  useEffect(() => {
    console.log(
      "listBreadcrumb",
      listBreadcrumb,
      listBreadcrumb?.filter((x) => x.name == name && x.link == link)?.length ==
        0
    );
    if (
      listBreadcrumb?.filter((x) => x.name == name && x.link == link)?.length ==
        0 &&
      name != null
    ) {
      setListBreadcrumb([
        ...listBreadcrumb,
        {
          name: name,
          link: link,
        },
      ]);
    }
    console.log("mount listBreadcrumb ", name);

    return () => {
      console.log("unmount listBreadcrumb ", name);
    };
  }, [listBreadcrumb]);
}
